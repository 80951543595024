/**
 * @generated SignedSource<<bbb2e9cc7d877916ae02d9aa1b4c9f80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToolCard_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deleted",
          "storageKey": null
        }
      ],
      "type": "Tool",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToolImage_Fragment"
    }
  ],
  "type": "ToolInterface",
  "abstractKey": "__isToolInterface"
};

node.hash = "e7ffa55d722c5427b6070e9d8da7ca57";

module.exports = node;
