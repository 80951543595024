import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';

export default function initializeDatadog() {
  // data dog rum integration
  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: process.env.DATADOG_SITE,

    service: process.env.DATADOG_SERVICE,
    env: process.env.DATADOG_ENV,
    version: process.env.COMMIT_SHA,

    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',

    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  });
  // data dog logs integration
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: process.env.DATADOG_SITE,

    service: process.env.DATADOG_SERVICE,
    env: process.env.DATADOG_ENV,
    version: process.env.COMMIT_SHA,

    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,

    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  });
}
