import React from 'react';
import PropTypes from 'prop-types';

function PageFullContentTemplate({headerText, children}) {
  let header = null;
  if (headerText) header = <h1 className="header">{headerText}</h1>;

  return (
    <div className="page">
      {header}
      {children}
    </div>
  );
}

PageFullContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  headerText: PropTypes.string,
};

export default PageFullContentTemplate;
