import {graphql} from 'react-relay';

export default graphql`
  query databrowserQuery(
    $filters: DatabrowserFiltersInput
    $summaryFilters: DatabrowserFiltersInput
    $includeDatabrowserRows: Boolean!
    $type: DatabrowserTabType!
    $initialFetch: Boolean!
  ) {
    ...Databrowser_Fragment
    ... @include(if: $initialFetch) {
      databrowser {
        ...SidebarFilters_Filters_Fragment
      }
    }
  }
`;
