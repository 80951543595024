import {equals} from 'ramda';

import usePrevious from './usePrevious';

/**
 * useHasChanged returns a boolean value indicator whether or not the given
 * value has changed between the current render previous render.
 */
const useHasChanged = value => {
  const prevValue = usePrevious(value);
  return !equals(value, prevValue);
};

export default useHasChanged;
