import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

const DataAlertMessage = ({hasSwitch, message, alertKey, onToggleSwitch, checked}) => {
  if (!hasSwitch) {
    return <div key={alertKey}>{message}</div>;
  }

  return (
    <Form.Switch
      key={alertKey}
      id={`databrowser-alert-${alertKey}`}
      label={message}
      onChange={onToggleSwitch}
      checked={checked}
    />
  );
};

DataAlertMessage.propTypes = {
  alertKey: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  message: PropTypes.string.isRequired,
  hasSwitch: PropTypes.bool,
  onToggleSwitch: PropTypes.func.isRequired,
};

export default DataAlertMessage;
