import React from 'react';
import {Button} from 'react-bootstrap';

import Link from '../../../atoms/Link';

function WizardError() {
  return (
    <div>
      <div className="wizard-header center">Something went wrong</div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <Button variant="primary" to="/data/diseases" as={Link}>
          Back to Data Browser
        </Button>
      </div>
    </div>
  );
}

export default WizardError;
