import PropTypes from 'prop-types';

function DatabrowserHeader({pecan}) {
  if (pecan) {
    return 'Data Selected from PeCan';
  }

  return 'Select Data';
}

DatabrowserHeader.propTypes = {
  pecan: PropTypes.bool,
};

export default DatabrowserHeader;
