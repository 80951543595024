import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';
import EDAA_COUNTRIES from '../edaaCountries';

function ApplicationTypeForm({user, setDraftType}) {
  const onClick = wantsEsign => {
    setDraftType(wantsEsign);
  };

  return (
    <div>
      <div className="wizard-header center">
        How would you like to complete the data access agreement?
      </div>
      <div className="wizard-sub-header">
        Your responses have been saved to the PDF agreement, and you will need to choose how you
        would like to collect signatures. You can choose to collect e-signatures through DocuSign or
        download the agreement and collect physical signatures yourself.
      </div>
      <br />
      <Form.Group className="options-row">
        <Button
          variant="primary"
          onClick={() => onClick(true)}
          className="options-button"
          data-testid="edaa-type-btn-docusign"
          disabled={!EDAA_COUNTRIES.includes(user.country)}
        >
          Online DocuSign® Form
        </Button>

        <Button
          variant="primary"
          onClick={() => onClick(false)}
          className="options-button"
          data-testid="edaa-type-btn-manual"
        >
          Download PDF
        </Button>
      </Form.Group>
      {!EDAA_COUNTRIES.includes(user.country) && (
        <div>
          <div className="text-danger text-sm-center">
            At this time, we do not support electronic signing of the data access agreement for your
            country.
          </div>
          <br />
        </div>
      )}
    </div>
  );
}

ApplicationTypeForm.propTypes = {
  setDraftType: PropTypes.func.isRequired,
  user: PropTypes.shape({
    country: PropTypes.string,
  }),
};

export default ApplicationTypeForm;
