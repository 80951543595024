import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';

import PageTemplate from '../../templates/PageTemplate';

import {logout} from './actions';

function LogoutPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {search = ''} = useLocation();

  let redirectTarget = '/';
  const queryParams = new URLSearchParams(search);
  const path = queryParams.get('path');

  if (path) {
    redirectTarget = path;
  }

  useEffect(() => {
    dispatch(logout(decodeURIComponent(redirectTarget)));
  }, [dispatch, redirectTarget]);

  if (window.Intercom) {
    window.Intercom('shutdown');
  }

  return (
    <PageTemplate>
      <p>{intl.formatMessage({id: 'logoutMessage'})}</p>
    </PageTemplate>
  );
}

export default LogoutPage;
