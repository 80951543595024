/**
 * @generated SignedSource<<645706bff86ada010d6306dd07688c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "draftInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requestRequiredAccessUnits"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userInput"
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "draftInput"
      }
    ],
    "concreteType": "UpsertAccessRequestDraftMutationPayload",
    "kind": "LinkedField",
    "name": "upsertAccessRequestDraft",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccessRequestDraft",
        "kind": "LinkedField",
        "name": "accessRequestDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iid",
            "storageKey": null
          },
          {
            "condition": "requestRequiredAccessUnits",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredAccessUnits",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "userInput"
      }
    ],
    "concreteType": "UpdateUserMutationPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessRequestSubmissionWizard_UpsertDraft_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccessRequestSubmissionWizard_UpsertDraft_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "edfe4886b7a872c7b6e9f66400cedfeb",
    "id": null,
    "metadata": {},
    "name": "AccessRequestSubmissionWizard_UpsertDraft_Mutation",
    "operationKind": "mutation",
    "text": "mutation AccessRequestSubmissionWizard_UpsertDraft_Mutation(\n  $draftInput: UpsertAccessRequestDraftMutationInput!\n  $userInput: UpdateUserMutationInput!\n  $requestRequiredAccessUnits: Boolean!\n) {\n  upsertAccessRequestDraft(input: $draftInput) {\n    accessRequestDraft {\n      id\n      iid\n      requiredAccessUnits @include(if: $requestRequiredAccessUnits)\n    }\n    error {\n      type\n      message\n    }\n  }\n  updateUser(input: $userInput) {\n    error {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "f3fd8c6f9b0fa433d297230a6f67ddc2";

module.exports = node;
