import {useEffect, useRef} from 'react';

/**
 * useDidUpdateEffect is equivalent to class components' `componentDidUpdate`.
 * This hook skips the effect callback for the initial render and runs for all
 * subsequent renders.
 */
export default function useDidUpdateEffect(fn, dependencies) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    return fn(); // eslint-disable-line consistent-return
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}
