/**
 * @generated SignedSource<<b15ce122becf51b57a198e16a5147f92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "draftId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "draftId",
    "variableName": "draftId"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodySanitized",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dxid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkflowPage_index_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "toolInterface",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ToolButtons_Fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ToolHeader_Fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkflowPage_index_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "toolInterface",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isToolInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "toolProject",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "type": "Error",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "type": "ToolProject",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Tool",
            "abstractKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "embed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ToolWorkflow",
            "kind": "LinkedField",
            "name": "workflows",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b57ce4dc333172f84b3091bbd760f16",
    "id": null,
    "metadata": {},
    "name": "WorkflowPage_index_Query",
    "operationKind": "query",
    "text": "query WorkflowPage_index_Query(\n  $slug: String\n  $draftId: ID\n) {\n  toolInterface(slug: $slug, draftId: $draftId) {\n    __typename\n    name\n    subtitle\n    bodySanitized\n    slug\n    ...ToolButtons_Fragment\n    ...ToolHeader_Fragment\n    id\n  }\n}\n\nfragment ToolButtons_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  name\n  subtitle\n  slug\n  __typename\n  ... on Tool {\n    toolProject {\n      __typename\n      ... on Error {\n        type\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  ...UserLoggedInButtons_Fragment\n  ...UserNotLoggedInButtons_Fragment\n}\n\nfragment ToolHeader_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  name\n  subtitle\n  ...ToolImage_Fragment\n}\n\nfragment ToolImage_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  name\n  imageUrl\n}\n\nfragment UserLoggedInButtons_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  id\n  slug\n  path\n  embed\n  workflows {\n    dxid\n    label\n    id\n  }\n  ... on Tool {\n    toolProject {\n      __typename\n      ... on ToolProject {\n        dxid\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment UserNotLoggedInButtons_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  path\n}\n"
  }
};
})();

node.hash = "c3c1ce652c2c2393be8e5ef57a9ca7d1";

module.exports = node;
