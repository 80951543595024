/**
 * @generated SignedSource<<84d097764d0e2e0bee19bca4eb24077f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNotLoggedInButtons_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "type": "ToolInterface",
  "abstractKey": "__isToolInterface"
};

node.hash = "035c97c16d9bc89255b0521631cb1d04";

module.exports = node;
