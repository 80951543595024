import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import SummaryField from './SummaryField';

function ITDSummary({wantsToDownload, isStjude, itDirector}) {
  return (
    <div>
      <Form.Check>
        <Form.Check.Label className="checkbox-container">
          <Form.Check.Input type="checkbox" checked={wantsToDownload} disabled />
          I plan to download the data in addition to working with it in the cloud.
          <span className="checkmark" />
        </Form.Check.Label>
      </Form.Check>
      {wantsToDownload && !isStjude && (
        <>
          <SummaryField name="Full Name" value={itDirector.name} />
          <SummaryField name="Email" value={itDirector.email} />
        </>
      )}
    </div>
  );
}

ITDSummary.propTypes = {
  wantsToDownload: PropTypes.bool,
  isStjude: PropTypes.bool,
  itDirector: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default ITDSummary;
