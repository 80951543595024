import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay';

import Heading from './Heading';
import AddendumUploadSuccessModal from './AddendumUploadSuccessModal';
import UploadExtensionAddendum from './UploadExtensionAddendum';
import ProjectInfo from './ProjectInfo';
import DataAccessUnits from './DataAccessUnits';
import PrincipalInvestigator from './PrincipalInvestigator';
import AdditionalApplicants from './AdditionalApplicants';
import InstitutionalAuthority from './InstitutionalAuthority';
import DownloadData from './DownloadData';
import DeleteUserConfirmationModal from './DeleteUserConfirmationModal';

import ErrorPage from '../ErrorPage';
import {APPROVAL_STATUS} from '../DashboardPage/PreviousRequests/constants';

import withUserAuthentication from '../../auth/WithUserAuthentication';
import PageTemplate from '../../templates/PageTemplate';
import DocumentationButton from '../../atoms/DocumentationButton';

const metaData = {
  htmlTitle: 'Extension Addendum',
  metaDescription:
    'Submit an extension addendum to continue access to St. Jude Cloud genomic data.',
  metaKeywords: ['extension addendum', 'extend data request', 'extend access'],
};

const userAccessRequestQuery = graphql`
  query AddendumPage_index_Query($id: ID!) {
    accessRequest(id: $id) {
      iid
      projectName
      approvalStatus
      ...ProjectInfo_AddendumPage_Fragment
      ...DataAccessUnits_AddendumPage_Fragment
      ...PrincipalInvestigator_AddendumPage_Fragment
      ...AdditionalApplicantsInfo_AddendumPage_Fragment
      accessRequestDraft {
        id
        ...InstitutionalAuthority_AddendumPage_Fragment
        ...DownloadData_AddendumPage_Fragment
      }
    }
  }
`;

const upsertExtensionAddendumMutation = graphql`
  mutation AddendumPage_UpsertExtensionAddendum_Mutation(
    $eaInput: UpsertExtensionAddendumMutationInput!
  ) {
    upsertExtensionAddendum(input: $eaInput) {
      accessRequestDraft {
        id
        iid
        additionalApplicants {
          name
          email
        }
        institutionalAuthority {
          name
          email
        }
        itDirector {
          name
          email
        }
      }
      error {
        type
        message
      }
    }
  }
`;

export function AddendumPage() {
  const {id} = useParams();
  const {accessRequest} = useLazyLoadQuery(userAccessRequestQuery, {id});
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    projectName: accessRequest?.projectName,
  });

  const [commitMutation, isMutationInFlight] = useMutation(upsertExtensionAddendumMutation);
  const showConfirmDelete = (name, type, onDelete) => {
    setConfirmDelete({
      ...confirmDelete,
      show: true,
      name,
      type,
      onDelete,
    });
  };

  const upsertDraft = (newData, onError) => {
    commitMutation({
      variables: {
        eaInput: {
          accessRequestDraftId: accessRequest.accessRequestDraft.id,
          extensionAddendumInput: newData,
        },
      },
      onCompleted: response => {
        const {error} = response.upsertExtensionAddendum;
        if (error) onError(error.message);
      },
    });
  };

  if (!accessRequest || accessRequest.approvalStatus === APPROVAL_STATUS.PRE_APPROVED) {
    return <ErrorPage variant="notFound" />;
  }

  return (
    <PageTemplate {...metaData}>
      <AddendumUploadSuccessModal show={uploadSuccess} contentLabel="Upload Successful Modal" />
      <DeleteUserConfirmationModal
        confirmDelete={confirmDelete}
        onHide={() => setConfirmDelete({...confirmDelete, show: false})}
      />
      <div className="d-flex flex-column-reverse flex-md-row addendum-page">
        <div className="mx-auto my-5 ps-5 left-section">
          <Heading />
          <div className="my-4">
            <h2>Review the information below</h2>
            <ProjectInfo queryData={accessRequest} />
            <DataAccessUnits queryData={accessRequest} />
            <PrincipalInvestigator queryData={accessRequest} />
            <AdditionalApplicants
              queryData={accessRequest}
              upsertDraft={upsertDraft}
              upserting={isMutationInFlight}
              showConfirmDelete={showConfirmDelete}
            />
            <InstitutionalAuthority
              queryData={accessRequest.accessRequestDraft}
              upsertDraft={upsertDraft}
              upserting={isMutationInFlight}
            />
            <DownloadData
              queryData={accessRequest.accessRequestDraft}
              upsertDraft={upsertDraft}
              upserting={isMutationInFlight}
            />
          </div>
          <UploadExtensionAddendum className="my-4" id={id} setUploadSuccess={setUploadSuccess} />
        </div>

        <div className="vr mx-2" />

        <div className="my-5 mx-2 right-section">
          <DocumentationButton
            path="/managing-data/how-to-fill-out-extension/"
            variant="outline-primary"
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default withUserAuthentication(AddendumPage);
