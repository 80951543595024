const STATUS = {
  NEW: 'new',
  SUCCESS: 'success',
  CRASHED: 'crashed',
  PROCESSING: 'processing',
};

const APPROVAL_STATUS = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  PRE_APPROVED: 'PRE_APPROVED',
  REJECTED: 'REJECTED',
  EXPIRING: 'EXPIRING',
  EXPIRED: 'EXPIRED',
  WAITING_ON_SIGNATURES: 'WAITING_ON_SIGNATURES',
  DECLINED_TO_SIGN: 'DECLINED_TO_SIGN',
};

export {STATUS, APPROVAL_STATUS};
