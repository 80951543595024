import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';

import parseFormFieldName from './formUtils';

function PIForm({principalInvestigator, setPIField, isPi, setIsPi}) {
  const onChange = event => {
    const {name, value} = event.target;
    const [field] = parseFormFieldName(name);
    setPIField(field, value);
  };

  return (
    <div>
      <div className="wizard-header center">
        Are you the Principal Investigator for the project?
      </div>
      <div className="wizard-sub-header">
        The Principal Investigator is typically the holder of a grant and the lead researcher or
        supervisor of a grant project.
      </div>
      <br />
      <Form.Group>
        <div className="options-row">
          <Button
            variant="primary"
            onClick={() => setIsPi(true)}
            className="options-button"
            data-testid="btn-edaa-principalInvestigator-yes"
          >
            Yes, that&apos;s me
          </Button>
          <Button
            variant="primary"
            onClick={() => setIsPi(false)}
            className="options-button"
            data-testid="btn-edaa-principalInvestigator-no"
          >
            No, that&apos;s someone else
          </Button>
        </div>
      </Form.Group>
      {/* Check for false; if undefined the user has not yet made a decision */}
      {isPi === false && (
        <div className="d-flex flex-column align-items-center">
          <hr className="dropdown-line" />
          <div className="page-sub-header center">
            Provide information about your Principal Investigator
          </div>
          <Form.Group controlId="pi_name">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="principalInvestigator[name]"
              value={principalInvestigator.name}
              required
              onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">Full name is required.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="pi_email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="principalInvestigator[email]"
              required
              value={principalInvestigator.email}
              onChange={onChange}
              data-testid="txt-edaa-principalInvestigator-email"
            />
            <Form.Control.Feedback type="invalid">
              Email must be a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      )}
    </div>
  );
}

PIForm.propTypes = {
  principalInvestigator: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  setPIField: PropTypes.func.isRequired,
  isPi: PropTypes.bool,
  setIsPi: PropTypes.func.isRequired,
};

export default PIForm;
