import React from 'react';
import {useSelector} from 'react-redux';
import {Alert} from 'react-bootstrap';

import Link from '../Link';
import {getUser, getIsUserLoggedIn} from '../../../reducers/user/selectors';
import {documentationPath} from '../../../api/routes';

export function BillingSetupNotification() {
  const isLoggedIn = useSelector(getIsUserLoggedIn);
  const {billingSetup} = useSelector(getUser);
  if (!isLoggedIn || billingSetup) return null;

  return (
    <Alert variant="danger">
      Accessing St. Jude Cloud genomics data is free, however a DNAnexus Billing account must be
      configured before creating a DNAnexus project with St. Jude Cloud data. Visit your{' '}
      <Link to="/dashboard">St. Jude Cloud dashboard</Link> to finish setup. See{' '}
      <Link
        href={documentationPath('/faq#will-i-be-charged-for-using-st-jude-cloud-genomics-platform')}
      >
        our documentation
      </Link>{' '}
      for more information about what is available for free and what actions incur costs.
    </Alert>
  );
}

export default BillingSetupNotification;
