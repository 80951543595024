import React from 'react';
import {useParams} from 'react-router-dom';
import {graphql, useLazyLoadQuery} from 'react-relay';
import {Button, Container} from 'react-bootstrap';
import {ErrorComponent} from '@stjudecloud/ui-react';

import PageTemplate from '../../templates/PageTemplate';
import PageContent from '../../templates/PageContent';
import Link from '../../atoms/Link';

const accessRequestQuery = graphql`
  query AccessRequestPageQuery($id: ID!) {
    accessRequest(id: $id) {
      name
      form {
        envelope {
          applicants {
            name
            email
            signingStatus
            type
          }
        }
      }
    }
  }
`;

function AccessRequestPage() {
  const {id} = useParams();
  const {accessRequest} = useLazyLoadQuery(accessRequestQuery, {id});
  const envelope = accessRequest?.form?.envelope;

  const metaData = {
    htmlTitle: 'View Electronic Data Access Request Status',
    metaDescription:
      'View the status of your electronic data access request to St. Jude Cloud genomics data',
    metaKeywords: ['electronic data access request', 'DocuSign envelope status'],
  };

  if (!accessRequest) {
    return (
      <PageTemplate {...metaData}>
        <ErrorComponent variant="notFound" />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate {...metaData}>
      <PageContent as={Container}>
        <div className="electronic-data-access-request">
          <h1>Electronic Data Access Request</h1>
          <br />
          {envelope && envelope.applicants && (
            <div>
              {' '}
              <h4>Project: {accessRequest.name}</h4>
              <table className="table request-table">
                <thead>
                  <tr>
                    <th className="first-column">Role</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {envelope.applicants.map((applicant, index) => (
                    /* eslint-disable react/no-array-index-key */
                    <tr key={`${applicant.type}${index}`}>
                      <td>{applicant.type}</td>
                      <td>{applicant.name}</td>
                      <td>{applicant.signingStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <Button as={Link} to="/dashboard" variant="outline-primary">
            {' '}
            Return to Dashboard
          </Button>
        </div>
      </PageContent>
    </PageTemplate>
  );
}

export default AccessRequestPage;
