import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import {useParams} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';

import PageTemplate from '../../templates/PageTemplate';
import ToolList from '../../complexes/ToolList';
import BillingSetupNotification from '../../atoms/BillingSetupNotification';

import ErrorPage from '../ErrorPage';

import ToolHeader from './ToolHeader';
import ToolButtons from './ToolButtons';

export function metaDataForTool(tool) {
  const {name = 'Tools', subtitle} = tool || {};

  // Choose a default meta description if we didn't match anything
  const description = subtitle || `Work with ${name} on St. Jude Cloud.`;

  return {
    htmlTitle: name,
    metaDescription: description,
    metaKeywords: ['cloud genomic tools', 'St. Jude Cloud tools', description],
  };
}

const toolQuery = graphql`
  query WorkflowPage_index_Query($slug: String, $draftId: ID) {
    toolInterface(slug: $slug, draftId: $draftId) {
      name
      subtitle
      bodySanitized
      slug
      ...ToolButtons_Fragment
      ...ToolHeader_Fragment
    }
  }
`;

function WorkflowPage() {
  const {draftId, slug} = useParams();
  const isDraft = !!draftId;

  const {toolInterface: tool} = useLazyLoadQuery(toolQuery, {slug, draftId});
  if (!tool) return <ErrorPage variant="notFound" />;

  const metaData = metaDataForTool(tool);

  return (
    <PageTemplate {...metaData}>
      <Container className="tool-launcher-page py-5" fluid>
        <ToolHeader queryData={tool}>
          <ToolButtons queryData={tool} isDraft={isDraft} />
        </ToolHeader>
        <Container>
          <BillingSetupNotification />
        </Container>
        {tool && (
          <div
            className="markdown-component"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: tool.bodySanitized}}
          />
        )}
        <Container>
          <Row as="section">
            <Col>
              <h2 className="other-tools-title">
                <strong>Other Tools</strong>
              </h2>
              {tool && <ToolList except={tool.slug} next={2} />}
            </Col>
          </Row>
        </Container>
      </Container>
    </PageTemplate>
  );
}

export default WorkflowPage;
