import React, {useCallback, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {useLoading} from '../../hooks';
import SpinnerWrapper from '../../atoms/SpinnerWrapper';
import DropzoneForm from '../../complexes/DropzoneForm';

import {ApiUploadAddendumForm} from '../../../api/apiCalls';
import {newAPIV1AccessRequestAddendumFormsPath} from '../../../api/routes';
import {FILE_UPLOAD_VALIDATION} from '../../../constants';
import API from '../../../api';

const {FILE_SIZE_LIMIT, FILE_TOO_LARGE_MESSAGE, NO_FILE_MESSAGE, INCORRECT_FILE_TYPE_MESSAGE} =
  FILE_UPLOAD_VALIDATION;

async function uploadForm(id, file) {
  if (!file) throw new Error(NO_FILE_MESSAGE);
  if (file.size > FILE_SIZE_LIMIT) throw new Error(FILE_TOO_LARGE_MESSAGE);
  if (file.type !== 'application/pdf') throw new Error(INCORRECT_FILE_TYPE_MESSAGE);

  try {
    const response = await ApiUploadAddendumForm(id, file);
    if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (e) {
    // Handle API.fetchJSON throwing objects
    if (e.error) throw new Error(e.error.message);
    throw e;
  }
}

async function checkAndDownloadForm(id) {
  const response = await API.fetchData(newAPIV1AccessRequestAddendumFormsPath(id));

  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.error.message);
  }

  // download the file
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Renewal_Addendum.pdf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

const UploadExtensionAddendum = props => {
  const {id, setUploadSuccess, className} = props;
  const [{error: uploadError, isLoading: isUploading}, startFormUpload] = useLoading(null);
  const [{error: downloadError, isLoading: isDownloading}, startFormDownload] = useLoading(null);
  const [file, setFile] = useState(null);
  const [fileSubmitted, setFileSubmitted] = useState(false);

  const onDrop = useCallback(files => {
    if (files && files.length) {
      setFile(files[0]);
    }
  }, []);

  const uploadNewForm = useCallback(() => {
    setFileSubmitted(true);
    startFormUpload(() => uploadForm(id, file));
  }, [setFileSubmitted, startFormUpload, id, file]);

  const checkAndDownload = useCallback(() => {
    startFormDownload(() => checkAndDownloadForm(id));
  }, [id, startFormDownload]);

  useEffect(() => {
    setUploadSuccess(!uploadError && !isUploading && fileSubmitted);
  }, [fileSubmitted, isUploading, setUploadSuccess, uploadError]);

  return (
    <div className={className}>
      <h3>Complete the Extension Addendum</h3>
      <hr />
      <div className="my-4 d-flex flex-column">
        <strong>1. Download and sign your Extension Addendum</strong>
        <Button
          className="w-100 py-2 my-2 download-addendum-form"
          variant="primary"
          onClick={checkAndDownload}
          disabled={isDownloading}
        >
          <SpinnerWrapper show={isDownloading} inline>
            <i className="fa fa-download fa-lg" />
          </SpinnerWrapper>{' '}
          Download Extension Addendum Form
        </Button>

        <ul className="text-danger">
          {downloadError &&
            downloadError.message &&
            downloadError.message.split(';').map(e => (
              <li>
                <span>{e}</span>
              </li>
            ))}
        </ul>
      </div>

      <div className="d-flex flex-column">
        <strong>2. Then, upload your signed Extension Addendum below</strong>
        <SpinnerWrapper show={isUploading} styled>
          <div className="d-flex my-2">
            <DropzoneForm
              errors={uploadError ? [uploadError.message] : []}
              show
              onShow={() => {
                /* no-op */
              }}
              onHide={() => setFile(null)}
              onUpload={uploadNewForm}
              onDrop={onDrop}
              fileInfo={file}
              uploadText="Send Request"
              cancelText="Cancel"
            />
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
};

export default UploadExtensionAddendum;

UploadExtensionAddendum.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadSuccess: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};
