import React from 'react';
import {Container, Row, Button, Col} from 'react-bootstrap';

import PageTemplate from '../../templates/PageTemplate';
import HomePagePanel from './HomePagePanel';
import Link from '../../atoms/Link';

import GenomicsPlatformImage from '../../../../images/icon_app-genomics-platform.png';
import DataBrowserIcon from '../../../../images/icon_data-browser.png';
import WorkflowIcon from '../../../../images/icon_analysis-workflow.png';
import ManageDataIcon from '../../../../images/icon_manage-data.png';
import DataBrowserSectionImage from '../../../../images/section_data-browser.png';
import WorkflowsSectionImage from '../../../../images/section_analysis-workflow.png';

function HomePage() {
  const metaData = {
    metaDescription:
      "St. Jude Cloud's Genomics Platform is one of the world's largest repositories of pediatric genomics data and offers a suite of analysis workflows.",
    metaKeywords: ['Genomics Platform', 'genomics data', 'genomics analysis workflows'],
  };

  return (
    <PageTemplate {...metaData}>
      <div className="homepage">
        <div className="homepage__hero">
          <Container className="homepage__hero__text">
            <img src={GenomicsPlatformImage} alt="Genomics Platform" />
            <h1>Genomics Platform</h1>
            <h5 className="mt-2">
              A research platform designed to facilitate the sharing and analysis of genomics data
            </h5>
          </Container>
        </div>
        <Container className="pb-5">
          <Row className="mb-5">
            <HomePagePanel
              title="Data Browser"
              description="Mine one of the world's most comprehensive repositories of pediatric cancer genomics data."
              icon={DataBrowserIcon}
              cta="Explore Data"
              slug="data/diseases/paired-tumor-normal"
            />
            <HomePagePanel
              title="Analysis Workflows"
              description="Analyze genomics data using sophisticated computational pipelines built for speed and ease of use."
              icon={WorkflowIcon}
              cta="Run Workflows"
              slug="/workflows"
              className="homepage__middle-panel"
            />
            <HomePagePanel
              title="My Dashboard"
              description="View your projects, continue an access agreement, and work with your data."
              icon={ManageDataIcon}
              cta="Manage Data"
              slug="/dashboard"
            />
          </Row>
        </Container>
        <Container as="section" fluid className="background-light-blue py-5">
          <Container className="d-flex p-5">
            <Row>
              <Col md={4} className="homepage__section__img-left">
                <img src={DataBrowserSectionImage} alt="data-browser-reads" />
              </Col>
              <Col xs={12} md={8} className="homepage__section__text-right">
                <h3>About our Data Browser</h3>
                <p>
                  Explore and request data from one of the largest pediatric cancer genome
                  repositories. Our app offers high-quality whole genome sequencing (WGS), whole
                  exome sequencing (WES) and RNA-Seq data aligned to the latest reference genome,
                  GRCh38. Data can be viewed by disease, by publication and by curated dataset.
                </p>
                <p>
                  Access to data is simple and fast, without the need for downloading. Submit a
                  simple one-time request to access raw data.
                </p>
                <Button
                  variant="primary"
                  className="mt-2"
                  as={Link}
                  to="/data/diseases/paired-tumor-normal"
                >
                  Explore Data
                </Button>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container as="section" fluid className="homepage__section">
          <Container className="d-flex p-5">
            <Row>
              <Col xs={12} md={8} className="homepage__section__text-left">
                <h3>About our Analysis Workflows</h3>
                <p>
                  Use our unique collection of bioinformatics workflows and tools to quickly and
                  privately gain novel insights from next-generation sequencing data. Our end-to-end
                  computational pipelines have been thoroughly validated using real data and are
                  designed for ease of use by both non-specialists and experts alike.
                </p>
                <p>
                  Upload your own data, or use the workflows to analyze our data in new ways. There
                  is no installation and no waiting in line; simply click &quot;run,&quot; and you
                  will receive an email when your results are ready. Data and results can be
                  securely shared with collaborators within the platform.
                </p>
                <Button variant="primary" className="mt-2" as={Link} to="/workflows">
                  View Workflows
                </Button>
              </Col>
              <Col md={4} className="homepage__section__img-right">
                <img src={WorkflowsSectionImage} alt="genomics-analysis-workflow" />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </PageTemplate>
  );
}

export default HomePage;
