import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function CustomSelect({options, currentOption, onChange, className, validated, name, placeholder}) {
  const customStyles = {
    control: (styles, {isFocused}) => {
      let borderColor;
      if (!validated) {
        if (isFocused) {
          borderColor = 'rgb(131, 183, 241)';
        } else {
          borderColor = '#ddd';
        }
      } else if (currentOption && currentOption !== '') {
        borderColor = 'var(--success, green)';
      } else {
        borderColor = 'var(--danger, red)';
      }

      let boxShadow;
      if (!validated) {
        if (isFocused) {
          boxShadow = '0 0 0 3px rgba(131, 183, 241, 0.4)';
        }
      } else if (isFocused) {
        if (currentOption && currentOption !== '') {
          boxShadow = '0 0 0 3px rgba(117, 188, 33, 0.4)';
        } else {
          boxShadow = '0 0 0 3px rgba(224, 52, 0, 0.4)';
        }
      }

      let borderColor2;
      if (!validated) {
        if (isFocused) {
          borderColor2 = 'rgb(131, 183, 241)';
        } else {
          borderColor2 = '#ddd';
        }
      } else if (currentOption && currentOption !== '') {
        borderColor2 = 'var(--success, green)';
      } else {
        borderColor2 = 'var(--danger, red)';
      }

      return {
        ...styles,
        borderRadius: '40px',
        borderColor,
        boxShadow,
        '&:hover': {
          borderColor: borderColor2,
        },
      };
    },
    option: (styles, {isFocused, isSelected}) => {
      let backgroundColor;
      if (isSelected) {
        backgroundColor = 'rgba(24, 116, 220, 0.7)';
      } else if (isFocused) {
        backgroundColor = 'rgba(24, 116, 220, 0.2)';
      }

      return {
        ...styles,
        backgroundColor,
      };
    },
    input: styles => ({...styles, marginLeft: '6px', paddingBottom: '-12px'}),
    singleValue: styles => ({...styles, marginLeft: '6px'}),
    menu: styles => ({...styles, zIndex: 3}),
    dropdownIndicator: styles => {
      let color;
      if (!validated) {
        color = styles.color;
      } else if (currentOption && currentOption !== '') {
        color = 'var(--success, green)';
      } else {
        color = 'var(--danger, red)';
      }

      return {
        ...styles,
        color,
      };
    },
  };

  return (
    <Select
      styles={customStyles}
      options={options}
      value={options.find(option => option.value === currentOption)}
      onChange={onChange}
      className={className}
      name={name}
      placeholder={placeholder}
    />
  );
}

CustomSelect.defaultProps = {
  placeholder: '',
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  currentOption: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  validated: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default CustomSelect;
