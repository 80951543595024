import React from 'react';
import PropTypes from 'prop-types';

function Errors({errors}) {
  return errors.length ? (
    <div className="fw-bold text-danger">
      {errors.map(error => (
        <div key={error}>{error}</div>
      ))}
    </div>
  ) : null;
}

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Errors;
