import React from 'react';
import PropTypes from 'prop-types';
import {ErrorComponent} from '@stjudecloud/ui-react';

import PageTemplate from '../../templates/PageTemplate';

function ErrorPage(props) {
  const {variant} = props;
  const htmlTitle = variant === 'notFound' ? 'Not Found' : 'Internal Error';
  const metaData = {htmlTitle};

  return (
    <PageTemplate {...metaData}>
      <ErrorComponent {...props} />
    </PageTemplate>
  );
}

ErrorPage.defaultProps = {
  variant: 'internalServerError',
};

ErrorPage.propTypes = {
  variant: PropTypes.oneOf(['notFound', 'internalServerError']),
};

export default ErrorPage;
