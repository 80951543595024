import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {graphql, useFragment} from 'react-relay';

import Link from '../../../atoms/Link';

const toolFragment = graphql`
  fragment UserNotLoggedInButtons_Fragment on ToolInterface {
    path
  }
`;

export default function UserNotLoggedInButtons({queryData}) {
  const tool = useFragment(toolFragment, queryData);

  if (tool.path) {
    return (
      <Button variant="primary" as={Link} href={tool.path} newTab key="run" id="go-to-tool-btn">
        Go to Tool
      </Button>
    );
  }

  return (
    <div className="mt-2" key="login">
      <Link to="/login">Log in</Link> to launch this tool
    </div>
  );
}
UserNotLoggedInButtons.propTypes = {
  queryData: PropTypes.object.isRequired,
};
