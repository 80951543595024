import React from 'react';
import PropTypes from 'prop-types';

function AccessForm({requiredAccessUnits, accessUnits}) {
  return (
    <div>
      <div className="wizard-header">
        Based on the files you selected, you will need to request access to these datasets.
      </div>
      <div className="wizard-sub-header">
        For the sake of protecting our human research subjects, we control access to data files that
        contain information about individuals. These data access units should be reflected on your
        signed contract.
      </div>
      <br />
      <div className="access-units">
        <span className="box-header">Data Access Units (DAUs) you are requesting:</span>
        <ul style={{marginLeft: '20px'}}>
          {accessUnits.map(accessUnit => {
            const {name, description} = accessUnit;
            if (requiredAccessUnits.includes(name)) {
              /* eslint-disable-next-line react/no-danger */
              return <li key={name} dangerouslySetInnerHTML={{__html: description}} />;
            }
            return null;
          })}
        </ul>
      </div>
    </div>
  );
}

AccessForm.propTypes = {
  requiredAccessUnits: PropTypes.array.isRequired,
  accessUnits: PropTypes.array.isRequired,
};

export default AccessForm;
