import React from 'react';
import {Button} from 'react-bootstrap';

import {apiV1ManifestPath} from '../../../api/routes';
import DownloadLink from '../../atoms/DownloadLink';

export default function DownloadManifestButton() {
  return (
    <Button
      variant="outline-primary"
      as={DownloadLink}
      size="sm"
      className="me-1"
      href={`${apiV1ManifestPath}.tsv`}
      download="SAMPLE_INFO.tsv"
    >
      Download All Metadata
    </Button>
  );
}

DownloadManifestButton.propTypes = {};
