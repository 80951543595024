import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from 'react-relay';
import {graphql} from 'relay-runtime';

import SubsectionTitle from './SubsectionTitle';
import UserDetail from './UserDetail';
import USER_TYPES from './userTypes';

const YES_WITH_IT_DIRECTOR =
  'Yes, I want to download the data and my Information Security Officer is:';
const YES_WITHOUT_IT_DIRECTOR = 'Yes, I want to download the data';
const NO = 'No, I am not planning to download';

const downloadDataFragment = graphql`
  fragment DownloadData_AddendumPage_Fragment on AccessRequestDraft {
    wantsToDownload
    itDirector {
      name
      email
    }
  }
`;

const DownloadData = ({queryData, upsertDraft, upserting}) => {
  const {wantsToDownload, itDirector} = useFragment(downloadDataFragment, queryData);
  const [iso, setIso] = useState(itDirector);

  const upsert = user => {
    const onError = message => {
      setIso({...iso, error: message});
    };
    upsertDraft({itDirector: user}, onError);
  };

  const onCancel = () => {
    setIso({...iso, editMode: false});
  };

  const onSave = user => {
    upsert(user);
  };

  const onClick = () => {
    setIso({...iso, editMode: true});
  };

  const hasItDirector = itDirector && itDirector.name && itDirector.email;

  let text;
  if (!wantsToDownload) text = NO;
  else if (wantsToDownload && hasItDirector) text = YES_WITH_IT_DIRECTOR;
  else text = YES_WITHOUT_IT_DIRECTOR;

  useEffect(() => {
    setIso(itDirector);
  }, [itDirector]);

  return (
    <div className="addendum-subsection">
      <SubsectionTitle
        title="Download Data"
        icon={<i className="fas fa-info-circle" />}
        info="If you opted in to downloading the data, you must include a signature from the Information Security Officer or IT Director, edit this section if that person has changed. If you did not indicate intent to download previously, you must submit a new DAA to do so now."
      />
      <div className="d-flex mb-2">
        <span className="me-3">
          <i className={`fas fa-${wantsToDownload ? 'check text-success' : 'times text-danger'}`} />
        </span>

        <span className="fw-light">{text}</span>
      </div>
      {wantsToDownload && hasItDirector && (
        <UserDetail
          name={iso.name}
          email={iso.email}
          icon={<i className="fas fa-user-shield" />}
          onCancel={onCancel}
          onSave={onSave}
          onClick={onClick}
          editMode={iso.editMode}
          type={USER_TYPES.ISO}
          upserting={upserting}
          error={iso.error}
          canEdit
        />
      )}
    </div>
  );
};

export default DownloadData;

DownloadData.propTypes = {
  queryData: PropTypes.object.isRequired,
  upsertDraft: PropTypes.func.isRequired,
  upserting: PropTypes.bool.isRequired,
};
