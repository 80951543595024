import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Alert} from 'react-bootstrap';

import clearFlashAlert from './actions';

function FlashAlert({variant, dismissible, children, alertKey, onClose}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const onAlertClose = () => {
    if (onClose) {
      onClose();
    }

    dispatch(clearFlashAlert(alertKey));
    setShow(false);
  };

  if (show) {
    return (
      <Alert
        variant={variant}
        onClose={onAlertClose}
        dismissible={dismissible}
        className="rounded-0 mb-0"
      >
        {children}
      </Alert>
    );
  }

  return null;
}

FlashAlert.defaultProps = {
  dismissible: true,
};

FlashAlert.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]).isRequired,
  children: PropTypes.node.isRequired,
  dismissible: PropTypes.bool,
  alertKey: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};

export default FlashAlert;
