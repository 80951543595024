import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Overlay, Tooltip} from 'react-bootstrap';

/* Heavily based on https://gist.github.com/lou/571b7c0e7797860d6c555a9fdc0496f9
 * Discussion in react-bootstrap https://github.com/react-bootstrap/react-bootstrap/issues/1622
 *
 * Usage:
 * <StickyToolip
 *    component={<div>Holy guacamole! I'm Sticky.</div>}
 *    placement="top"
 *    onMouseEnter={() => { }}
 *    delay={200}
 * >
 *   <div>Show the sticky tooltip</div>
 * </StickyTooltip>
 */

function StickyTooltip({delay, onMouseEnter, component, children, placement}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const setTimeoutConst = useRef(null);
  const childRef = useRef(null);

  useEffect(
    () => () => {
      if (setTimeoutConst.current) {
        clearTimeout(setTimeoutConst.current);
        setTimeoutConst.current = null;
      }
    },
    []
  );

  useEffect(() => {
    if (showTooltip && onMouseEnter) {
      onMouseEnter();
    }
  }, [showTooltip, onMouseEnter]);

  const handleMouseEnter = useCallback(() => {
    setTimeoutConst.current = setTimeout(() => {
      setShowTooltip(true);
    }, delay);
  }, [delay]);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(setTimeoutConst.current);
    setShowTooltip(false);
  }, []);

  const child = React.Children.map(children, c =>
    React.cloneElement(c, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      ref: node => {
        childRef.current = node;
        const {ref} = c;
        if (typeof ref === 'function') {
          ref(node);
        }
      },
    })
  )[0];

  return (
    <>
      {child}
      <Overlay
        show={showTooltip}
        placement={placement}
        target={childRef.current}
        shouldUpdatePosition
      >
        <Tooltip
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={handleMouseLeave}
          id="tooltip"
        >
          {component}
        </Tooltip>
      </Overlay>
    </>
  );
}

StickyTooltip.defaultProps = {
  delay: 0,
};

StickyTooltip.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  component: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

export default StickyTooltip;
