import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

import Link from '../../../atoms/Link';

export default function SuccessPage({requestType}) {
  let msg = 'The data will be vended to your project. This may take a few minutes.';
  if (requestType && requestType === 'manual') {
    msg =
      'Your application will be reviewed by the data access committees and you will receive an email after review.';
  } else if (requestType) {
    msg =
      'Please check your email for a DocuSign link in order to complete the process. Once all the signatories have signed and submitted the data access agreement, the relevant data access committees will review the application.';
  }
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="wizard-header">Your request has been submitted!</div>
      <div className="wizard-sub-header">{msg}</div>
      <br />
      <Button as={Link} to="/dashboard" variant="primary">
        To Manage Data
      </Button>
    </div>
  );
}

SuccessPage.propTypes = {
  requestType: PropTypes.string,
};
