import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import {documentationPath} from '../../../../api/routes';
import Link from '../../../atoms/Link';

function ContemplatedUseForm({contemplatedUse, setContemplatedUse}) {
  const onChange = event => {
    const {value} = event.target;
    setContemplatedUse(value);
  };

  return (
    <div>
      <div className="wizard-header center">
        Explain how the data will be used in your research.{' '}
      </div>
      <div className="wizard-sub-header">
        Please use the following space to provide a project description of the research including
        your contemplated use of the St. Jude Data, that constitutes biomedical research. Include
        information about what research question you will be investigating and how the requested
        data will aid in your research. (150 words or more) <br /> <br />
        For examples and more information,{' '}
        <Link
          href={documentationPath('/requesting-data/how-to-fill-out-DAA/#contemplated-use-example')}
          newTab
        >
          click here.
        </Link>
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <Form.Group controlId="request_contemplatedUse">
          <Form.Control
            cols="120"
            rows="10"
            as="textarea"
            required
            value={contemplatedUse}
            name="request[contemplatedUse]"
            data-testid="txt-contemplated-use"
            onChange={onChange}
            minLength={300}
          />
          <Form.Control.Feedback type="invalid">
            Contemplated use should be more than 300 characters.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

ContemplatedUseForm.propTypes = {
  contemplatedUse: PropTypes.string,
  setContemplatedUse: PropTypes.func.isRequired,
};

export default ContemplatedUseForm;
