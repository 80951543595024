import React from 'react';
import PropTypes from 'prop-types';

import SummaryTable from './SummaryTable';
import SidebarFilters from './SidebarFilters';

function SideNav({
  filters,
  setFilters,
  tab,
  shareableURL,
  shouldRefetchSummary,
  databrowserFragmentData,
  sidebarFiltersFragmentData,
}) {
  return (
    <div>
      <SummaryTable
        databrowserFragmentData={databrowserFragmentData}
        filters={filters}
        shouldRefetchSummary={shouldRefetchSummary}
        tab={tab}
      />
      <SidebarFilters
        sidebarFiltersFragmentData={sidebarFiltersFragmentData}
        filters={filters}
        setFilters={setFilters}
        shareableURL={shareableURL}
      />
    </div>
  );
}

SideNav.propTypes = {
  tab: PropTypes.string.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  shouldRefetchSummary: PropTypes.bool,
  sidebarFiltersFragmentData: PropTypes.any,
  databrowserFragmentData: PropTypes.object,
  shareableURL: PropTypes.string.isRequired,
};

export default SideNav;
