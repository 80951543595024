import React from 'react';
import {Button, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from '../../../atoms/Link';
import Image from '../../../atoms/Image';

const HomePagePanel = props => {
  const {title, description, icon, cta, slug, className} = props;
  const topClassNames = classnames('homepage__panel', className);

  return (
    <Col lg={4} className={topClassNames}>
      <Image className="p-2 m-3" src={icon} />
      <h5>{title}</h5>
      <p>{description}</p>
      <Button variant="primary" as={Link} to={slug}>
        {cta}
      </Button>
    </Col>
  );
};

HomePagePanel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  cta: PropTypes.string,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HomePagePanel;
