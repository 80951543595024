import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import PageTemplate from '../../templates/PageTemplate';
import PageContent from '../../templates/PageContent';
import Link from '../../atoms/Link';
import ToolList from '../../complexes/ToolList';
import {documentationBasePath} from '../../../api/routes';

function AnalysisWorkflowsPage() {
  const metaData = {
    htmlTitle: 'Analysis Workflows',
    metaDescription: 'Unique collection of bioinformatics tools',
    metaKeywords: ['tools', 'analysis workflows', 'workflows'],
  };
  return (
    <PageTemplate {...metaData}>
      <PageContent as={Container}>
        <Row className="g-0">
          <Col>
            <h1>Analysis Workflows</h1>
            <p className="mb-0">
              Use our unique collection of bioinformatics tools to quickly and privately gain novel
              insights from complex data. Our powerful algorithms have been thoroughly validated
              using real data and are designed for ease of use by both non–specialists and experts.
              Upload your own data, or use the tools to analyze the data we provide in new ways.
              Data and results can be securely shared with collaborators within the platform.
            </p>
            <Link href={documentationBasePath()} newTab>
              View help guides &gt;
            </Link>
            <p className="py-4">
              Notice: These tools produce high-quality data, but the interface is still undergoing
              improvements to provide a better experience.
            </p>
          </Col>
        </Row>
        <Row as={ToolList} />
      </PageContent>
    </PageTemplate>
  );
}

export default AnalysisWorkflowsPage;
