import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

const WizardForm = React.forwardRef(({children, validated}, formRef) => (
  <Form
    className="wizard-form"
    noValidate
    validated={validated}
    ref={formRef}
    onSubmit={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {children}
  </Form>
));

WizardForm.propTypes = {
  children: PropTypes.node,
  validated: PropTypes.bool,
};

export default WizardForm;
