/**
 * @generated SignedSource<<ad759bee99df1ae8d9302183a6db0228>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeDatabrowserRows"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "initialFetch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "summaryFilters"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFileSize",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFileCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSampleCount",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "diagnosisCode",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "databrowserQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Databrowser_Fragment"
      },
      {
        "condition": "initialFetch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Databrowser",
            "kind": "LinkedField",
            "name": "databrowser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SidebarFilters_Filters_Fragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "databrowserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Databrowser",
        "kind": "LinkedField",
        "name": "databrowser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "summaryFilters"
              },
              (v5/*: any*/)
            ],
            "concreteType": "DatabrowserSelectionSummary",
            "kind": "LinkedField",
            "name": "selectionSummary",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraData",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "includeDatabrowserRows",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Databrowser",
            "kind": "LinkedField",
            "name": "databrowser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters"
                  },
                  (v5/*: any*/)
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sampleTypes",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seqTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessUnits",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allSeqTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allFileTypes",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diseaseCode",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserTumor",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "DatabrowserPairedTumorNormal",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "DatabrowserGermline",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accessionNumber",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserDataset",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publicationAccession",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pmid",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserPublication",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "initialFetch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Databrowser",
            "kind": "LinkedField",
            "name": "databrowser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DatabrowserFilter",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filters",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a1b17f4d0cf10b2e5cba0928a8c4aa62",
    "id": null,
    "metadata": {},
    "name": "databrowserQuery",
    "operationKind": "query",
    "text": "query databrowserQuery(\n  $filters: DatabrowserFiltersInput\n  $summaryFilters: DatabrowserFiltersInput\n  $includeDatabrowserRows: Boolean!\n  $type: DatabrowserTabType!\n  $initialFetch: Boolean!\n) {\n  ...Databrowser_Fragment\n  databrowser @include(if: $initialFetch) {\n    ...SidebarFilters_Filters_Fragment\n  }\n}\n\nfragment Databrowser_Fragment on Query {\n  ...SummaryTable_Summary_Fragment\n  databrowser @include(if: $includeDatabrowserRows) {\n    items(filters: $filters, type: $type) {\n      __typename\n      name\n      sampleTypes\n      totalFileSize\n      totalFileCount\n      totalSampleCount\n      seqTypes\n      fileTypes\n      accessUnits\n      allSeqTypes\n      allFileTypes\n      ... on DatabrowserTumor {\n        diseaseCode\n      }\n      ... on DatabrowserPairedTumorNormal {\n        diagnosisCode\n      }\n      ... on DatabrowserGermline {\n        diagnosisCode\n      }\n      ... on DatabrowserDataset {\n        accessionNumber\n      }\n      ... on DatabrowserPublication {\n        publicationAccession\n        pmid\n      }\n    }\n  }\n}\n\nfragment SidebarFilters_Filters_Fragment on Databrowser {\n  filters {\n    type\n    filters\n  }\n}\n\nfragment SummaryTable_Summary_Fragment on Query {\n  databrowser {\n    selectionSummary(filters: $summaryFilters, type: $type) {\n      totalFileSize\n      totalFileCount\n      totalSampleCount\n      extraData\n    }\n  }\n}\n"
  }
};
})();

node.hash = "1d8042bac1a5c728a575274282ad2043";

module.exports = node;
