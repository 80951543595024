/**
 * @generated SignedSource<<9012be1691228dfd9143b56ecf965ccf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarFilters_Filters_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DatabrowserFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filters",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Databrowser",
  "abstractKey": null
};

node.hash = "aa9830bbfbac5b21cb17af6688684576";

module.exports = node;
