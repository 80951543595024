import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';

import parseFormFieldName from './formUtils';
import Link from '../../../atoms/Link';

function AAForm({
  additionalApplicants,
  setApplicantField,
  setNoApplicants,
  addNewApplicant,
  removeApplicant,
  isPi,
  scrollTarget,
}) {
  const [showForm, setShowForm] = useState(additionalApplicants.length > 0);

  const onChange = event => {
    const {name, value} = event.target;
    const [index, field] = parseFormFieldName(name);

    setApplicantField(index, field, value);
  };

  const MAX_APPLICANTS = isPi ? 8 : 7;

  const addNewApplicantAndScroll = () => {
    addNewApplicant(() =>
      scrollTarget.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    );
  };

  return (
    <div>
      <div className="wizard-header center">Are there any additional applicants?</div>
      <div className="wizard-sub-header">
        Anyone else who will have access to this data must be listed as an additional applicant.
      </div>
      <br />
      <Form.Group>
        <div className="options-row">
          <Button
            variant="primary"
            onClick={() => {
              if (additionalApplicants.length === 0) {
                // First time showing drop down
                addNewApplicant();
              }
              setShowForm(true);
            }}
            className="options-button"
          >
            Yes
          </Button>
          <Button variant="primary" onClick={setNoApplicants} className="options-button">
            No
          </Button>
        </div>
      </Form.Group>
      {showForm && (
        <div className="d-flex flex-column align-items-center">
          <hr className="dropdown-line" />
          <div>
            {additionalApplicants.map((applicant, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <div key={index}>
                <div className="page-sub-header applicant-header">
                  Applicant {index + 1}
                  <Button variant="outline-primary" onClick={() => removeApplicant(index)}>
                    Remove applicant
                  </Button>
                </div>
                <Form.Group controlId={`applicants_${index}_name`}>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name={`additionalApplicants[${index}][name]`}
                    value={applicant.name}
                    required
                    onChange={onChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Full name is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId={`applicants_${index}_email`}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name={`additionalApplicants[${index}][email]`}
                    value={applicant.email}
                    required
                    onChange={onChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email must be a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            ))}
            {additionalApplicants.length >= MAX_APPLICANTS ? (
              <div className="form-text" data-testid="edaa-aa-max">
                If you would like to add more applicants, please email us at{' '}
                <Link href="mailto:support@stjude.cloud">support@stjude.cloud</Link>.
              </div>
            ) : (
              <div className="options-row my-4">
                <Button
                  variant="outline-primary"
                  onClick={addNewApplicantAndScroll}
                  data-testid="edaa-aa-btn-add"
                >
                  + Add Another Applicant
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

AAForm.propTypes = {
  additionalApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  setApplicantField: PropTypes.func.isRequired,
  setNoApplicants: PropTypes.func.isRequired,
  addNewApplicant: PropTypes.func.isRequired,
  removeApplicant: PropTypes.func.isRequired,
  isPi: PropTypes.bool,
  scrollTarget: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

export default AAForm;
