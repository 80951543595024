/**
 * @generated SignedSource<<8beee27cfbd5dda208bbe979879205ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eaInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "eaInput"
      }
    ],
    "concreteType": "UpsertExtensionAddendumMutationPayload",
    "kind": "LinkedField",
    "name": "upsertExtensionAddendum",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccessRequestDraft",
        "kind": "LinkedField",
        "name": "accessRequestDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdditionalApplicant",
            "kind": "LinkedField",
            "name": "additionalApplicants",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdditionalApplicant",
            "kind": "LinkedField",
            "name": "institutionalAuthority",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdditionalApplicant",
            "kind": "LinkedField",
            "name": "itDirector",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumPage_UpsertExtensionAddendum_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumPage_UpsertExtensionAddendum_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2016ccd52aaa562769e1d7ccc0d1c844",
    "id": null,
    "metadata": {},
    "name": "AddendumPage_UpsertExtensionAddendum_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddendumPage_UpsertExtensionAddendum_Mutation(\n  $eaInput: UpsertExtensionAddendumMutationInput!\n) {\n  upsertExtensionAddendum(input: $eaInput) {\n    accessRequestDraft {\n      id\n      iid\n      additionalApplicants {\n        name\n        email\n      }\n      institutionalAuthority {\n        name\n        email\n      }\n      itDirector {\n        name\n        email\n      }\n    }\n    error {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "a79a4962c4fade32d422787fc67f04d3";

module.exports = node;
