/**
 * @generated SignedSource<<7bf7a1c66a189394c86be0b83bf0b3f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeDatabrowserRows"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "summaryFilters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFileSize",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFileCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSampleCount",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "diagnosisCode",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DatabrowserRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Databrowser_Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DatabrowserRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Databrowser",
        "kind": "LinkedField",
        "name": "databrowser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "summaryFilters"
              },
              (v1/*: any*/)
            ],
            "concreteType": "DatabrowserSelectionSummary",
            "kind": "LinkedField",
            "name": "selectionSummary",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraData",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "includeDatabrowserRows",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Databrowser",
            "kind": "LinkedField",
            "name": "databrowser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters"
                  },
                  (v1/*: any*/)
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sampleTypes",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seqTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessUnits",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allSeqTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allFileTypes",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diseaseCode",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserTumor",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "DatabrowserPairedTumorNormal",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "DatabrowserGermline",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accessionNumber",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserDataset",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publicationAccession",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pmid",
                        "storageKey": null
                      }
                    ],
                    "type": "DatabrowserPublication",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "38a0af5e115bda13711dec692dafcdf3",
    "id": null,
    "metadata": {},
    "name": "DatabrowserRefetchQuery",
    "operationKind": "query",
    "text": "query DatabrowserRefetchQuery(\n  $filters: DatabrowserFiltersInput\n  $includeDatabrowserRows: Boolean!\n  $summaryFilters: DatabrowserFiltersInput\n  $type: DatabrowserTabType!\n) {\n  ...Databrowser_Fragment\n}\n\nfragment Databrowser_Fragment on Query {\n  ...SummaryTable_Summary_Fragment\n  databrowser @include(if: $includeDatabrowserRows) {\n    items(filters: $filters, type: $type) {\n      __typename\n      name\n      sampleTypes\n      totalFileSize\n      totalFileCount\n      totalSampleCount\n      seqTypes\n      fileTypes\n      accessUnits\n      allSeqTypes\n      allFileTypes\n      ... on DatabrowserTumor {\n        diseaseCode\n      }\n      ... on DatabrowserPairedTumorNormal {\n        diagnosisCode\n      }\n      ... on DatabrowserGermline {\n        diagnosisCode\n      }\n      ... on DatabrowserDataset {\n        accessionNumber\n      }\n      ... on DatabrowserPublication {\n        publicationAccession\n        pmid\n      }\n    }\n  }\n}\n\nfragment SummaryTable_Summary_Fragment on Query {\n  databrowser {\n    selectionSummary(filters: $summaryFilters, type: $type) {\n      totalFileSize\n      totalFileCount\n      totalSampleCount\n      extraData\n    }\n  }\n}\n"
  }
};
})();

node.hash = "d71bed58828c19b6cc70933b76e9b904";

module.exports = node;
