import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Row, Col, Image} from 'react-bootstrap';
import {Navigate} from 'react-router-dom';
import classname from 'classnames';

import CloudAnalysis from '../../../../../../assets/images/icon-cloud-analysis.svg';
import ViewOnly from '../../../../../../assets/images/icon-view-only.svg';

function WizardCard({title, subtitle, onClick, children, active, image, viewOnly, disabled}) {
  return (
    <div
      className={classname('wizard-card', {active, disabled})}
      onClick={disabled ? null : onClick}
      onKeyDown={null}
      role="button"
      tabIndex={0}
    >
      <Image className="wizard-card__image" src={image} />
      <div className="title-contianer">
        <div className="title">
          <strong>{title}</strong>
        </div>
      </div>
      <div className="subtitle-container">
        <div className="subtitle">
          <strong>{subtitle}</strong>
        </div>
      </div>
      {children}
      <Button
        variant={viewOnly ? 'outline-primary' : 'primary'}
        onClick={onClick}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
}

WizardCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.any.isRequired,
  children: PropTypes.node,
};

function ViewOnlyForm({
  viewOnly,
  onSelectViewOnly,
  onSelectCloudAnalysis,
  canChooseProjectType,
  needsToRequestAccess,
  billingSetup,
}) {
  if (!canChooseProjectType) {
    if (needsToRequestAccess) {
      return <Navigate to="project" />;
    }

    return <Navigate to="request_details" />;
  }

  return (
    <div>
      <div className="wizard-header">
        Vend the selected data to a view only project or a cloud analysis project?
      </div>
      <br />
      <Form.Group>
        <Row>
          <Col md={12} lg={6} className="p-md-2">
            <WizardCard
              title="Cloud Analysis Project"
              subtitle="Best option for using cloud based workflows and having complete control over your project"
              onClick={onSelectCloudAnalysis}
              image={CloudAnalysis}
              active={!viewOnly}
              disabled={!billingSetup}
            >
              <ul>
                <li>
                  <i className="fa-li fas fa-plus-circle" />
                  View or download data for free, sponsored by St. Jude Cloud
                </li>
                <li>
                  Run cloud workflows curated by St. Jude Cloud, or upload and build your own to
                  analyze data immediately
                </li>
                <li>
                  Have admin access over your project with the option to upload your own data and
                  add collaborators
                </li>
                <li className={classname({warn: !billingSetup, okay: billingSetup})}>
                  Requires having a billing account set up with DNAnexus (though access and storage
                  of St. Jude Cloud data does not incur charges)
                </li>
              </ul>
            </WizardCard>
          </Col>
          <Col md={12} lg={6} className="p-md-2">
            <WizardCard
              title="View Only Project"
              subtitle="Best option for downloading St. Jude Cloud data"
              onClick={onSelectViewOnly}
              image={ViewOnly}
              active={viewOnly}
              viewOnly
            >
              <ul>
                <li>View or download data for free, sponsored by St. Jude Cloud</li>
                <li>
                  These projects don’t require any billing setup, St. Jude Cloud is the project
                  owner and admin
                </li>
                <li>Data can be copied into other projects on DNAnexus</li>
                <li className="con">No cloud workflows can be used with this type of project</li>
                <li className="con">
                  Downloading large amounts of data is inherently error prone and may take a long
                  time
                </li>
              </ul>
            </WizardCard>
          </Col>
        </Row>
      </Form.Group>
      <br />
    </div>
  );
}

ViewOnlyForm.propTypes = {
  viewOnly: PropTypes.bool,
  canChooseProjectType: PropTypes.bool,
  onSelectViewOnly: PropTypes.func.isRequired,
  onSelectCloudAnalysis: PropTypes.func.isRequired,
  needsToRequestAccess: PropTypes.bool,
  billingSetup: PropTypes.bool,
};

export default ViewOnlyForm;
