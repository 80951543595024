import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import PageTemplate from '../../templates/PageTemplate';
import Link from '../../atoms/Link';
import {getIsUserLoggedIn, getUserIsLoading} from '../../../reducers/user/selectors';
import RestrictedContext from '../RestrictedContext';

function withUserAuthentication(WrappedComponent) {
  function AuthRequiredComponent(props) {
    const isLoggedIn = useSelector(getIsUserLoggedIn);
    const isLoading = useSelector(getUserIsLoading);
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoggedIn && !isLoading) {
        navigate('/login', {replace: true});
      }
    }, [isLoading, isLoggedIn, navigate]);

    if (isLoggedIn && !isLoading) {
      // The user is logged in and not loading, so render the component
      return (
        <RestrictedContext.Provider value>
          <WrappedComponent {...props} />
        </RestrictedContext.Provider>
      );
    }

    if (isLoading) {
      // The user is not logged in, but async calls are resolving, display
      // nothing for the moment until we know for sure the user is auth'd.
      return null;
    }

    // The user is not logged in or in the process of logging in, so
    // redirect them to the login page.
    return (
      <PageTemplate>
        <div className="login-redirect-card">
          <div className="content-wrap">
            <div className="login-notification-title">Please Log In</div>
            <p className="redirect-text">
              You must be logged in to see this page. You&#39;re being redirected to the St. Jude
              Cloud Platform login page. If you aren&#39;t redirected within 5 seconds, please&nbsp;
              <Link to="/login">click here</Link> to log in.
            </p>
          </div>
        </div>
      </PageTemplate>
    );
  }

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  AuthRequiredComponent.displayName = `withUserAuthentication(${displayName})`;

  return AuthRequiredComponent;
}

export default withUserAuthentication;
