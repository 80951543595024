import React from 'react';
import {useLocation} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from '../../atoms/Link';
import {documentationPath} from '../../../api/routes';

const DOCUMENTATION_LINKS = {
  '/requests/access': '/about-our-data/dau-and-datasets/#list-of-daus',
  '/requests/project': '/requesting-data/how-to-fill-out-daa/',
  '/requests/user': '/requesting-data/how-to-fill-out-daa/',
  '/requests/application_type':
    '/requesting-data/how-to-fill-out-daa/#the-electronic-data-access-agreement-process',
  '/requests/manual': '/requesting-data/how-to-fill-out-daa/',
  '/requests/pi': '/requesting-data/how-to-fill-out-daa/#principal-investigator',
  '/requests/signing_official': '/requesting-data/how-to-fill-out-daa/#institutional-authority',
  '/requests/data_security': '/requesting-data/how-to-fill-out-daa/#downloading-data',
  '/requests/additional_applicants': '/requesting-data/how-to-fill-out-daa/#additional-applicants',
  '/requests/contemplated_use': '/requesting-data/how-to-fill-out-daa/#contemplated-use',
  '/requests/summary': '/requesting-data/how-to-fill-out-daa/',
  '/requests/request_details': '/requesting-data/how-to-fill-out-daa/',
};

export default function WizardDocumentationButton({className, fullContentPage}) {
  const {pathname} = useLocation();

  if (!Object.prototype.hasOwnProperty.call(DOCUMENTATION_LINKS, pathname)) return null;

  const path = DOCUMENTATION_LINKS[pathname];

  const contentTypeClassName = fullContentPage ? 'full-content' : 'sidebar';
  const classNames = classnames('wizard-documentation-button', className, contentTypeClassName);

  return (
    <div className={classNames}>
      <Link href={documentationPath(path)} newTab>
        Get Help on This Step
        <Button variant="primary" className="wizard-documentation-button__help-icon">
          ?
        </Button>
      </Link>
    </div>
  );
}

WizardDocumentationButton.defaultProps = {
  className: '',
};

WizardDocumentationButton.propTypes = {
  className: PropTypes.string,
  fullContentPage: PropTypes.bool.isRequired,
};
