import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';
import {Navigate} from 'react-router-dom';

import Link from '../../../../atoms/Link';
import SpinnerWrapper from '../../../../atoms/SpinnerWrapper';

import SubmitError from '../../SubmitError';

import SummaryHeading from './SummaryHeading';
import SummaryField from './SummaryField';
import AdditionalApplicantsSummary from './AdditionalApplicantsSummary';
import ITDSummary from './ITDSummary';
import PISummary from './PISummary';
import ContemplatedUseSummary from './ContemplatedUseSummary';

export function SummaryPage(props) {
  const [savedFromSummaryPage, setSavedFromSummaryPage] = useState(false);

  const {
    submitDraft,
    saveDraft,
    projectName,
    folderName,
    user,
    isPi,
    principalInvestigator,
    additionalApplicants,
    institutionalAuthority,
    wantsToDownload,
    itDirector,
    contemplatedUse,
    requestType,
    draftIsUpserting,
    upsertSuccessful,
    draftIsSubmitting,
    submitError,
    resetSubmitError,
    viewOnly,
    wantsToVendToExistingProject,
  } = props;

  useEffect(resetSubmitError, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!draftIsUpserting && upsertSuccessful && savedFromSummaryPage) {
    return <Navigate to="/dashboard" />;
  }

  const onSaveDraft = () => {
    resetSubmitError();
    setSavedFromSummaryPage(true);
    saveDraft();
  };

  return (
    <div>
      <div className="wizard-header center">Review the information below.</div>
      <br />
      <div className="summary-form">
        <SummaryHeading heading="Project" editPath="project" />
        <Form.Group>
          <SummaryField className="project" name="Project Name" value={projectName} />
          <SummaryField className="project" name="Folder Name" value={folderName} />

          <SummaryField
            className="project"
            name="Project Type"
            value={viewOnly ? 'View Only Project' : 'Cloud Analysis Project'}
          />
          <Form.Check>
            <Form.Check.Label className="checkbox-container">
              <Form.Check.Input type="checkbox" checked={wantsToVendToExistingProject} disabled />
              My data will be vended to an existing project.
              <span className="checkmark" />
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <SummaryHeading heading="About You" editPath="user" />
        <Form.Group>
          <div>
            <SummaryField name="Full Name" value={user.fullName} />
            <SummaryField name="Institution" value={user.institution} />
            <SummaryField name="Job Title" value={user.jobTitle} />
            <SummaryField name="Email" value={user.email} />
          </div>
        </Form.Group>

        <SummaryHeading heading="Principal Investigator" editPath="pi" />
        <Form.Group>
          <PISummary user={user} principalInvestigator={principalInvestigator} isPi={isPi} />{' '}
        </Form.Group>

        <SummaryHeading heading="Additional Applicants" editPath="additional_applicants" />
        <Form.Group>
          <AdditionalApplicantsSummary additionalApplicants={additionalApplicants} />
        </Form.Group>

        <SummaryHeading heading="Administrative Authority" editPath="signing_official" />
        <Form.Group>
          <SummaryField name="Full Name" value={institutionalAuthority.name} />
          <SummaryField name="Email" value={institutionalAuthority.email} />
        </Form.Group>

        <SummaryHeading heading="Download Data" editPath="data_security" />
        <Form.Group>
          <ITDSummary
            wantsToDownload={wantsToDownload}
            isStjude={user.isStjude}
            itDirector={itDirector}
          />
        </Form.Group>

        <SummaryHeading heading="Contemplated Use" editPath="contemplated_use" />
        <Form.Group>
          <ContemplatedUseSummary contemplatedUse={contemplatedUse} />
        </Form.Group>

        <SpinnerWrapper show={draftIsSubmitting || draftIsUpserting} fadeIn="none" styled>
          <Button
            variant="outline-primary"
            className="my-2 w-50 align-self-center"
            onClick={onSaveDraft}
          >
            Save My Progress and Continue Later
          </Button>
          {requestType === 'electronic' ? (
            <Button variant="primary" className="my-2 w-50 align-self-center" onClick={submitDraft}>
              Submit
            </Button>
          ) : (
            <Button
              variant="primary"
              className="my-2 w-50 align-self-center"
              as={Link}
              to="/requests/manual"
            >
              Continue
            </Button>
          )}
        </SpinnerWrapper>
        <SubmitError message={submitError} />
      </div>
    </div>
  );
}

SummaryPage.propTypes = {
  submitDraft: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  folderName: PropTypes.string,
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resetSubmitError: PropTypes.func.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    jobTitle: PropTypes.string,
    institution: PropTypes.string,
    country: PropTypes.string,
    isStjude: PropTypes.bool,
  }),
  isPi: PropTypes.bool,
  principalInvestigator: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  additionalApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  institutionalAuthority: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  wantsToDownload: PropTypes.bool,
  itDirector: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  contemplatedUse: PropTypes.string,
  requestType: PropTypes.string,
  draftIsUpserting: PropTypes.bool,
  upsertSuccessful: PropTypes.bool,
  draftIsSubmitting: PropTypes.bool,
  viewOnly: PropTypes.bool,
  wantsToVendToExistingProject: PropTypes.bool,
};

export default SummaryPage;
