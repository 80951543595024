import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import {createRoot} from 'react-dom/client';
import App from '../src';

function runOnLoaded() {
  const appContainer = document.getElementById('app');
  const root = createRoot(appContainer);
  root.render(<App />);
}

const loadedStates = ['complete', 'loaded', 'interactive'];

if (loadedStates.includes(document.readyState) && document.body) {
  runOnLoaded();
} else {
  window.addEventListener('DOMContentLoaded', runOnLoaded, false);
}
