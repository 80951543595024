import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';

import SubsectionTitle from './SubsectionTitle';
import UserDetail from './UserDetail';
import USER_TYPES from './userTypes';

const principalInvestigatorInfoFragment = graphql`
  fragment PrincipalInvestigator_AddendumPage_Fragment on AccessRequest {
    user {
      fullName
      email
    }
    accessRequestDraft {
      isPi
      principalInvestigator {
        name
        email
      }
    }
  }
`;

const PrincipalInvestigator = ({queryData}) => {
  const {
    user: {fullName, email},
    accessRequestDraft: {isPi, principalInvestigator},
  } = useFragment(principalInvestigatorInfoFragment, queryData);

  return (
    <div className="addendum-subsection">
      <SubsectionTitle
        title="Principal Investigator"
        icon={<i className="fas fa-info-circle" />}
        info="The Principal Investigator of a project cannot be edited, if there is a new PI who has taken over this project, they will need to submit a new Data Access Agreement."
      />
      <UserDetail
        name={isPi ? fullName : principalInvestigator.name}
        email={isPi ? email : principalInvestigator.email}
        icon={<i className="fas fa-user-graduate" />}
        self={isPi}
        type={USER_TYPES.PRINCIPAL_INVESTIGATOR}
        popoverText="You can't change the Principal Investigator"
      />
    </div>
  );
};

export default PrincipalInvestigator;

PrincipalInvestigator.propTypes = {
  queryData: PropTypes.object.isRequired,
};
