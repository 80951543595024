import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {graphql, useRefetchableFragment, fetchQuery, useRelayEnvironment} from 'react-relay';
import {useDispatch} from 'react-redux';

import {pushFlashAlert} from '../../../actions';
import {useIsMounted} from '../../hooks';
import FileSizeSummary from '../../atoms/FileSizeSummary';

import {prepareGraphQLFilters} from './util';
import databrowserQuery from './databrowserQuery';

const ICONS = {
  tumor: 'fa-stethoscope',
  germline: 'fa-stethoscope',
  'paired-tumor-normal': 'fa-clone',
  publications: 'fa-file-alt',
  cohorts: 'fa-chart-bar',
  samples: 'fa-users',
};

const TITLES = {
  tumor: 'Diagnosis',
  germline: 'Diagnosis',
  'paired-tumor-normal': 'Diagnosis',
  publications: 'Publication',
  cohorts: 'Dataset',
  samples: 'Subject',
};

function pluralize(str) {
  switch (str) {
    case 'Diagnosis':
      return 'Diagnoses';
    case 'Associated Diagnosis':
      return 'Associated Diagnoses';
    default:
      return `${str}s`;
  }
}

const summaryTableQuery = graphql`
  fragment SummaryTable_Summary_Fragment on Query
  @refetchable(queryName: "DatabrowserSummaryRefetchQuery") {
    databrowser {
      selectionSummary(filters: $summaryFilters, type: $type) {
        totalFileSize
        totalFileCount
        totalSampleCount
        extraData
      }
    }
  }
`;

const redux = {pushFlashAlert};

/**
 * Displays the four-square grid of aggregate information in the top left
 * section of the databrowser.
 *
 * @param {summary}: The aggregate counts of user filtered
 *   selections within the databrowser
 * @param {tab}: The current tab that is shown on the databrowser
 */

function SummaryTable({tab, filters, shouldRefetchSummary, databrowserFragmentData}) {
  const [summary, refetch] = useRefetchableFragment(summaryTableQuery, databrowserFragmentData);
  const graphqlEnvironment = useRelayEnvironment();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectionSummary = summary?.databrowser?.selectionSummary || {};

  useEffect(() => {
    if (!shouldRefetchSummary) return;

    function onRefetchCompletion(err) {
      if (!isMounted()) return;

      if (!err) return;

      dispatch(
        redux.pushFlashAlert({
          dismissable: true,
          variant: 'warning',
          content:
            'An error occurred while trying to customize your data selection. Please try again.',
        })
      );
    }

    const variables = prepareGraphQLFilters(filters, tab);
    fetchQuery(graphqlEnvironment, databrowserQuery, variables, {
      networkCacheConfig: {force: false},
    }).subscribe({
      complete: () => {
        // *After* the query has been fetched, we call refetch again to
        // re-render with the updated data. At this point the data for the
        // query should be cached, so we use the 'store-only' fetchPolicy to
        // avoid suspending.

        refetch(variables, {
          onComplete: onRefetchCompletion,
          fetchPolicy: 'store-only',
        });
      },
      error: onRefetchCompletion,
    });
  }, [shouldRefetchSummary, filters, isMounted, dispatch, graphqlEnvironment, refetch, tab]);

  return (
    <div className="summary-table">
      <div className="flex-container">
        <div className="flex-item-1">
          <div className="summary-icon">
            <span className="fa fa-vial fa-2x" />
          </div>
          <div className="summary-value">
            {intl.formatNumber(selectionSummary.totalSampleCount || 0)}
          </div>
          <div className="summary-type">
            {
              {
                one: 'Sample',
                other: 'Samples',
              }[intl.formatPlural(selectionSummary.totalSampleCount || 0)]
            }
          </div>
        </div>
        <div className="flex-item-2">
          <div className="summary-icon">
            <i className={`fa fa-2x ${ICONS[tab]}`} />
          </div>
          <div className="summary-value">{intl.formatNumber(selectionSummary.extraData || 0)}</div>
          <div className="summary-type">
            {
              {
                one: TITLES[tab],
                other: pluralize(TITLES[tab]),
              }[intl.formatPlural(selectionSummary.extraData || 0)]
            }
          </div>
        </div>
      </div>
      <div className="flex-container">
        <div className="flex-item-3">
          <div className="summary-icon">
            <span className="fa fa-folder-open fa-2x" />
          </div>
          <div className="summary-value">
            {intl.formatNumber(selectionSummary.totalFileCount || 0)}
          </div>
          <div className="summary-type">Total Files</div>
        </div>
        <div className="flex-item-4">
          <div className="summary-icon">
            <span className="fa fa-hdd fa-2x" />
          </div>
          <div className="summary-value">
            <FileSizeSummary size={Number(selectionSummary.totalFileSize || 0)} />
          </div>
          <div className="summary-type">Size</div>
        </div>
      </div>
    </div>
  );
}

SummaryTable.propTypes = {
  tab: PropTypes.oneOf([
    'tumor',
    'germline',
    'paired-tumor-normal',
    'publications',
    'cohorts',
    'samples',
  ]),
  filters: PropTypes.object,
  databrowserFragmentData: PropTypes.any,
  shouldRefetchSummary: PropTypes.bool,
};

SummaryTable.defaultProps = {
  tab: 'tumor',
};

export default SummaryTable;
