import React, {useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useLazyLoadQuery} from 'react-relay';
import {isEmpty} from 'ramda';

import databrowserFilterStorage from '../../../utils/databrowserFilterStorage';

import Databrowser from './Databrowser';
import DraftFlashAlert from './DraftFlashAlert';
import {queryStringFilters, tabFromLocation, getSelectAll} from './util';

import databrowserQuery from './databrowserQuery';

function DataBrowserPage() {
  // Use state hooks to prevent re-renders when tabs change. We are content to
  // let refetch queries handle subsequent updates to the databrowser.
  const location = useLocation();
  const [tab] = useState(tabFromLocation(location));
  const {setId, domain, subtab} = useParams();

  const getFilters = () => {
    // Prefer URL-supplied filters over user-saved filters. PeCan requests
    // have potentially very large amounts samples to select, so that list
    // is stored server side; we can't derive the PeCan sample set within
    // only the frontend, so `setId` is treated differently.
    let extentFilters = {};
    if (setId) {
      extentFilters = {setId};
    } else {
      extentFilters = queryStringFilters(tab, location.search, domain);

      if (isEmpty(extentFilters)) {
        extentFilters = databrowserFilterStorage.filters;
      }
    }

    return extentFilters;
  };

  const [filters] = useState(getFilters);
  const [selectAll] = useState(() => getSelectAll(location.search));

  // Either pass whatever `selectedTags` we intend to restore so we can restore
  // them, or pass `true`/`false` to signal if all/zero rows should be auto-
  // selected. These are evaluated in order of priority.
  const selectTagsAfterLoad =
    !!filters.setId ||
    selectAll ||
    (Array.isArray(filters.selectedTags) &&
      filters.selectedTags.length > 0 &&
      filters.selectedTags);

  // Allow `<Databrowser>` component to handle all network roundtrips
  // internally. This hook is provided so `useRefetchableFragments` do not emit
  // error warnings about `null` query refs.
  const databrowserQueryData = useLazyLoadQuery(
    databrowserQuery,
    {initialFetch: true},
    {fetchPolicy: 'store-only'} // Prevent suspenseful loading states from network requests.
  );
  const sidebarFiltersFragmentData = databrowserQueryData?.databrowser || null;

  return (
    <>
      <DraftFlashAlert />
      <Databrowser
        databrowserQueryData={databrowserQueryData}
        sidebarFiltersFragmentData={sidebarFiltersFragmentData}
        filters={filters}
        tab={tab}
        subtab={subtab}
        selectTagsAfterLoad={selectTagsAfterLoad}
      />
    </>
  );
}

DataBrowserPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default DataBrowserPage;
