import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Button, Dropdown} from 'react-bootstrap';
import {pathOr} from 'ramda';
import {graphql, useMutation, useFragment} from 'react-relay';

import {getUser} from '../../../../reducers/user/selectors';
import SpinnerWrapper from '../../../atoms/SpinnerWrapper';
import Link from '../../../atoms/Link';
import {
  dnanexusProjectMonitorPath,
  dnanexusProjectDataPath,
  dnanexusProjectWorkflowPath,
} from '../../../../api/routes';

import ToolError from './ToolError';

const toolFragment = graphql`
  fragment UserLoggedInButtons_Fragment on ToolInterface {
    id
    slug
    path
    embed
    workflows {
      dxid
      label
    }
    ... on Tool {
      toolProject {
        ... on ToolProject {
          dxid
        }
      }
    }
  }
`;

const startToolMutation = graphql`
  mutation UserLoggedInButtons_startTool_Mutation($input: StartToolMutationInput!) {
    startTool(input: $input) {
      toolProject {
        dxid
      }
      error {
        type
        message
      }
    }
  }
`;

export default function UserLoggedInButtons({queryData}) {
  const user = useSelector(getUser);
  const buttons = [];
  const [commit, isInFlight] = useMutation(startToolMutation);
  const [error, setError] = useState(null);
  const tool = useFragment(toolFragment, queryData);
  const projectDxid = pathOr(null, ['toolProject', 'dxid'], tool);
  const dxid = projectDxid && projectDxid.match(/^project-(\w+)$/)[1];

  const startTool = useCallback(() => {
    function updater(store) {
      // Once the mutation succeeds, we need to manually swap in the
      // tool->toolProjects field so anything reading or observing it may
      // automatically get updated.
      const toolProject = store.getRootField('startTool').getLinkedRecord('toolProject');
      if (!toolProject) return;
      const relayStoredTool = store.get(tool.id);
      relayStoredTool.setLinkedRecord(toolProject, 'toolProject');
    }

    function onCompleted(data) {
      if (data?.startTool?.error) {
        setError(data.startTool.error);
      } else {
        setError(null);
      }
    }

    const variables = {input: {slug: tool.slug}};
    return commit({variables, updater, onCompleted});
  }, [tool.slug, tool.id, commit]);

  if (tool.path) {
    buttons.push(
      <Button variant="primary" as={Link} href={tool.path} newTab key="run" id="go-to-tool-btn">
        Go to Tool
      </Button>
    );
  } else if (projectDxid) {
    if (tool.embed) {
      buttons.push(
        <Button
          variant="primary"
          as={Link}
          to={`/tools/embed/${tool.id}`}
          key="run"
          id="go-to-tool-btn"
        >
          Go to Tool
        </Button>
      );
    } else if ((tool.workflows || []).length === 1) {
      const url = dnanexusProjectWorkflowPath(dxid, tool.workflows[0].dxid);

      buttons.push(
        <Button variant="primary" as={Link} key="run" href={url} newTab id="launch-tool-btn">
          Launch This Tool
        </Button>
      );
    } else if ((tool.workflows || []).length > 1) {
      buttons.push(
        <Dropdown key="run" id="launch-tool-dropdown">
          <Dropdown.Toggle variant="primary">Launch Tool</Dropdown.Toggle>

          <Dropdown.Menu>
            {tool.workflows.map(workflow => {
              const url = dnanexusProjectWorkflowPath(dxid, workflow.dxid);

              return (
                <Dropdown.Item href={url} newTab as={Link} key={workflow.dxid}>
                  {workflow.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    buttons.push(
      <Dropdown key="view" id="view-results-btn">
        <Dropdown.Toggle variant="primary">View Results</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href={dnanexusProjectMonitorPath(dxid)} newTab as={Link}>
            View Running Jobs
          </Dropdown.Item>
          <Dropdown.Item href={dnanexusProjectDataPath(dxid)} newTab as={Link}>
            View Results Files
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    buttons.push(
      <Button
        variant="primary"
        key="start"
        onClick={startTool}
        id="start-btn"
        disabled={!user?.billingSetup}
      >
        <SpinnerWrapper show={isInFlight} fadeIn="none">
          Start
        </SpinnerWrapper>
      </Button>
    );
  }

  if (error) {
    return (
      <div>
        {buttons}
        <ToolError error={error} />
      </div>
    );
  }

  return buttons;
}

UserLoggedInButtons.propTypes = {
  queryData: PropTypes.object.isRequired,
};
