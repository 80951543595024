import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';

import parseFormFieldName from './formUtils';

function ITDForm({itDirector, wantsToDownload, setITDField, setWantsToDownload, isStjude}) {
  const onChange = event => {
    const {name, value} = event.target;
    const [field] = parseFormFieldName(name);
    setITDField(field, value);
  };

  return (
    <div>
      <div className="wizard-header center">Do you plan on downloading the data?</div>
      <div className="wizard-sub-header">
        You will be given access to a secure, cloud workspace, where you can access our data. We
        recommend that you use the data in this cloud workspace. Additional information will be
        required if you would like to download the raw genomics data to your local infrastructure.
      </div>
      <br />
      <Form.Group>
        <div className="options-row">
          <Button
            variant="primary"
            onClick={() => setWantsToDownload(true)}
            className="options-button"
          >
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={() => setWantsToDownload(false)}
            className="options-button"
          >
            No
          </Button>
        </div>
      </Form.Group>

      {isStjude && wantsToDownload !== undefined && (
        <div className="mt-5 d-flex flex-column align-items-center">
          <Form.Check>
            <Form.Check.Label className="checkbox-container">
              <Form.Check.Input type="checkbox" checked={wantsToDownload} disabled />
              I plan to download the data in addition to working with it in the cloud.
              <span className="checkmark" />
            </Form.Check.Label>
          </Form.Check>
        </div>
      )}

      {wantsToDownload && !isStjude && (
        <div className="d-flex flex-column align-items-center">
          <hr className="dropdown-line" />
          <div className="page-sub-header center">
            Enter information about your Information Security Officer
          </div>
          <div className="wizard-sub-header">
            The Information Security Officer is typically your Information Director, Chief Security
            Officer, or the person that is responsible to ensure your institution&apos;s information
            is secure.
          </div>
          <Form.Group controlId="itd_name">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="itDirector[name]"
              value={itDirector.name}
              required
              onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">Full name is required.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="itd_email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="itDirector[email]"
              value={itDirector.email}
              required
              onChange={onChange}
              data-testid="edaa-itd-email"
            />
            <Form.Control.Feedback type="invalid">
              Email must be a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      )}
    </div>
  );
}

ITDForm.propTypes = {
  itDirector: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  wantsToDownload: PropTypes.bool,
  isStjude: PropTypes.bool,
  setWantsToDownload: PropTypes.func.isRequired,
  setITDField: PropTypes.func.isRequired,
};

export default ITDForm;
