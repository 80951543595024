import {reject} from 'ramda';

import {createReducer} from '../../utils';
import C from '../../constants';

export const initialState = [];

/*
 * flashAlert: {
 *   variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
 *   content: string | React.Node (PropTypes.node)
 *   dismissible: bool (default true).
 *   key: number | string,
 * }
 */

export default createReducer(initialState, {
  [C.PUSH_FLASH_ALERT]: (state, action) => {
    const {flashAlert = {}} = action;

    // Ensure unique key exists on any flash alert that is pushed
    if (!flashAlert.key) {
      flashAlert.key = Date.now();
    }

    return [...state, flashAlert];
  },

  [C.CLEAR_FLASH_ALERT]: (state, action) => reject(fm => fm.key === action.key, state),

  [C.CLEAR_ALL_FLASH_ALERTS]: () => [],
});
