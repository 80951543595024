import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {Container, Row, Col} from 'react-bootstrap';

import ToolImage from '../../../molecules/ToolImage';

const toolFragment = graphql`
  fragment ToolHeader_Fragment on ToolInterface {
    name
    subtitle
    ...ToolImage_Fragment
  }
`;

function ToolHeader({queryData, children}) {
  const tool = useFragment(toolFragment, queryData);

  return (
    <Container className="tool-header">
      <Row as="section">
        <Col md={3} className="mb-3 mb-md-0 d-flex justify-content-center">
          <ToolImage queryData={tool} background="circle" />
        </Col>
        <Col md={9} className="my-auto text-md-left">
          <h1 className="tool-title">{tool.name}</h1>
          <h2 className="tool-subtitle">{tool.subtitle}</h2>
        </Col>
      </Row>
      <Row className="my-3 my-md-0">
        <Col md={{offset: 3, span: 9}}>{children}</Col>
      </Row>
    </Container>
  );
}

ToolHeader.propTypes = {
  queryData: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ToolHeader;
