import {configureStore as configureReduxStore} from '@reduxjs/toolkit';
import rootReducer from '../reducers';

export default function configureStore(initialState = {}) {
  return configureReduxStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });
}
