import {useRef} from 'react';
import {equals} from 'ramda';

/**
 * useStableIdentity will deeply check for changes in the given value and only
 * update when the dependencies actually change. This should only be used as an
 * effort to keep complex objects identity-stable (so `Object.is` returns
 * consistently).
 */
export default function useStableIdentity(value) {
  const ref = useRef();

  if (!equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
