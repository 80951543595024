import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {getCurrentUser} from '../../../../reducers/user/selectors';
import {dnanexusUserBillingPath, documentationPath} from '../../../../api/routes';

function ErrorHelp({documentationLink}) {
  return (
    <>
      For help, please visit <a href={documentationPath(documentationLink)}>our documentation</a> or
      contact us at <a href="https://stjude.cloud/contact">https://stjude.cloud/contact</a>.
    </>
  );
}

ErrorHelp.propTypes = {
  documentationLink: PropTypes.string.isRequired,
};

export default function ToolError({error}) {
  const user = useSelector(getCurrentUser);

  if (!error) return null;

  let errorText = null;
  if (error.type === 'DNAnexus::UnpermittedRegionError') {
    errorText = (
      <>
        DNAnexus Error: Your account is not configured for the correct billing region.{' '}
        <ErrorHelp documentationLink="/accounts-and-billing/#billing-setup" />
      </>
    );
  } else if (error.type === 'DNAnexus::SpendingLimitExceededError') {
    errorText = (
      <>
        DNAnexus Error: {error.message}. Please update your{' '}
        <a href={dnanexusUserBillingPath(user.dxid)}>DNAnexus billing information</a>.{' '}
        <ErrorHelp documentationLink="/accounts-and-billing/#billing-setup" />
      </>
    );
  } else {
    let errorType = 'error';
    if (error.type?.match(/^DNAnexus::/)) {
      errorType = 'DNAnexus error';
    }

    errorText = (
      <>
        An unexpected {errorType} occurred. Please try again. If the problem persists, please
        contact us at <a href="https://stjude.cloud/contact">https://stjude.cloud/contact</a>.
      </>
    );
  }

  return <div className="text-danger text-center p-2">{errorText}</div>;
}

ToolError.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
};
