import React from 'react';
import {useSelector} from 'react-redux';
import {isNil} from 'ramda';
import Spinner from 'react-spinkit';
import {Navbar} from '@stjudecloud/ui-react';

import {getCurrentUser} from '../../../reducers/user/selectors';

import AdminLoggedInDropdown from './AdminLoggedInDropdown';
import UserLoggedInDropdown from './UserLoggedInDropdown';

export function Header() {
  const user = useSelector(getCurrentUser);
  const isLoading = user.isLoading || isNil(user.isLoading);

  const spinner = isLoading ? <Spinner name="three-bounce" className="login-spinner" /> : null;

  return (
    <Navbar
      portalConfig={{title: 'Genomics Platform', link: '/'}}
      loginConfig={{show: !isLoading && !user.isLoggedIn, loginLink: '/login'}}
      userDropdownConfig={{
        show: !isLoading && user.isLoggedIn,
        additionalItems: <UserLoggedInDropdown user={user} />,
      }}
    >
      {spinner}

      <AdminLoggedInDropdown show={!isLoading && user.isAdmin} />
    </Navbar>
  );
}

export default Header;
