import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

import SubmitError from './SubmitError';

const NavigationButtons = React.forwardRef(
  (
    {
      pathname,
      isPi,
      hasAdditionalApplicants,
      wantsToDownload,
      isStjude,
      onNext,
      onPrev,
      submitError,
      resetSubmitError,
      wantsToVendToExistingProject,
      hasAccessRequests,
      needsToRequestAccess,
      canChooseProjectType,
    },
    ref
  ) => {
    let prevText = 'Previous Question';
    let prevPage = '';
    let nextText = 'Continue';
    let nextPage = '';

    useEffect(resetSubmitError, [pathname, resetSubmitError]);

    switch (pathname) {
      case '/requests/new_or_existing':
        prevPage = '/data';
        prevText = 'Return to data browser';
        nextPage = '/requests/project';

        if (!wantsToVendToExistingProject) {
          nextText = '';
        }

        if (canChooseProjectType) {
          nextPage = '/requests/project_type';
        }

        if (!needsToRequestAccess) {
          nextPage = '/requests/request_details';
        }

        break;
      case '/requests/project_type':
        prevPage = '/requests/new_or_existing';
        nextText = '';

        break;
      case '/requests/request_details':
        nextText = 'Submit';

        if (canChooseProjectType) {
          prevPage = '/requests/project_type';
        } else if (hasAccessRequests) {
          prevPage = '/requests/new_or_existing';
        } else {
          prevPage = '/data';
          prevText = 'Return to data browser';
        }

        break;
      case '/requests/project':
        nextPage = '/requests/access';

        if (canChooseProjectType) {
          prevPage = '/requests/project_type';
        } else if (hasAccessRequests) {
          prevPage = '/requests/new_or_existing';
        } else {
          prevPage = '/data';
          prevText = 'Return to data browser';
        }

        break;
      case '/requests/access':
        prevText = 'Previous Step';
        prevPage = '/requests/project';
        nextPage = '/requests/user';

        break;
      case '/requests/user':
        prevPage = '/requests/access';
        nextPage = '/requests/pi';

        break;
      case '/requests/pi':
        prevPage = '/requests/user';
        nextPage = '/requests/signing_official';

        // If isPi === true, the user is moved to the next page and nothing
        //   needs to be shown because the user is on a different page. No
        //   button needs to be shown.
        // If isPi === false, the user stays on the page and must complete the
        //   form. The button needs to be shown.
        // If isPi === undefined, the user has not made a decision yet. No
        //   button needs to be shown.
        if (isPi !== false) {
          nextText = '';
        }

        break;
      case '/requests/signing_official':
        prevPage = '/requests/pi';
        nextPage = '/requests/data_security';

        break;
      case '/requests/data_security':
        prevPage = '/requests/signing_official';
        nextPage = '/requests/additional_applicants';

        // If the user does not want to download data or is a St. Jude
        // employee, they do not need to fill out the form re an Information
        // Security Officer; thus, no continue button is necessary
        if (!wantsToDownload || (wantsToDownload && isStjude)) {
          nextText = '';
        }

        break;
      case '/requests/additional_applicants':
        prevPage = '/requests/data_security';
        nextPage = '/requests/contemplated_use';
        if (!hasAdditionalApplicants) {
          nextText = '';
        }

        break;
      case '/requests/contemplated_use':
        prevPage = '/requests/additional_applicants';
        nextPage = '/requests/application_type';

        break;
      case '/requests/application_type':
        prevPage = '/requests/contemplated_use';
        nextText = '';

        break;
      case '/requests/manual':
        prevPage = '/requests/summary';
        nextText = 'Submit';

        break;
      case '/requests/summary':
        prevPage = '/requests/application_type';
        nextText = '';

        break;
      default:
        prevText = '';
        nextText = '';

        break;
    }

    return (
      <>
        <div className="nav-buttons" ref={ref}>
          {prevText !== '' && (
            <Button
              variant="none"
              className="text-primary"
              onClick={() => onPrev(prevPage)}
              data-testid="btn-edaa-prev"
            >
              <i className="fas fa-chevron-left" />
              {prevText}
            </Button>
          )}
          {nextText !== '' && (
            <Button variant="primary" onClick={() => onNext(nextPage)} data-testid="btn-edaa-next">
              {nextText}
              <i className="fas fa-chevron-right" />
            </Button>
          )}
        </div>
        <SubmitError message={submitError} />
      </>
    );
  }
);

NavigationButtons.propTypes = {
  pathname: PropTypes.string.isRequired,
  isPi: PropTypes.bool,
  hasAdditionalApplicants: PropTypes.bool,
  isStjude: PropTypes.bool,
  wantsToDownload: PropTypes.bool,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resetSubmitError: PropTypes.func.isRequired,
  wantsToVendToExistingProject: PropTypes.bool,
  hasAccessRequests: PropTypes.bool,
  needsToRequestAccess: PropTypes.bool,
  canChooseProjectType: PropTypes.bool,
};

export default NavigationButtons;
