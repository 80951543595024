/**
 * @generated SignedSource<<37e1ba7bf908fb8aafae3affd165000d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToolHeader_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToolImage_Fragment"
    }
  ],
  "type": "ToolInterface",
  "abstractKey": "__isToolInterface"
};

node.hash = "df6946eb42daf9d56f02de36674abb8d";

module.exports = node;
