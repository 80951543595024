import React from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import DropzoneWrapper from './DropzoneWrapper';
import Errors from './Errors';

function DropzoneForm({
  errors,
  onShow,
  onHide,
  onUpload,
  onDrop,
  show,
  fileInfo,
  inline,
  uploadText,
  cancelText,
}) {
  if (!show) {
    return (
      <Button variant="primary" className="dropzone-ctl-btn float-end" onClick={onShow}>
        <span className="fa fa-upload" /> UPLOAD FORM
      </Button>
    );
  }

  const dropzone = (
    <DropzoneWrapper
      fileInfo={fileInfo}
      onDrop={onDrop}
      onHide={onHide}
      multiple={false}
      accept={{'application/pdf': ['.pdf']}}
    />
  );
  const errorComponent = <Errors errors={errors} />;
  const uploadBtn = (
    <Button variant="primary" className="dropzone-ctl-btn" onClick={onUpload}>
      <span className="fa fa-paper-plane" /> {uploadText || 'UPLOAD'}
    </Button>
  );
  const cancelBtn = (
    <Button variant="outline-primary" className="dropzone-ctl-btn" onClick={onHide}>
      {cancelText || 'CANCEL'}
    </Button>
  );

  let content = null;
  if (inline) {
    content = (
      <>
        <div className="dropzone-form-container dropzone-form-container--inline">
          {dropzone}
          <div className="dropzone-buttons-container--inline">
            {uploadBtn}
            {cancelBtn}
          </div>
        </div>
        {errorComponent}
      </>
    );
  } else {
    content = (
      <>
        <div className="dropzone-form-container">{dropzone}</div>
        <div className="my-3">
          <Row className="g-0">
            <Col xs="auto">{errorComponent}</Col>
            <Col xs="auto" className="ms-auto d-flex justify-content-between submit-cancel-buttons">
              <div className="me-2 cancel-button">{cancelBtn}</div>
              <div className="ms-2 upload-button">{uploadBtn}</div>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return <div className="dropzone-form">{content}</div>;
}

DropzoneForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  fileInfo: PropTypes.any,
  inline: PropTypes.bool,
  uploadText: PropTypes.string,
  cancelText: PropTypes.string,
};

DropzoneForm.defaultProps = {
  fileInfo: null,
};

export default DropzoneForm;
