import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export const SummaryHeading = ({heading, editPath}) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex">
      <h3 className="summary-form-heading">
        <span>
          <Button
            variant="outline-primary"
            size="xs"
            className="ms-2 summary-edit-btn"
            onClick={() => navigate(`/requests/${editPath}`)}
          >
            Edit
          </Button>
          {heading}
        </span>
      </h3>
    </div>
  );
};

SummaryHeading.propTypes = {
  heading: PropTypes.string,
  editPath: PropTypes.string.isRequired,
};

export default SummaryHeading;
