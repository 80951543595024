import React from 'react';

const Heading = () => (
  <div>
    <h2>You are requesting a data access extension</h2>
    <div className="d-flex flex-column mt-4">
      <div className="d-flex flex-row">
        <span className="heading-icon">
          <i className="fas fa-check-circle" />
        </span>
        <span className="ms-3">
          Please review the information below carefully and make sure everything is up to date.
        </span>
      </div>

      <div className="d-flex flex-row mt-4">
        <span className="heading-icon">
          <i className="fas fa-edit" />
        </span>
        <span className="ms-3">
          After reviewing, download the Extension Addendum PDF, collect all required signatures from
          your institution, and upload the PDF to Genomics Platform on this page.
        </span>
      </div>
    </div>
  </div>
);

export default Heading;
