import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

export function DownloadLink(props) {
  const {children} = props;
  const isChrome = navigator.userAgent.indexOf('Chrome') > 1;

  return (
    <Link newTab={isChrome} {...props}>
      {children}
    </Link>
  );
}

DownloadLink.propTypes = {
  children: PropTypes.node,
};

export default DownloadLink;
