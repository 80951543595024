import React from 'react';
import PropTypes from 'prop-types';
import {Image, Form} from 'react-bootstrap';
import PeCanIcon from '@stjudecloud/assets/images/icon-pecan.png';

function DatabrowserSubheader({show, onTogglePecan, pecanToggleActive}) {
  if (!show) return null;

  return (
    <div className="saved-file-set-message d-flex">
      <Image src={PeCanIcon} className="pecan-icon" />
      <div>
        You selected a collection of samples from PeCan. Changing tabs or filters will change your
        selection.
        <Form.Check
          type="switch"
          id="pecan-context-toggle"
          label="Show PeCan collection"
          onChange={onTogglePecan}
          defaultChecked={pecanToggleActive}
        />
      </div>
    </div>
  );
}

DatabrowserSubheader.propTypes = {
  show: PropTypes.bool,
  onTogglePecan: PropTypes.func,
  pecanToggleActive: PropTypes.bool,
};

export default DatabrowserSubheader;
