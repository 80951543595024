import {filesize} from 'filesize';

import BadgeRenderer from './BadgeRenderer';
import PublicationTitleRenderer from './PublicationTitleRenderer';

function numberFormatter(params) {
  return params.value ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
}

function fileSizeFormatter(params) {
  return filesize(params.value, {standard: 'jedec'});
}

export default function columnsDefs(tab) {
  const columns = [
    {
      headerName: '',
      width: 50,
      minWidth: 45,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      suppressSizeToFit: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Sequencing Types',
      field: 'seqTypes',
      minWidth: 180,
      cellRenderer: BadgeRenderer,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'File Types',
      field: 'fileTypes',
      minWidth: 325,
      cellRenderer: BadgeRenderer,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Total Files',
      field: 'totalFileCount',
      valueFormatter: numberFormatter,
      minWidth: 100,
      width: 120,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Total File Size',
      field: 'totalFileSize',
      valueFormatter: fileSizeFormatter,
      flex: 1,
      resizable: true,
      comparator: (valueA, valueB) => valueA - valueB,
      sortable: true,
    },
  ];

  const diseasesNameCol = {
    headerName: 'Primary Diagnosis',
    field: 'name',
    width: 350,
    minWidth: 200,
    resizable: true,
    sortable: true,
  };

  const associatedDiagnosisNameCol = {
    headerName: 'Associated Diagnosis',
    field: 'name',
    width: 350,
    minWidth: 200,
    resizable: true,
    sortable: true,
  };

  const publicationsNameCol = {
    headerName: 'Publication',
    field: 'name',
    cellRenderer: PublicationTitleRenderer,
    minWidth: 150,
    width: 350,
    resizable: true,
    sortable: true,
  };

  const cohortsNameCol = {
    headerName: 'Dataset',
    field: 'name',
    minWidth: 150,
    width: 350,
    resizable: true,
    sortable: true,
  };

  const cohortsAccessionCol = {
    headerName: 'Accession Number',
    field: 'accessionNumber',
    minWidth: 150,
    width: 200,
    resizable: true,
    sortable: true,
    sort: 'asc',
  };

  const samplesNameCol = {
    headerName: 'Sample Name',
    field: 'name',
    minWidth: 120,
    width: 120,
    resizable: true,
    sortable: true,
  };

  const samplesCol = {
    headerName: 'Samples',
    field: 'totalSampleCount',
    valueFormatter: numberFormatter,
    minWidth: 90,
    width: 120,
    resizable: true,
    sortable: true,
  };

  switch (tab) {
    case 'tumor':
    case 'paired-tumor-normal':
      columns.splice(1, 0, diseasesNameCol);
      columns.splice(4, 0, samplesCol);
      break;
    case 'germline':
      columns.splice(1, 0, associatedDiagnosisNameCol);
      columns.splice(4, 0, samplesCol);
      break;
    case 'publications':
      columns.splice(1, 0, publicationsNameCol);
      columns.splice(4, 0, samplesCol);
      break;
    case 'cohorts':
      columns.splice(1, 0, cohortsNameCol);
      columns.splice(2, 0, cohortsAccessionCol);
      columns.splice(5, 0, samplesCol);
      break;
    case 'samples':
      columns.splice(1, 0, samplesNameCol);
      break;
  }

  return columns;
}
