import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {Image} from 'react-bootstrap';
import classnames from 'classnames';

const toolFragment = graphql`
  fragment ToolImage_Fragment on ToolInterface {
    name
    imageUrl
  }
`;

function ToolImage({queryData, className, background, as}) {
  const tool = useFragment(toolFragment, queryData);
  const backgroundClass = background === 'bullet' ? 'tool-image--bullet' : 'rounded-circle';

  const img = React.createElement(as || Image, {src: tool.imageUrl, alt: tool.name});

  return <div className={classnames(['tool-image'], className, backgroundClass)}>{img}</div>;
}

ToolImage.propTypes = {
  className: PropTypes.string,
  background: PropTypes.oneOf(['bullet', 'circle']).isRequired,
  queryData: PropTypes.object.isRequired,
  as: PropTypes.elementType,
};

export default ToolImage;
