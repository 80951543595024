import React from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from 'classnames';
import {Button} from 'react-bootstrap';

import {documentationPath} from '../../../api/routes';

export default function DocumentationButton({path, className, variant}) {
  const buttonClassNames = [];
  if (className) buttonClassNames.push(className);

  return (
    <Button
      variant={variant}
      size="sm"
      className={mergeClassNames(buttonClassNames)}
      href={documentationPath(path)}
      target="_blank"
      rel="noopener noreferrer"
    >
      Documentation
    </Button>
  );
}

DocumentationButton.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

DocumentationButton.defaultProps = {
  variant: 'primary',
};
