import React from 'react';
import PropTypes from 'prop-types';
import {pathOr, sortBy} from 'ramda';

import Badge from './Badge';

const seqTypeOrder = (a, b) => {
  const order = ['WGS', 'WES', 'RNA-SEQ', 'Multiple'];
  return order.indexOf(a) - order.indexOf(b);
};

const fileTypeOrder = (a, b) => {
  const order = ['BAM', 'gVCF', 'Somatic_VCF', 'CNV', 'FEATURE_COUNTS'];
  return order.indexOf(a) - order.indexOf(b);
};

function BadgeRenderer(props) {
  const {
    rowIndex,
    colDef: {field},
    data,
  } = props;

  let allKey = 'allFileTypes';
  let sortFn = fileTypeOrder;

  if (field === 'seqTypes') {
    allKey = 'allSeqTypes';
    sortFn = seqTypeOrder;
  }

  const allBadges = pathOr([], [allKey], data);
  const actualBadges = pathOr([], [field], data);

  // Use ramda's sortBy since it returns a copy of the passed array. We can't
  // sort the actual array since its an immutable prop.
  /* eslint-disable react/no-array-index-key */
  return sortBy(sortFn)(allBadges).map((badge, badgeIndex) => (
    <Badge
      key={`${badge}-${badgeIndex}-${rowIndex}`}
      type={badge}
      active={actualBadges.length === 0 || actualBadges.indexOf(badge) > -1}
    />
  ));
}

BadgeRenderer.propTypes = {
  rowIndex: PropTypes.number,
  data: PropTypes.object,
  colDef: PropTypes.shape({
    field: PropTypes.oneOf(['seqTypes', 'fileTypes']),
  }),
};

export default BadgeRenderer;
