import React, {useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import DatabrowserFiltersContext from '../DatabrowserFiltersContext';

import {useStableIdentity} from '../../hooks';
import databrowserFilterStorage from '../../../utils/databrowserFilterStorage';

function DatabrowserFiltersContextProvider({children}) {
  const [filters, setFiltersState] = useState({});
  const [requestType, setRequestTypeState] = useState(null);

  const setFilters = useCallback(
    newFilters => {
      setFiltersState(newFilters);
      databrowserFilterStorage.filters = newFilters;
    },
    [setFiltersState]
  );

  const setRequestType = useCallback(
    newRequestType => {
      setRequestTypeState(newRequestType);
      databrowserFilterStorage.requestType = newRequestType;
    },
    [setRequestTypeState]
  );

  const clearFilters = useCallback(() => {
    setFiltersState({});
    databrowserFilterStorage.reset();
  }, [setFiltersState]);

  const stableFilters = useStableIdentity(filters);

  const hasFilters = useCallback(() => databrowserFilterStorage.hasFilters, []);

  const contextObj = useMemo(
    () => ({
      filters,
      setFilters,
      requestType,
      setRequestType,
      clearFilters,
      hasFilters,
    }),
    [stableFilters, setFilters, requestType, setRequestType, clearFilters, hasFilters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <DatabrowserFiltersContext.Provider value={contextObj}>
      {children}
    </DatabrowserFiltersContext.Provider>
  );
}

DatabrowserFiltersContextProvider.propTypes = {
  children: PropTypes.node,
};

export default DatabrowserFiltersContextProvider;
