import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import parseFormFieldName from './formUtils';

function IAForm({institutionalAuthority, setIAField}) {
  const onChange = event => {
    const {name, value} = event.target;
    const [field] = parseFormFieldName(name);
    setIAField(field, value);
  };

  return (
    <div>
      <div className="wizard-header center">
        Provide information about your institutional authority.
      </div>
      <div className="wizard-sub-header">
        The institutional authority cannot be the same as the Principal Investigator and must have
        the authority to bind the institution to the terms of the contract. This is typically the
        individual that signs contracts for your institution (e.g., grant applications). For St.
        Jude employees, the Institutional Authority is your department chair. If the department
        chair is involved in the project, the Institutional Authority is VP who the chair reports
        to.
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <Form.Group controlId="ia_name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            name="institutionalAuthority[name]"
            value={institutionalAuthority.name}
            required
            onChange={onChange}
          />
          <Form.Control.Feedback type="invalid">Full name is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="ia_email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="institutionalAuthority[email]"
            required
            value={institutionalAuthority.email}
            onChange={onChange}
            data-testid="edaa-institutionalAuthority-email"
          />
          <Form.Control.Feedback type="invalid">
            Email must be a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

IAForm.propTypes = {
  institutionalAuthority: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  setIAField: PropTypes.func.isRequired,
};

export default IAForm;
