import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'relay-runtime';
import {useFragment} from 'react-relay';

import SubsectionTitle from './SubsectionTitle';

const accessUnitsInfoFragment = graphql`
  fragment DataAccessUnits_AddendumPage_Fragment on AccessRequest {
    accessRequestsAccessUnits {
      accessUnit {
        dxid
        name
        description
      }
      decision
    }
  }
`;

const DataAccessUnits = ({queryData}) => {
  const {accessRequestsAccessUnits} = useFragment(accessUnitsInfoFragment, queryData);
  const approvedArau = accessRequestsAccessUnits.filter(
    arau =>
      arau.decision === 'APPROVED' && arau.accessUnit && arau.accessUnit.name !== 'UnrestrictedData'
  );

  return (
    <div className="addendum-subsection">
      <SubsectionTitle title="Data Access Units" />
      {approvedArau.map((arau, index) => (
        <div key={arau?.accessUnit?.dxid || index} className="my-4">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: arau?.accessUnit?.description || ''}} />
        </div>
      ))}
    </div>
  );
};

export default DataAccessUnits;

DataAccessUnits.propTypes = {
  queryData: PropTypes.object.isRequired,
};
