import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Spinner from 'react-spinkit';

import {useTimeout} from '../../hooks';

const ELECTRONIC_BOOKMARKS = [
  // 'new_or_existing',
  // 'project_type',
  'project',
  'access',
  'user',
  'pi',
  'signing_official',
  'data_security',
  'additional_applicants',
  'contemplated_use',
  'application_type',
  'summary',
];

const MANUAL_BOOKMARKS = [
  // 'new_or_existing',
  // 'project_type',
  'project',
  'access',
  'user',
  'pi',
  'signing_official',
  'data_security',
  'additional_applicants',
  'contemplated_use',
  'application_type',
  'summary',
  'manual',
];

const HAS_ACCESS_BOOKMARKS = [
  // 'new_or_existing',
  // 'project_type',
  'request_details',
];

const BOOKMARK_TITLES = {
  new_or_existing: 'Choose Your Data Destination',
  project_type: 'Choose Your Project Type',
  project: 'Name Your Project',
  access: 'Data Access Requirement',
  user: 'Personal Information',
  application_type: 'Application Type',
  manual: 'Download/Upload Form',
  pi: 'Principal Investigator',
  signing_official: 'Institutional Authority',
  data_security: 'Data Security',
  additional_applicants: 'Additional Applicants',
  contemplated_use: 'Contemplated Use',
  summary: 'Summary',
  request_details: 'Summary',
};

function SideNav({
  requestType,
  saveDraft,
  draftIsUpserting,
  upsertSuccessful,
  hasAccessRequests,
  needsToRequestAccess,
  canChooseProjectType,
}) {
  const [savedFromSideNav, setSavedFromSideNav] = useState(false);
  const [_, setTimer] = useTimeout(); // eslint-disable-line no-unused-vars

  let bookmarksType = HAS_ACCESS_BOOKMARKS;
  if (needsToRequestAccess) {
    if (requestType === 'electronic') {
      bookmarksType = ELECTRONIC_BOOKMARKS;
    } else {
      bookmarksType = MANUAL_BOOKMARKS;
    }
  }

  const bookmarks = [...bookmarksType];
  if (canChooseProjectType) {
    bookmarks.unshift('project_type');
  }

  if (hasAccessRequests) {
    bookmarks.unshift('new_or_existing');
  }

  const onClick = () => {
    setSavedFromSideNav(true);
    saveDraft();
    setTimer(() => setSavedFromSideNav(false), 3000);
  };

  return (
    <div className="access-request-submission-wizard-side-nav">
      {bookmarks.map(bookmark => (
        <NavLink
          className={({isActive}) => `bookmark${isActive ? ' selected' : ''}`}
          key={bookmark}
          to={`/requests/${bookmark}`}
          onClick={saveDraft}
          data-testid={`nav-edaa-${bookmark}`}
        >
          {BOOKMARK_TITLES[bookmark]}
        </NavLink>
      ))}
      <div className="save-draft-btn">
        <Button onClick={onClick} variant="primary" size="sm" data-testid="nav-edaa-save">
          Save as Draft
        </Button>
        {savedFromSideNav && draftIsUpserting && (
          <Spinner fadeIn="none" name="circle" className="ms-3" />
        )}
        {savedFromSideNav && !draftIsUpserting && upsertSuccessful && (
          <span className="fa fa-check text-success ms-3 delayed-hide" />
        )}
        {savedFromSideNav && !draftIsUpserting && !upsertSuccessful && (
          <span className="fa fa-times text-danger ms-3 delayed-hide" />
        )}
      </div>
    </div>
  );
}

SideNav.propTypes = {
  requestType: PropTypes.string,
  saveDraft: PropTypes.func.isRequired,
  draftIsUpserting: PropTypes.bool,
  upsertSuccessful: PropTypes.bool,
  hasAccessRequests: PropTypes.bool,
  needsToRequestAccess: PropTypes.bool,
  canChooseProjectType: PropTypes.bool,
};

export default SideNav;
