import React from 'react';
import PropTypes from 'prop-types';
import {Helmet as ReactHelmet} from 'react-helmet';

const defaultHtmlTitle = 'Genomics Platform | St. Jude Cloud';
const defaultMetaKeywords = [
  'St. Jude Cloud',
  'genomics',
  'genome',
  'whole genome sequencing',
  'WGS',
  'whole exome sequencing',
  'WES',
  'RNA-Seq',
  'GRCh38 pediatric cancer sequencing',
  'genomic sequencing',
];
const defaultMetaDescription = 'Explore and work with genomic data and tools on St. Jude Cloud.';

function Helmet({htmlTitle, metaDescription, metaKeywords}) {
  const finalMetaKeywords = [].concat(defaultMetaKeywords, metaKeywords);
  const finalMetaDescription = metaDescription || defaultMetaDescription;
  const finalHtmlTitle = htmlTitle ? `${htmlTitle} | ${defaultHtmlTitle}` : defaultHtmlTitle;

  const helmetProps = {
    link: [
      {rel: 'shortcut icon', href: '/favicon.ico'},
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700',
      },
      {
        rel: 'stylesheet',
        href: 'https://use.fontawesome.com/releases/v5.10.2/css/all.css',
        integrity: 'sha384-rtJEYb85SiYWgfpCr0jn174XgJTn4rptSOQsMroFBPQSGLdOC5IbubP6lJ35qoM9',
        crossOrigin: 'anonymous',
      },
    ],
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no'},
      {name: 'keywords', content: finalMetaKeywords},
      {name: 'description', content: finalMetaDescription},
      {name: 'title', content: finalHtmlTitle},
    ],
    title: finalHtmlTitle,
  };

  return <ReactHelmet {...helmetProps} />;
}

Helmet.propTypes = {
  htmlTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
};

export default Helmet;
