import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button} from 'react-bootstrap';
import {Navigate} from 'react-router-dom';

import CustomSelect from '../../../atoms/CustomSelect';

function NewOrExistingProjectForm({
  accessRequests,
  validated,
  projectDxid,
  setVendToProject,
  wantsToVendToExistingProject,
  setWantsToVendToExistingProject,
  setDoesNotWantToVendToExistingProject,
  hasAccessRequests,
}) {
  if (!hasAccessRequests) {
    return <Navigate to="/requests/project_type" />;
  }

  const onSelectChange = ({value}) => setVendToProject(value);

  return (
    <div>
      <div className="wizard-header">
        Vend the selected data to one of your existing projects or create a new project?
      </div>
      <div className="wizard-sub-header">
        You can choose to vend your data to an existing or a new project. Vending data to an
        existing project will create a new folder within the project. It will not have any effect on
        previously vended data.
      </div>
      <br />
      <Form.Group>
        <div className="options-row">
          <Button
            variant="primary"
            onClick={setWantsToVendToExistingProject}
            className="options-button"
          >
            Existing Project
          </Button>
          <Button
            variant="primary"
            onClick={setDoesNotWantToVendToExistingProject}
            className="options-button"
          >
            New Project
          </Button>
        </div>
      </Form.Group>
      {wantsToVendToExistingProject && (
        <div className="d-flex flex-column align-items-center">
          <hr className="dropdown-line" />
          <div className="page-sub-header center">Select from your existing projects.</div>
          <div className="wizard-sub-header">
            We will vend your data to the secure cloud project of your choice.
          </div>
          <Form.Group className="d-flex flex-column" controlId="existing_project_id">
            <Form.Label>Your Available Projects:</Form.Label>
            <CustomSelect
              name="request[projectDxid]"
              options={accessRequests.map(request => ({
                value: request,
                label: request.projectName,
              }))}
              currentOption={projectDxid}
              onChange={onSelectChange}
              className="form-input-select"
              validated={validated}
            />
            {validated && !projectDxid && (
              <div className="d-block invalid-feedback">A destination project is required.</div>
            )}
          </Form.Group>
        </div>
      )}
      <br />
    </div>
  );
}

NewOrExistingProjectForm.propTypes = {
  accessRequests: PropTypes.arrayOf(
    PropTypes.shape({
      projectName: PropTypes.string,
      projectDxid: PropTypes.string,
    })
  ),
  projectDxid: PropTypes.string,
  setVendToProject: PropTypes.func.isRequired,
  validated: PropTypes.bool,
  wantsToVendToExistingProject: PropTypes.bool,
  setWantsToVendToExistingProject: PropTypes.func.isRequired,
  setDoesNotWantToVendToExistingProject: PropTypes.func.isRequired,
  hasAccessRequests: PropTypes.bool,
};

export default NewOrExistingProjectForm;
