import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

function SpinnerWrapper({show, children, styled, inline, style, fadeIn}) {
  let className = 'spinner-wrapper';

  if (styled) {
    className += ' spinner--styled';
  } else if (inline) {
    className += ' spinner--inline';
  }

  let contents = null;
  if (show) {
    contents = <Spinner name="circle" className={className} style={style} fadeIn={fadeIn} />;
  } else if (children) {
    contents = children;
  }

  return contents;
}

SpinnerWrapper.propTypes = {
  styled: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  inline: PropTypes.bool,
  fadeIn: PropTypes.oneOf(['full', 'half', 'quarter', 'none']),
};

export default memo(SpinnerWrapper);
