import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

const DeleteUserConfirmationModal = ({
  confirmDelete: {show, type, name, projectName, onDelete},
  onHide,
}) => {
  const deleteAndClose = () => {
    onDelete();
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-6 text-uppercase">
          <span className="text-danger mr-2">
            <i className="fas fa-trash-alt" />
          </span>{' '}
          Removing {type || 'User'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to remove <strong>{name}</strong> from <strong>{projectName}</strong>{' '}
        project?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteAndClose}>
          Yes, Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserConfirmationModal;

DeleteUserConfirmationModal.propTypes = {
  confirmDelete: PropTypes.shape({
    show: PropTypes.bool,
    name: PropTypes.string,
    projectName: PropTypes.string,
    onDelete: PropTypes.func,
    type: PropTypes.string,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
};
