import React from 'react';
import PropTypes from 'prop-types';

export default function SubmitError({message}) {
  if (!message) return null;

  return <div className="text-danger text-center">{message}</div>;
}

SubmitError.propTypes = {
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
