import React from 'react';

const context = React.createContext({
  filters: {},
  setFilters: () => {},
  requestType: null,
  clearFilters: () => {},
  setRequestType: () => {},
  hasFilters: () => false,
});
context.displayName = 'DatabrowserFiltersContext';

export default context;
