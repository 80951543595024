/**
 * @generated SignedSource<<856d4cfc4c5e9d3b3add618f687f861a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumPage_index_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccessRequest",
        "kind": "LinkedField",
        "name": "accessRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectInfo_AddendumPage_Fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DataAccessUnits_AddendumPage_Fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PrincipalInvestigator_AddendumPage_Fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdditionalApplicantsInfo_AddendumPage_Fragment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequestDraft",
            "kind": "LinkedField",
            "name": "accessRequestDraft",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InstitutionalAuthority_AddendumPage_Fragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DownloadData_AddendumPage_Fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumPage_index_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccessRequest",
        "kind": "LinkedField",
        "name": "accessRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "folderName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewOnly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequestsAccessUnit",
            "kind": "LinkedField",
            "name": "accessRequestsAccessUnits",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessUnit",
                "kind": "LinkedField",
                "name": "accessUnit",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dxid",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "decision",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequestDraft",
            "kind": "LinkedField",
            "name": "accessRequestDraft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPi",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdditionalApplicant",
                "kind": "LinkedField",
                "name": "principalInvestigator",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdditionalApplicant",
                "kind": "LinkedField",
                "name": "additionalApplicants",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdditionalApplicant",
                "kind": "LinkedField",
                "name": "institutionalAuthority",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wantsToDownload",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdditionalApplicant",
                "kind": "LinkedField",
                "name": "itDirector",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc29450970b08d2e2bc3e8119e8d4c97",
    "id": null,
    "metadata": {},
    "name": "AddendumPage_index_Query",
    "operationKind": "query",
    "text": "query AddendumPage_index_Query(\n  $id: ID!\n) {\n  accessRequest(id: $id) {\n    iid\n    projectName\n    approvalStatus\n    ...ProjectInfo_AddendumPage_Fragment\n    ...DataAccessUnits_AddendumPage_Fragment\n    ...PrincipalInvestigator_AddendumPage_Fragment\n    ...AdditionalApplicantsInfo_AddendumPage_Fragment\n    accessRequestDraft {\n      id\n      ...InstitutionalAuthority_AddendumPage_Fragment\n      ...DownloadData_AddendumPage_Fragment\n    }\n    id\n  }\n}\n\nfragment AdditionalApplicantsInfo_AddendumPage_Fragment on AccessRequest {\n  user {\n    fullName\n    email\n    id\n  }\n  accessRequestDraft {\n    isPi\n    additionalApplicants {\n      name\n      email\n    }\n    id\n  }\n}\n\nfragment DataAccessUnits_AddendumPage_Fragment on AccessRequest {\n  accessRequestsAccessUnits {\n    accessUnit {\n      dxid\n      name\n      description\n    }\n    decision\n    id\n  }\n}\n\nfragment DownloadData_AddendumPage_Fragment on AccessRequestDraft {\n  wantsToDownload\n  itDirector {\n    name\n    email\n  }\n}\n\nfragment InstitutionalAuthority_AddendumPage_Fragment on AccessRequestDraft {\n  institutionalAuthority {\n    name\n    email\n  }\n}\n\nfragment PrincipalInvestigator_AddendumPage_Fragment on AccessRequest {\n  user {\n    fullName\n    email\n    id\n  }\n  accessRequestDraft {\n    isPi\n    principalInvestigator {\n      name\n      email\n    }\n    id\n  }\n}\n\nfragment ProjectInfo_AddendumPage_Fragment on AccessRequest {\n  projectName\n  folderName\n  viewOnly\n}\n"
  }
};
})();

node.hash = "60add1e1f02760300def1a4e91bec915";

module.exports = node;
