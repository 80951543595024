import * as R from 'ramda';

import csrf from '../utils/csrf';

const API = {
  /* A generic fetch request using the Fetch API (or appropriate polyfill) to send a request to the middleware server. It will
   ** attempt to configure a request automatically with sensible parameters, but leave room for customization if such is required.
   **
   ** Arguments:
   ** - route: <string> The pathname of the route
   ** - body: <object> For POST request, the request payload to send to the server. If this is set and a FormData object, the request will
   **     automatically be converted into a POST request and given the "application/json" content type.
   ** - opts: <object> Any Fetch API options that should be changed from their defaults.
   **
   ** Returns: A Promise according to the Fetch API.
   */
  fetchData: (route, body = {}, opts = {}) => {
    const defaultOpts = {
      credentials: 'include',
    };
    const options = R.mergeRight(defaultOpts, opts);

    if (!R.isEmpty(body)) {
      if (typeof body === 'object' && !(body instanceof FormData))
        options.body = JSON.stringify(body);
      else options.body = body;
    }
    if (!(R.isEmpty(options.body) || R.isNil(options.body))) {
      options.method = options.method || 'POST';
      if (!(options.body instanceof FormData))
        options.headers = {...options.headers, 'Content-Type': 'application/json'};
    }
    if (options.method && !options.method.match('/^(?:GET|OPTIONS)$/i') && !R.isNil(csrf.token)) {
      options.headers = {...options.headers, ...csrf.headers};
    }

    document.body.setAttribute('data-fetching', true);
    return fetch(route, options).finally(() => document.body.setAttribute('data-fetching', 'done'));
  },

  /* A fetch route designed to return JSON data. It takes the same arguments and returns the same Promise as fetch above, but
   ** it will also attempt to convert the response into JSON before returning and give an error if the JSON response is malformed.
   */
  fetchJSON: (route, body = {}, opts = {}) =>
    API.fetchData(route, body, opts).then(response => {
      if (response.ok) {
        if (response.headers.get('content-type').match(/application\/json/i)) {
          return response.json();
        }
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve, reject) => reject(response));
      }
      if (response.status >= 400) {
        return new Promise((resolve, reject) => {
          response
            .json()
            .then(json => {
              reject(json);
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                error: {
                  type: 'FetchParseError',
                  message: 'A 422 error was thrown, but the response could not be parsed as JSON',
                },
              });
            });
        });
      }
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve, reject) => reject(response));
    }),
};

export default API;
