import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Form, OverlayTrigger, Popover} from 'react-bootstrap';
import classNames from 'classnames';
import USER_TYPES from './userTypes';

const TooltipWrapper = ({children, show, popoverText}) => {
  if (show)
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Body>
              <strong>{popoverText}</strong>
            </Popover.Body>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    );
  return children;
};

const UserDetail = ({
  name,
  email,
  icon,
  self,
  canEdit,
  canDelete,
  popoverText,
  editMode,
  onSave,
  onCancel,
  onDelete,
  onClick,
  error,
  upserting,
  type,
}) => {
  const [user, setUser] = useState({name, email});
  const [canSave, setCanSave] = useState(false);

  const onChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    onSave(user);
  };

  const getEmailText = () => {
    if (email) return email;
    if (type !== USER_TYPES.PRINCIPAL_INVESTIGATOR) return 'Unknown email';
    return ''; // because PI cannot be edited
  };

  useEffect(() => {
    setCanSave(user.name !== name || user.email !== email);
  }, [name, email, user]);

  if (editMode && canEdit)
    return (
      <div className="edit-user-detail p-4 my-2 d-flex flex-column">
        <div className="d-flex">
          <div className="flex-grow-1">
            <span className="me-3 text-primary ">{icon}</span>
            <strong>{type}</strong>
          </div>
          {canDelete && (
            <Button className="my-auto fs-5 text-danger p-0" variant="link" onClick={onDelete}>
              <i className="fas fa-trash-alt" />
            </Button>
          )}
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Control
            className="mb-3 py-2"
            type="text"
            placeholder={`Add ${type}'s name here`}
            value={user.name}
            name="name"
            onChange={onChange}
            required
          />
          <Form.Control
            className="mb-3 py-2"
            type="email"
            placeholder="name@company.com"
            value={user.email || ''}
            name="email"
            onChange={onChange}
            required
          />
          {error && <div className="text-danger">{error}</div>}

          <Form.Group className="d-flex flex-row-reverse">
            <Button
              variant="primary"
              className="px-3"
              type="submit"
              disabled={upserting || !canSave}
            >
              {upserting ? 'Saving' : 'Save'}
            </Button>
            <Button variant="outline-primary" className="mx-2 px-3" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  return (
    <TooltipWrapper popoverText={popoverText} show={!canEdit}>
      <div
        className={classNames('d-flex justify-content-between user-detail', {editable: canEdit})}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <div className="d-flex">
          <span className="me-3 text-primary">{icon}</span>
          <div className="d-flex flex-column">
            <span>
              <span className="fw-bold">{name || type}</span>{' '}
              {self && (
                <Badge bg="" pill className="mx-2 tag-secondary">
                  You
                </Badge>
              )}
            </span>

            <p className="text-small fw-light">{getEmailText()}</p>
          </div>
        </div>

        {canEdit && (
          <div className="edit-icon">
            <i className="fas fa-pen text-small" /> <span className="text-small">Edit</span>
          </div>
        )}
      </div>
    </TooltipWrapper>
  );
};

export default UserDetail;

UserDetail.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  email: PropTypes.string,
  upserting: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  self: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  editMode: PropTypes.bool,
  popoverText: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
};
UserDetail.defaultProps = {
  self: false,
  canEdit: false,
  canDelete: false,
  editMode: false,
  popoverText: 'This cannot be edited',
  type: 'User',
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
  onClick: () => {},
};

TooltipWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  popoverText: PropTypes.string,
};
