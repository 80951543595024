import React, {useState, useRef, useEffect, useCallback, useImperativeHandle} from 'react';
import {AgGridReact} from 'ag-grid-react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';

import {debounce} from '../../../utils';
import {useHasChanged} from '../../hooks';

import NoRowsOverlay from './NoRowsOverlay';
import LoadingOverlay from './LoadingOverlay';
import {tagForRow, rowSelected} from './util';

const Grid = React.forwardRef(function DatabrowserGrid(props, ref) {
  const {
    columnDefs,
    initialLoading,
    isLongLoading,
    isShortLoading,
    selectedTags,
    setSelectedTags,
    tab,
    rowData = [],
  } = props;

  const loadingBarRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const gridApi = useRef(null);

  const shortTimerName = 'showShortLoading';
  const longTimerName = 'showLongLoading';
  const forcedLongTimerName = 'showForcedLongLoading';
  const [timers, setTimers] = useState({
    [shortTimerName]: false,
    [`${shortTimerName}Timer`]: null,
    [longTimerName]: false,
    [`${longTimerName}Timer`]: null,
    [forcedLongTimerName]: false,
    [`${forcedLongTimerName}Timer`]: null,
  });
  const {showShortLoading, showLongLoading, showForcedLongLoading} = timers;

  const initialLoadingChanged = useHasChanged(initialLoading);
  const isShortLoadingChanged = useHasChanged(isShortLoading);
  const isLongLoadingChanged = useHasChanged(isLongLoading);
  const showShortLoadingChanged = useHasChanged(showShortLoading);
  const showLongLoadingChanged = useHasChanged(showLongLoading);
  const showForcedLongLoadingChanged = useHasChanged(showForcedLongLoading);

  const selectTags = useCallback(tags => {
    if (!gridApi.current) return;
    if (tags === true) {
      gridApi.current.selectAll();
      return;
    }

    gridApi.current.forEachNode(row => {
      if (rowSelected(row.data, tags)) row.setSelected(true, false, false);
    });
  }, []);

  useImperativeHandle(ref, () => ({selectTags}), [selectTags]);

  const onRowDataUpdated = useCallback(() => {
    if (!gridApi.current) return;

    gridApi.current.sizeColumnsToFit();
    selectTags(selectedTags);
  }, [selectedTags, selectTags]);

  const onGridReady = useCallback(
    params => {
      gridApi.current = params.api;
      gridApi.current.sizeColumnsToFit();
      selectTags(selectedTags);
    },
    [selectTags, selectedTags]
  );

  const onPaginationChanged = useCallback(() => {
    if (!gridApi.current) return;

    setCurrentPage(gridApi.current.paginationGetCurrentPage() + 1);
    setTotalPages(gridApi.current.paginationGetTotalPages() || 1);
  }, []);

  const onPreviousPage = useCallback(() => {
    if (!gridApi.current || initialLoading) return;

    gridApi.current.paginationGoToPreviousPage();
  }, [initialLoading]);

  const onNextPage = useCallback(() => {
    if (!gridApi.current || initialLoading) return;

    gridApi.current.paginationGoToNextPage();
  }, [initialLoading]);

  const updateSelectedTags = useCallback(() => {
    if (!gridApi.current) return;

    const selectedRows = gridApi.current.getSelectedRows();
    const tags = [];

    selectedRows.forEach(row => tags.push(tagForRow(row)));
    setSelectedTags(tags);
  }, [setSelectedTags]);

  // We don't continuously swap `props.rowData` into AgGrid props mostly
  // because it's more difficult to get the initial loading indicators showing
  // when we propogate props normally.
  useEffect(() => {
    if (!gridApi.current) return;

    gridApi.current.setRowData(rowData);
  }, [rowData]);

  useEffect(() => {
    function startLoadingTimer(name, timeoutMS) {
      const timerKey = `${name}Timer`;

      function onTimeout() {
        setTimers(prevTimers => {
          const timer = prevTimers[timerKey];
          if (timer) clearTimeout(timer);

          return {...prevTimers, [timerKey]: null, [name]: true};
        });
      }

      const timeout = setTimeout(onTimeout, timeoutMS);

      setTimers(prevTimers => {
        const timer = prevTimers[timerKey];
        if (timer) clearTimeout(timer);

        return {...prevTimers, [timerKey]: timeout, [name]: false};
      });
    }

    // We need to determine when to show the 2 types of loading indicators.
    // This requires some sort of change detection on the loading props that
    // are passed in. This is also complicated by the loading timers we want to
    // use to prevent overuse of loading bars for cached or fast-loading data
    // sets.
    const isLongLoadingStarted = isLongLoading && isLongLoadingChanged;
    const isShortLoadingStarted = isShortLoading && isShortLoadingChanged;

    // We first determine if we should start a timer. For "short" timers, we
    // also start a timer to show the "long" timer if the "short" load hasn't
    // completed fast enough. The "forced long load" is configured in regards
    // to how the react-top-loading-bar is configured to load for (we force
    // show the long loading timer near the end of the loading bar).
    if (isLongLoadingStarted) {
      startLoadingTimer(longTimerName, 100);
    } else if (isShortLoadingStarted) {
      startLoadingTimer(shortTimerName, 100);
      startLoadingTimer(forcedLongTimerName, 5500);
    }
  }, [isShortLoading, isLongLoading, isLongLoadingChanged, isShortLoadingChanged]);

  useEffect(() => {
    if (!gridApi.current) return;

    function showLoadingBar() {
      return loadingBarRef.current.continuousStart(15, 1250);
    }

    function hideLoadingBar() {
      return loadingBarRef.current.complete();
    }

    function stopLoadingTimer(name) {
      const timerKey = `${name}Timer`;

      setTimers(prevTimers => {
        const timer = prevTimers[timerKey];
        if (timer) clearTimeout(timer);

        return {...prevTimers, [timerKey]: null, [name]: false};
      });
    }

    const initialLoadingDone = !initialLoading && initialLoadingChanged;
    const isLongLoadingDone = !isLongLoading && isLongLoadingChanged;
    const isShortLoadingDone = !isShortLoading && isShortLoadingChanged;
    const showShortLoadingDone = !showShortLoading && showShortLoadingChanged;
    const showLongLoadingDone = !showLongLoading && showLongLoadingChanged;
    const showForcedLongLoadingDone = !showForcedLongLoading && showForcedLongLoadingChanged;

    // For enabling the loading indicators, we need to check if props changed
    // from not showing to showing. Or the opposite for hiding the loading
    // indicators.
    //
    // The nested branching conditions are important to appropriately stop
    // execution and not erroneously call `gridApi.hideOverlay()`.
    if (initialLoading || showLongLoading || showForcedLongLoading) {
      if (showLongLoadingChanged || showForcedLongLoadingChanged) {
        if (!showShortLoading) showLoadingBar();
        gridApi.current.showLoadingOverlay();
      }
    } else if (showShortLoading) {
      if (showShortLoadingChanged) {
        showLoadingBar();
      }
    } else if (
      initialLoadingDone ||
      showShortLoadingDone ||
      showForcedLongLoadingDone ||
      showLongLoadingDone
    ) {
      hideLoadingBar();
    } else if (rowData.length === 0) {
      gridApi.current.showNoRowsOverlay();
    } else {
      gridApi.current.hideOverlay();
    }

    if (isLongLoadingDone || isShortLoadingDone) {
      stopLoadingTimer(shortTimerName);
      stopLoadingTimer(longTimerName);
      stopLoadingTimer(forcedLongTimerName);
    }
  }, [
    rowData,
    initialLoading,
    initialLoadingChanged,
    isShortLoading,
    isShortLoadingChanged,
    isLongLoading,
    isLongLoadingChanged,
    showShortLoading,
    showShortLoadingChanged,
    showLongLoading,
    showLongLoadingChanged,
    showForcedLongLoading,
    showForcedLongLoadingChanged,
  ]);

  return (
    <div className="data-browser-grid w-100 h-100">
      <div className="grid-container ag-theme-material">
        <LoadingBar
          className="loading-bar"
          containerClassName="loading-bar"
          shadow={false}
          ref={loadingBarRef}
        />
        <AgGridReact
          columnDefs={columnDefs}
          frameworkComponents={{NoRowsOverlay, LoadingOverlay}}
          noRowsOverlayComponent="NoRowsOverlay"
          loadingOverlayComponent="LoadingOverlay"
          loadingOverlayComponentParams={{
            tab,
            isLongLoading: isLongLoading || showForcedLongLoading,
          }}
          pagination
          suppressPaginationPanel
          suppressCellFocus
          sortable
          resizable
          rowMultiSelectWithClick
          suppressDragLeaveHidesColumns
          paginationAutoPageSize={false}
          paginationPageSize={10}
          onSelectionChanged={debounce(updateSelectedTags, 100)}
          onPaginationChanged={onPaginationChanged}
          rowHeight={52}
          rowSelection="multiple"
          onGridReady={onGridReady}
          onRowDataUpdated={onRowDataUpdated}
          rowDataChangeDetectionStrategy="IdentityCheck"
          columnDefsChangeDetectionStrategy="IdentityCheck"
        />
      </div>
      <Row className="pagination-row">
        <Col className="d-flex">
          <div className="d-flex ms-auto">
            <div className="page-text d-inline">
              Page <span className="page-num">{currentPage}</span>
              {' of '}
              <span className="page-num">{totalPages}</span>
            </div>
            <div className="pagination">
              <button type="button" onClick={onPreviousPage}>
                ❮
              </button>
              <button type="button" onClick={onNextPage}>
                ❯
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

Grid.propTypes = {
  rowData: PropTypes.array,
  columnDefs: PropTypes.array,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  setSelectedTags: PropTypes.func.isRequired,
  isLongLoading: PropTypes.bool,
  isShortLoading: PropTypes.bool,
  tab: PropTypes.string,
  initialLoading: PropTypes.bool,
};

export default Grid;
