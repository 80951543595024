import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Alert} from 'react-bootstrap';

import DataAlertMessage from './DataAlertMessage';
import {getUser} from '../../../reducers/user/selectors';
import {rowSelected} from './util';

export const DATA_ALERTS = [
  {
    hasSwitch: true,
    message:
      'One or more of the selected files was generated from a xenograft sample. I acknowledge that St. Jude Cloud does not currently clean non-human reads and that I will need to account for this in my analysis.',
    id: 1,
    conditions: [{key: 'sampleTypes', values: ['xenograft']}],
  },
];

const DataAlertContainer = ({children, selectedTags, show, rowData, dataAlerts}) => {
  const [toggles, onToggle] = useState(dataAlerts);
  const {isLoggedIn} = useSelector(getUser);

  const alerts = dataAlerts.filter(alert =>
    alert.conditions.every(condition =>
      rowData.some(
        row =>
          rowSelected(row, selectedTags) &&
          row[condition.key].some(e => condition.values.indexOf(e) > -1)
      )
    )
  );

  const onToggleSwitch = id => {
    const index = toggles.findIndex(a => a.id === id);
    const newToggles = [...toggles];
    newToggles[index].accepted = !newToggles[index].accepted;
    onToggle(newToggles);
  };

  // Check the already filtered list of alerts for all are toggled
  const hasAcceptedAll = () =>
    alerts.every(alert => {
      const index = toggles.findIndex(a => a.id === alert.id);
      return !alert.hasSwitch || toggles[index].accepted;
    });

  return (
    <>
      {show && alerts.length > 0 && selectedTags.length > 0 && isLoggedIn && (
        <Alert variant="warning" key="data-flash-alerts">
          <Alert.Heading>
            Please note and acknowledge the following regarding your selected data before
            continuing.
          </Alert.Heading>
          {alerts.map(alert => (
            <DataAlertMessage
              key={alert.id}
              hasSwitch={alert.hasSwitch}
              message={alert.message}
              alertKey={alert.id}
              onToggleSwitch={() => onToggleSwitch(alert.id)}
              checked={alert.accepted || false}
            />
          ))}
        </Alert>
      )}
      {children(hasAcceptedAll() && isLoggedIn)}
    </>
  );
};

DataAlertContainer.propTypes = {
  children: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  rowData: PropTypes.array.isRequired,
  show: PropTypes.bool,
  dataAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      hasSwitch: PropTypes.bool,
      message: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      conditions: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default DataAlertContainer;
