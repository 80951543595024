import API from '../../../api';
import {tosAPIV1CurrentUserIndexPath} from '../../../api/routes';
import C from '../../../constants';

export function acceptTOS() {
  return {
    type: C.ACCEPT_TOS_STARTED,
  };
}

export function acceptTOSConfirmed(response) {
  return {
    type: C.ACCEPT_TOS_CONFIRMED,
    value: response,
  };
}

export function acceptTOSFailed() {
  return {
    type: C.ACCEPT_TOS_FAILED,
  };
}

export function sendTOSAcceptance() {
  return dispatch => {
    dispatch(acceptTOS());
    return API.fetchJSON(tosAPIV1CurrentUserIndexPath(), {hasAcceptedTos: true}).then(response => {
      dispatch(acceptTOSConfirmed(response));
    });
  };
}
