/**
 * @generated SignedSource<<f8c3014c2747fcaa18e7ad66bca6b3d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "summaryFilters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DatabrowserSummaryRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SummaryTable_Summary_Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DatabrowserSummaryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Databrowser",
        "kind": "LinkedField",
        "name": "databrowser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "summaryFilters"
              },
              {
                "kind": "Variable",
                "name": "type",
                "variableName": "type"
              }
            ],
            "concreteType": "DatabrowserSelectionSummary",
            "kind": "LinkedField",
            "name": "selectionSummary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalFileSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalFileCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalSampleCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraData",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfd84f4dee1dff17a29e3d626957b2f2",
    "id": null,
    "metadata": {},
    "name": "DatabrowserSummaryRefetchQuery",
    "operationKind": "query",
    "text": "query DatabrowserSummaryRefetchQuery(\n  $summaryFilters: DatabrowserFiltersInput\n  $type: DatabrowserTabType!\n) {\n  ...SummaryTable_Summary_Fragment\n}\n\nfragment SummaryTable_Summary_Fragment on Query {\n  databrowser {\n    selectionSummary(filters: $summaryFilters, type: $type) {\n      totalFileSize\n      totalFileCount\n      totalSampleCount\n      extraData\n    }\n  }\n}\n"
  }
};
})();

node.hash = "188c383a394d78053bc011bc0ad318b8";

module.exports = node;
