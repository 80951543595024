const csrf = {
  init() {
    const tokenEl = document.querySelector('meta[name=csrf-token]');
    if (tokenEl !== null) {
      this.csrfToken = tokenEl.getAttribute('content');
    } else {
      this.csrfToken = null;
    }
  },
  get headerKey() {
    return 'X-CSRF-Token';
  },
  get token() {
    return this.csrfToken;
  },
  get headers() {
    if (this.csrfToken !== null) {
      return {
        [this.headerKey]: this.token,
      };
    }
    return {};
  },
};

csrf.init();

export default csrf;
