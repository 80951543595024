import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

const DEFAULT_VALUE = 'Required';

function SummaryField({name, value}) {
  const className = value ? '' : 'text-danger';
  return (
    <div>
      <Form.Label className="summary-form-label">{name}:</Form.Label>{' '}
      <span className={className}>{value || DEFAULT_VALUE}</span>
    </div>
  );
}

SummaryField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

export default SummaryField;
