import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import countryList from 'react-select-country-list';
import {Form} from 'react-bootstrap';

import CustomSelect from '../../../atoms/CustomSelect';
import parseFormFieldName from './formUtils';

function UserForm({user, setUserField, validated}) {
  const countryOptions = useMemo(() => countryList().getData(), []);

  const onChange = event => {
    const {name, value} = event.target;
    const [field] = parseFormFieldName(name);

    setUserField(field, value);
  };

  const onCheckboxChange = event => {
    const {name, checked} = event.target;
    const [field] = parseFormFieldName(name);
    setUserField(field, checked);
  };

  return (
    <div>
      <div className="wizard-header center">Provide information about yourself below.</div>
      <div className="wizard-sub-header">
        Please provide information about yourself and your institution.
      </div>
      <br />
      <div className="user-form d-flex flex-column align-items-center">
        <Form.Group controlId="user_name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            name="user[fullName]"
            value={user.fullName}
            required
            onChange={onChange}
          />
          <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="user_email">
          <Form.Label>Your Email</Form.Label>
          <Form.Control
            type="email"
            name="user[email]"
            required
            value={user.email}
            onChange={onChange}
            data-testid="text-edaa-user-email"
          />
          <Form.Control.Feedback type="invalid">
            Email must be a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="user_jobTitle">
          <Form.Label>Job Title</Form.Label>
          <Form.Control
            type="text"
            name="user[jobTitle]"
            value={user.jobTitle}
            required
            onChange={onChange}
          />
          <Form.Control.Feedback type="invalid">Job Title is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="user_institution">
          <Form.Label>Institution</Form.Label>
          <Form.Control
            type="text"
            name="user[institution]"
            value={user.institution}
            required
            onChange={onChange}
          />
          <Form.Control.Feedback type="invalid">Institution is required.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="d-flex flex-column" controlId="user_country">
          <Form.Label>Country of Institution</Form.Label>
          <CustomSelect
            name="user[country]"
            options={countryOptions}
            currentOption={user.country}
            onChange={({value}) => {
              setUserField('country', value);
            }}
            className="form-input-select"
            validated={validated}
          />
          {validated && !user.country && (
            <div className="d-block invalid-feedback">Country of Institution is required.</div>
          )}
        </Form.Group>
        <Form.Check
          name="user[emailReminders]"
          className="mt-3 form-text"
          id="user[emailReminders]"
          label="Receive email updates about St. Jude Cloud"
          type="switch"
          checked={user.emailReminders}
          onChange={onCheckboxChange}
          data-testid="check-edaa-reminders"
        />
        {user?.country !== 'US' && (
          <div className="form-text text-danger">
            <Form.Check
              name="user[wantsEsign]"
              id="user[wantsEsign]"
              label="At this time, we do not support an electronic data access agreement
              process with DocuSign in your country. Click the toggle if you would like us to support this process in your
              country in the future."
              type="switch"
              checked={user.wantsEsign}
              onChange={onCheckboxChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

UserForm.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    jobTitle: PropTypes.string,
    institution: PropTypes.string,
    country: PropTypes.string,
    emailReminders: PropTypes.bool,
    wantsEsign: PropTypes.bool,
  }),
  setUserField: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
};

export default UserForm;
