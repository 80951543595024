import {useRef, useEffect, useCallback} from 'react';

export default function useTimeout(fn, milliseconds) {
  const timer = useRef(fn ? setTimeout(fn, milliseconds) : null);

  const cancelTimer = useCallback(() => {
    if (timer.current) clearTimeout(timer.current);
  }, []);

  useEffect(() => cancelTimer, [cancelTimer]);

  const setTimer = useCallback(
    (newFn, newMilliseconds = milliseconds) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(newFn, newMilliseconds);
    },
    [milliseconds]
  );

  return [cancelTimer, setTimer];
}
