import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';

import Link from '../../atoms/Link';
import {getIsUserLoggedIn} from '../../../reducers/user/selectors';

function SubmitButton({enabled, onClick}) {
  const isLoggedIn = useSelector(getIsUserLoggedIn);

  if (isLoggedIn) {
    if (enabled) {
      return (
        <Button variant="primary" onClick={onClick} size="lg">
          Request Data
        </Button>
      );
    }

    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="requiredActionsTooltip">
            Select some data and acknowledge all notices to continue.
          </Tooltip>
        }
      >
        <Button variant="primary" disabled size="lg">
          Request Data
        </Button>
      </OverlayTrigger>
    );
  }

  return (
    <Link to="/login">
      <Button variant="primary" size="lg">
        Log In
      </Button>
    </Link>
  );
}

SubmitButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SubmitButton;
