import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_VALUE = 'Required';

function ContemplatedUseSummary({contemplatedUse}) {
  const className = contemplatedUse ? '' : 'text-danger';
  return <p className={className}>{contemplatedUse || DEFAULT_VALUE}</p>;
}

ContemplatedUseSummary.propTypes = {
  contemplatedUse: PropTypes.string,
};

export default ContemplatedUseSummary;
