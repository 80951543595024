import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import {Button} from 'react-bootstrap';

import {
  documentationPath,
  checkAPIV1DataAccessAgreementPath,
  apiV1DataAccessAgreementPath,
  uploadManualAccessAgreementAPIV1AccessRequestDraftPath,
} from '../../../../api/routes';

import Link from '../../../atoms/Link';
import DownloadLink from '../../../atoms/DownloadLink';
import DropzoneWrapper from '../../../complexes/DropzoneForm/DropzoneWrapper';
import AccessUnitCheckbox from './AccessUnitCheckbox';
import API from '../../../../api';
import {useLoading} from '../../../hooks';

import {FILE_UPLOAD_VALIDATION} from '../../../../constants';

const {FILE_SIZE_LIMIT, FILE_TOO_LARGE_MESSAGE, INCORRECT_FILE_TYPE_MESSAGE} =
  FILE_UPLOAD_VALIDATION;

async function uploadDAA(id, files, setUploadedFilename) {
  if (!files || !files[0])
    throw new Error('There was no file to upload when attempting to upload.');

  const file = files[0];
  if (file.size > FILE_SIZE_LIMIT) throw new Error(FILE_TOO_LARGE_MESSAGE);
  if (file.type !== 'application/pdf') throw new Error(INCORRECT_FILE_TYPE_MESSAGE);
  setUploadedFilename(file.name);

  const formData = new FormData();
  formData.append('file', file);

  const response = await API.fetchData(
    uploadManualAccessAgreementAPIV1AccessRequestDraftPath(id),
    formData,
    {
      method: 'POST',
    }
  );

  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.error.message);
  }
}

async function checkDAA(id, setShowDownloadButton) {
  const response = await API.fetchData(checkAPIV1DataAccessAgreementPath(id));

  if (!response.ok) {
    const json = await response.json();
    setShowDownloadButton(false);
    throw new Error(json.error.message);
  }

  setShowDownloadButton(true);
}

function DropzoneSpinner({isUploading, uploadedFilename}) {
  if (isUploading) {
    return <Spinner name="three-bounce" fadeIn="none" />;
  }

  if (uploadedFilename) {
    return <div>File {uploadedFilename} Uploaded</div>;
  }

  return (
    <div>
      Drop signed request form here or <span className="link-like">click to select a file</span> to
      upload.
    </div>
  );
}

DropzoneSpinner.propTypes = {
  isUploading: PropTypes.bool,
  uploadedFilename: PropTypes.string,
};

function DropzoneErrors({uploadError}) {
  if (!uploadError) return null;

  return <div className="text-danger">{uploadError.message}</div>;
}

DropzoneErrors.propTypes = {
  uploadError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

function ManualDAADropzone({isUploading, uploadedFilename, uploadError, onDrop}) {
  return (
    <div className="my-3">
      <DropzoneWrapper onDrop={onDrop} multiple={false} accept={{'application/pdf': ['.pdf']}}>
        <DropzoneSpinner isUploading={isUploading} uploadedFilename={uploadedFilename} />
      </DropzoneWrapper>
      <div className="errors my-2 text-center">
        <DropzoneErrors uploadError={uploadError} />
      </div>
    </div>
  );
}

ManualDAADropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  uploadError: PropTypes.shape({
    message: PropTypes.string,
  }),
  isUploading: PropTypes.bool,
  uploadedFilename: PropTypes.string,
};

export default function ManualApplicationForm({id, requiredAccessUnits, wantsEsign, accessUnits}) {
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [{error: checkError}, startCheckFetch] = useLoading(null);
  const [{error: uploadError, isLoading: isUploading}, startFormUpload] = useLoading(null);
  const [uploadedFilename, setUploadedFilename] = useState(null);

  const onCheckAgreement = useCallback(
    () => startCheckFetch(() => checkDAA(id, setShowDownloadButton)),
    [id, setShowDownloadButton, startCheckFetch]
  );

  const onDrop = useCallback(
    files => startFormUpload(() => uploadDAA(id, files, setUploadedFilename)),
    [id, setUploadedFilename, startFormUpload]
  );

  return (
    <div className="manual-application">
      <div className="wizard-header">Complete and upload the data access agreement.</div>
      {wantsEsign && (
        <div>
          <div className="warning-message center">
            At this time, we do not support electronic signing of the data access agreement for
            institutions in your country.
          </div>
          <br />
        </div>
      )}
      <div className="page-sub-header">First, download and complete the data access agreement.</div>
      <div className="access-units">
        <span className="box-header">
          On page 7, ensure the following data access units are selected:
        </span>
        <br />
        <ul>
          {accessUnits.map(accessUnit => {
            const {name, description, unrestricted} = accessUnit;
            if (unrestricted) return null;
            return (
              <AccessUnitCheckbox
                checked={requiredAccessUnits.includes(name)}
                description={description}
                key={name}
              />
            );
          })}
        </ul>
      </div>
      <div className="agreement-download">
        <Button variant="primary" className="download-button" onClick={onCheckAgreement}>
          Generate Data Access Agreement
        </Button>
        {showDownloadButton && (
          <Button
            variant="primary"
            as={DownloadLink}
            className="download-button"
            href={apiV1DataAccessAgreementPath(id)}
            download="StJudeCloud_Data_Access_Agreement.pdf"
          >
            <i className="fa fa-download fa-lg download-icon" /> Download Data Access Agreement
          </Button>
        )}
        <Link href={documentationPath('/requesting-data/how-to-fill-out-daa/')} newTab>
          <span>Need help filling out the DAA?</span>
        </Link>
        {checkError && <div className="text-danger text-center">{checkError.message}</div>}
      </div>
      <div className="page-sub-header">Then, upload your signed data access agreement below.</div>
      <ManualDAADropzone
        isUploading={isUploading}
        uploadError={uploadError}
        onDrop={onDrop}
        uploadedFilename={uploadedFilename}
      />
    </div>
  );
}

ManualApplicationForm.propTypes = {
  id: PropTypes.string.isRequired,
  wantsEsign: PropTypes.bool,
  requiredAccessUnits: PropTypes.arrayOf(PropTypes.string),
  accessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      unrestricted: PropTypes.bool,
    })
  ),
};
