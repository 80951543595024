import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';

import {getCurrentUser} from '../../../reducers/user/selectors';
import {pushFlashAlert} from '../../../actions';
import {Link} from '../../atoms/Link';

function DraftFlashAlert() {
  const dispatch = useDispatch();
  const {hasActiveDraft} = useSelector(getCurrentUser);

  useEffect(() => {
    if (!hasActiveDraft) {
      return;
    }

    const draftAlert = pushFlashAlert({
      dismissable: true,
      variant: 'warning',
      content: (
        <div className="d-flex align-items-center justify-content-center">
          You have a data request in progress. Would you like to continue with your previous draft?
          <Button size="sm" className="documentation-button ms-2" as={Link} to="/dashboard">
            Go to Manage Data
          </Button>
        </div>
      ),
    });

    dispatch(draftAlert);
  }, [hasActiveDraft, dispatch]);

  return null;
}

export default DraftFlashAlert;
