import {useEffect, useState} from 'react';

export default function useLoading(initialFn) {
  const [{fn}, setFn] = useState({fn: initialFn});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fn) return;

    const load = async () => {
      setData(null);
      setError(null);
      setIsLoading(true);

      try {
        const result = await fn();
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [fn]);

  return [{data, error, isLoading}, newFn => setFn({fn: newFn})];
}
