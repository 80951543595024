import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col} from 'react-bootstrap';

function LoginCard(props) {
  const {loginUrl, registerUrl, image, title, subtext} = props;

  return (
    <Col md={8} lg={5} className="login-card">
      <img src={image} className="login-card__logo" alt={`${title} login`} />
      <div className="login-card__info">
        <h5 className="login-card__title">{title}</h5>
        <a href={loginUrl}>
          <Button variant="primary" className="mt-4">
            Log In
          </Button>
        </a>
        {registerUrl != null && (
          <a href={registerUrl}>
            <Button variant="outline-primary" className="mt-2">
              Register
            </Button>
          </a>
        )}
        {subtext != null && <p className="login-card__subtext">{subtext}</p>}
      </div>
    </Col>
  );
}

LoginCard.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  registerUrl: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
};

export default LoginCard;
