/**
 * @generated SignedSource<<d7e1556b819165da92c0de8476071697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectDxid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "userInput"
    }
  ],
  "concreteType": "UpdateUserMutationPayload",
  "kind": "LinkedField",
  "name": "updateUser",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessRequestSubmissionWizard_SubmitDraft_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitAccessRequestDraftMutationPayload",
        "kind": "LinkedField",
        "name": "submitAccessRequestDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequest",
            "kind": "LinkedField",
            "name": "accessRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessRequestSubmissionWizard_SubmitDraft_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitAccessRequestDraftMutationPayload",
        "kind": "LinkedField",
        "name": "submitAccessRequestDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequest",
            "kind": "LinkedField",
            "name": "accessRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "efa6894ef8b58cc355891cef95911829",
    "id": null,
    "metadata": {},
    "name": "AccessRequestSubmissionWizard_SubmitDraft_Mutation",
    "operationKind": "mutation",
    "text": "mutation AccessRequestSubmissionWizard_SubmitDraft_Mutation(\n  $input: SubmitAccessRequestDraftMutationInput!\n  $userInput: UpdateUserMutationInput!\n) {\n  submitAccessRequestDraft(input: $input) {\n    accessRequest {\n      projectDxid\n      id\n    }\n    error {\n      type\n      message\n    }\n  }\n  updateUser(input: $userInput) {\n    error {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "1c17a096da6096e61b018d84d8977e47";

module.exports = node;
