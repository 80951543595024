import React from 'react';
import PropTypes from 'prop-types';
import {keys} from 'ramda';
import classnames from 'classnames';
import {filterAttributeProps} from '../../../utils';

const propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};

function Image(props) {
  const {className} = props;

  const topClassNames = classnames([className]);
  const passedProps = filterAttributeProps(keys(propTypes))(props);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img className={topClassNames} {...passedProps} />;
}

Image.propTypes = propTypes;

export default Image;
