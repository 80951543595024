import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {loadQuery} from 'react-relay';

import graphqlEnvironment from '../graphqlEnvironment';

import PageTemplate from './templates/PageTemplate';
import CloudSpinner from './atoms/CloudSpinner';

import Routes from './Routes';
import userQuery from './userQuery';

const preloadedQuery = loadQuery(graphqlEnvironment, userQuery, {});

export default function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <PageTemplate>
            <div className="d-flex justify-content-center my-4">
              <CloudSpinner />
            </div>
          </PageTemplate>
        }
      >
        <Routes preloadedUserQuery={preloadedQuery} />
      </Suspense>
    </BrowserRouter>
  );
}
