import React, {useState, useRef, useMemo, useCallback, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {graphql, useRefetchableFragment, fetchQuery, useRelayEnvironment} from 'react-relay';
import {
  Nav,
  Tab,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
  InputGroup,
  FormLabel,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import classnames from 'classnames';
import {pathOr, mergeDeepRight} from 'ramda';
import {useDispatch} from 'react-redux';

import DatabrowserFiltersContext from '../../atoms/DatabrowserFiltersContext';
import FilterBox from '../../atoms/FilterBox';
import PageSidebarTemplate from '../../templates/PageSidebarTemplate';
import {pushFlashAlert} from '../../../actions';
import {useHasChanged, useDidUpdateEffect, useIsMounted, useStableIdentity} from '../../hooks';
import {objectWithoutKeys} from '../../../utils';

import DatabrowserHeader from './DatabrowserHeader';
import DatabrowserSubheader from './DatabrowserSubheader';
import SideNav from './SideNav';
import Grid from './Grid';
import DataAlertContainer, {DATA_ALERTS} from './DataAlertContainer';
import SubmitButton from './SubmitButton';
import columnDefinitions from './columnDefs';
import {
  tagForRow,
  tabOrSubtab,
  prepareGraphQLFilters,
  shareableURL,
  tabToType,
  removeEmptyFilters,
} from './util';
import DownloadManifestButton from './DownloadManifestButton';

import databrowserQuery from './databrowserQuery';

const databrowserFragment = graphql`
  fragment Databrowser_Fragment on Query @refetchable(queryName: "DatabrowserRefetchQuery") {
    ...SummaryTable_Summary_Fragment
    ... @include(if: $includeDatabrowserRows) {
      databrowser {
        items(filters: $filters, type: $type) {
          name
          sampleTypes
          totalFileSize
          totalFileCount
          totalSampleCount
          seqTypes
          fileTypes
          accessUnits
          allSeqTypes
          allFileTypes
          ... on DatabrowserTumor {
            diseaseCode
          }
          ... on DatabrowserPairedTumorNormal {
            diagnosisCode
          }
          ... on DatabrowserGermline {
            diagnosisCode
          }
          ... on DatabrowserDataset {
            accessionNumber
          }
          ... on DatabrowserPublication {
            publicationAccession
            pmid
          }
        }
      }
    }
  }
`;

// For clarity within the `Databrowser` component, group redux-related actions
// within this object.
const redux = {
  pushFlashAlert,
};

function Databrowser({
  tab: initialTab,
  subtab: initialSubtab,
  filters: initialFilters,
  selectTagsAfterLoad,
  databrowserQueryData,
  sidebarFiltersFragmentData,
}) {
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const graphqlEnvironment = useRelayEnvironment();
  const [databrowserFragmentData, relayRefetch] = useRefetchableFragment(
    databrowserFragment,
    databrowserQueryData
  );

  const databrowserItems = pathOr([], ['databrowser', 'items'], databrowserFragmentData);

  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  // We use refs for these values instead of state because we don't need to
  // trigger re-renders when they change.
  const gridRef = useRef();
  const refetch = useRef(null);

  const {setFilters: setFiltersCtx, setRequestType: setRequestTypeCtx} =
    useContext(DatabrowserFiltersContext);

  // Differentiate `summaryTab` from `tab` so we can smoothly transition
  // summary table after refetches complete, not immediately when a new tab is
  // clicked.
  const [tab, setTab] = useState(initialTab);
  const [subtab, setSubtab] = useState(initialSubtab);
  const [summaryTab, setSummaryTab] = useState(tabOrSubtab(initialTab, initialSubtab));
  const [rawColumnDefs, setColumnDefs] = useState(
    columnDefinitions(tabOrSubtab(initialTab, initialSubtab))
  );

  // The default `checked` status of the PeCan toggle
  const [showPeCanSamples, setShowPeCanSamples] = useState(!!initialFilters.setId);

  // Ensure rows are (re)selected on load if needed
  const [reselectTags, setReselectTags] = useState(selectTagsAfterLoad);

  // Keep track of how much data we are `relay.refetch`ing so this can tell
  // child components if they should show various loading indicators.
  const [isLongLoading, setIsLongLoading] = useState(false);
  const [isShortLoading, setIsShortLoading] = useState(false);
  const [isDataLoading, setisDataLoading] = useState(false);

  const [rawFilters, rawSetFilters] = useState({
    setId: null,
    filterText: '',
    selectedTags: [],
    exactMatch: false,
    ...initialFilters,
  });

  // Ensure these objects only change when the objects actually change.
  // React/Object.is doesn't know when `databrowserItems` or `rawColumnDefs` changes and
  // erroneously re-runs effects/callbacks.
  const rowData = useStableIdentity(databrowserItems);
  const columnDefs = useStableIdentity(rawColumnDefs);

  // This is equivalent to a class component's `getDerivedStateFromProps`. It
  // is a memoized value as not all state updates will affect the derived
  // `filters`. Whenever we get new rowData, we need to remove any tags from
  // `selectedTags` that are no longer applicable. Because we don't want to
  // trigger state updates, we need to derive our new state. Triggering state
  // updates for `selectedTags` changes would cause a new refetch. Although the
  // result would be cached, the potential roundtrip is ultimately unnecessary.
  const filters = useMemo(() => {
    const derivedTags = [];
    rawFilters.selectedTags.forEach(tag => {
      if (rowData.some(row => tagForRow(row) === tag)) derivedTags.push(tag);
    });

    return {...rawFilters, selectedTags: derivedTags};
  }, [...useStableIdentity([rawFilters]), rowData]); // eslint-disable-line react-hooks/exhaustive-deps
  const selectedTags = useStableIdentity(filters.selectedTags);

  // We need to know which specific values changed in order to initiate
  // different types of `relay.refetch`s.
  const initialLoadingChanged = useHasChanged(initialLoading);
  const isLongLoadingChanged = useHasChanged(isLongLoading);
  const isShortLoadingChanged = useHasChanged(isShortLoading);

  // We include a check for if the user toggled the PeCan context. This is
  // only applicable if the user came from PeCan and the user is still on the
  // sample tab. This check is used to determine if we need to refetch rows for
  // the specific `filters.setId` or all samples.
  const showPeCanSamplesChanged = useHasChanged(showPeCanSamples);

  // Comparing differences in filters without regards to `exactMatch` or
  // `selectedTags` is fine. If either of the `filters.*_types` or `filterText`
  // change, we can directly check for inequality.
  const filtersChanged = useHasChanged(objectWithoutKeys(filters, ['exactMatch', 'selectedTags']));

  // Separately check if the user's chosen tags have changed. This check is
  // used to determine if we need to refetch databrowser rows _and_ the
  // summary, or _only_ the summary.
  const selectedTagsChanged = useHasChanged(filters.selectedTags);
  const rawSelectedTagsChanged = useHasChanged(rawFilters.selectedTags);
  const exactMatchChanged = useHasChanged(filters.exactMatch);
  const tabChanged = useHasChanged(tab);
  const subtabChanged = useHasChanged(subtab);

  // We want to ensure the UX around toggling the `exactMatch` option is as
  // smooth as possible. This means not refetching when exactMatch was
  // toggled and there is no filterText to filter on. Otherwise, the refetch
  // is useless.
  const exactMatchChangedWithText = exactMatchChanged && filters.filterText !== '';
  const databrowserRowsChanged =
    filtersChanged ||
    tabChanged ||
    subtabChanged ||
    exactMatchChangedWithText ||
    showPeCanSamplesChanged;

  // When loading finishes, we want to select whatever tags may need selecting
  // for the user. This is important whenever the databrowser's rows change.
  const isDoneInitialLoading = !initialLoading && initialLoadingChanged;
  const isDoneLongLoading = !isLongLoading && isLongLoadingChanged;
  const isDoneShortLoading = !isShortLoading && isShortLoadingChanged;
  const shouldSelectTags =
    reselectTags && (isDoneInitialLoading || isDoneLongLoading || isDoneShortLoading);

  // We only want to initiate a refetch when both `selectedTags` and
  // `rawSelectedTags` change. Both values change only when a user selects or
  // deselects a row from the databrowser. Changes between only one value
  // happens as a result of state derived tags changing (when rows are removed)
  // or when the original tags are synchronized to the derived tags (when
  // various setState triggers run).
  const tagsChanged = selectedTagsChanged && rawSelectedTagsChanged;

  const setFilters = useCallback(
    newFilters => rawSetFilters(state => removeEmptyFilters(mergeDeepRight(state, newFilters))),
    []
  );
  const setExactMatch = useCallback(
    () =>
      rawSetFilters(state =>
        removeEmptyFilters(mergeDeepRight(state, {exactMatch: !state.exactMatch}))
      ),
    []
  );
  const setFilterText = useCallback(filterText => setFilters({filterText}), [setFilters]);
  const setSelectedTags = useCallback(tags => setFilters({selectedTags: tags}), [setFilters]);

  const storeFiltersCallback = useCallback(() => {
    // We really don't want to store `setId`s. It will inadvertently lock the
    // user into a PeCan request indefinitely, and we don't want to confuse
    // them by returning them to a PeCan-based databrowser context when they
    // navigated to the databrowser through non-PeCan means.
    setFiltersCtx(removeEmptyFilters(objectWithoutKeys(filters, 'setId')));
    setRequestTypeCtx(tabToType(tab, subtab));
  }, [filters, tab, subtab, setFiltersCtx, setRequestTypeCtx]);

  const redirectToTab = useCallback(
    newTab => {
      // If the user is already on this tab, do nothing.
      if (newTab === tab) return;

      const switchingToPeCanSamples = newTab === 'samples' && filters.setId && showPeCanSamples;

      // If the user changes tabs, we want to clear their `selectedTags` and
      // `filterText` as they no longer apply. We also need to keep track of
      // the new tab the user is on.
      setTab(newTab);
      setFilters({selectedTags: [], filterText: ''});
      setReselectTags(switchingToPeCanSamples);

      let path = `/data/${newTab}`;

      if (newTab === 'diseases' && subtab) {
        path += `/${subtab}`;
      }

      if (switchingToPeCanSamples) {
        path += `/${filters.setId}`;
      }

      navigate(path, {replace: true});
    },
    [tab, subtab, showPeCanSamples, filters.setId, setFilters, navigate]
  );

  const redirectToSubtab = useCallback(
    newSubtab => {
      if (tab !== 'diseases') return;
      if (newSubtab === subtab) return;

      // If the user changes tabs, we want to clear their `selectedTags` as
      // they probably no longer apply. We also need to keep track of the new
      // subtab the user is on.
      setSubtab(newSubtab);
      setFilters({selectedTags: []});

      navigate(`/data/diseases/${newSubtab}`, {replace: true});
    },
    [tab, subtab, setFilters, navigate]
  );

  const togglePeCanSamples = useCallback(() => {
    setShowPeCanSamples(!showPeCanSamples);
    setReselectTags(!showPeCanSamples);

    // Don't use `mergeDeepRight` or `setFilters` so we can effectively reset
    // the `filters` object.
    rawSetFilters(
      removeEmptyFilters({
        setId: filters.setId,
        filterText: '',
        selectedTags: [],
        exactMatch: false,
      })
    );

    if (showPeCanSamples) {
      navigate('/data/samples', {replace: true});
    } else {
      navigate(`/data/samples/${filters.setId}`, {replace: true});
    }
  }, [showPeCanSamples, filters.setId, navigate]);

  const onRequestData = useCallback(() => {
    refetch.current?.unsubscribe();
    storeFiltersCallback();
    navigate('/requests');
  }, [storeFiltersCallback, navigate]);

  // If we're loading data for a tab that has a stored set located server side,
  // we need to mark every row as selected when loading is finished, either
  // from initial load or reloads triggered from tab changes. These sets are
  // loaded from URLs like `/data/samples/1a2b3c4d5e6f7890`. Unfortunately this
  // does trigger a refetch, but only for summary information, so the refetch
  // is typically fast and not known to the user.
  useDidUpdateEffect(() => {
    function selectTags() {
      if (reselectTags === true) {
        gridRef.current.selectTags(true);
        return;
      }

      if (!Array.isArray(reselectTags)) return;

      const tags = [];

      // We don't `setState` here to mark every row as selected. Instead, we
      // gather up all the tags and ask the `Grid` to toggle the selected
      // checkbox for each row. This will call `setState` and subsequenty
      // trigger a refetch for new summary data.
      rowData.forEach(row => {
        const tag = tagForRow(row);

        // If `true`, we select every row. Otherwise, just select whatever is
        // in the list, if any.
        if (reselectTags.includes(tag)) {
          tags.push(tag);
        }
      });
      gridRef.current.selectTags(tags);
    }

    // (Re)synchronize `rawFilters.selectedTags` and `filters.selectedTags`.
    // `filters.selectedTags` is derived from `rawFilters.selectedTags`. There
    // is a scenario as follows:
    // 1. User loads the databrowser and selects a row called "Sample 1" with
    //    file types [BAM, gVCF]
    // 2. User selects a file type filter of CNV
    //    -> "Sample 1" should no longer be present
    // 3. User unselects file type filter CNV
    //    -> "Sample 1" should be present and not selected
    // Without this synchronization, "Sample 1" would still be selected; there
    // would be no `filters.selectedTags` for the Grid component to re-toggle
    // as selected in order to reselect rows, and thus no subsequent updates to
    // `rawFilters.selectedTags`.
    function synchronizeSelectedTags() {
      setSelectedTags(selectedTags);
    }

    if (shouldSelectTags) {
      selectTags();
    } else if (selectedTagsChanged) {
      synchronizeSelectedTags();
    }
  }, [
    filters.setId,
    reselectTags,
    shouldSelectTags,
    selectedTagsChanged,
    setSelectedTags,
    selectedTags,
    rowData,
    tab,
    subtab,
  ]);

  // Similar to above, it is important know exactly which of the declared
  // dependencies triggered this `useEffect` hook. The refetch will request
  // slightly different data depending on how the user interacts with the
  // databrowser, and that means knowing what the user changed.
  useEffect(() => {
    function onRefetchCompletion(err) {
      refetch.current = null;

      if (!isMounted()) return;

      setColumnDefs(columnDefinitions(tabOrSubtab(tab, subtab)));
      setSummaryTab(tabOrSubtab(tab, subtab));
      setIsLongLoading(false);
      setIsShortLoading(false);
      setisDataLoading(false);

      if (!err) return;

      dispatch(
        redux.pushFlashAlert({
          dismissable: true,
          variant: 'warning',
          content:
            'An error occurred while trying to customize your data selection. Please try again.',
        })
      );
    }

    function startRefetch(switchingTabs, rowsChanged) {
      // Carry several of the state values forward in the event where a user
      // selected a left-side filter and/or a row on the databrowser before the
      // previous refetch completed.
      setIsLongLoading(prevIsLongLoading => prevIsLongLoading || switchingTabs);
      setIsShortLoading(prevIsShortLoading => prevIsShortLoading || rowsChanged);

      // Exclude `setId` for irrelevant tabs. While this is ignored server-side
      // when it isn't applicable, it does affect the server cache. Additionally,
      // it is a minor amount of data that doesn't need to be transmitted.
      let databrowserFilters = {...filters};
      if (tab !== 'samples' || !(filters.setId && showPeCanSamples)) {
        databrowserFilters = objectWithoutKeys(databrowserFilters, 'setId');
      }

      const variables = prepareGraphQLFilters(databrowserFilters, tab, subtab);
      variables.includeDatabrowserRows = true;
      variables.initialFetch = initialLoading;

      // Avoid Suspense-ful loading by using fetchQuery:
      // https://relay.dev/docs/guided-tour/refetching/refetching-fragments-with-different-data/#if-you-need-to-avoid-suspense
      return fetchQuery(graphqlEnvironment, databrowserQuery, variables, {
        networkCacheConfig: {force: false},
      }).subscribe({
        complete: () => {
          // *After* the query has been fetched, we call refetch again to
          // re-render with the updated data. At this point the data for the
          // query should be cached, so we use the 'store-only' fetchPolicy to
          // avoid suspending.

          relayRefetch(variables, {
            onComplete: onRefetchCompletion,
            fetchPolicy: 'store-only',
          });
          setisDataLoading(true);
        },
        error: onRefetchCompletion,
      });
    }

    if (initialLoading || databrowserRowsChanged) {
      storeFiltersCallback();
      refetch.current?.unsubscribe();
      refetch.current = startRefetch(tabChanged || subtabChanged, databrowserRowsChanged);
      setInitialLoading(false);
      setisDataLoading(false);
    }
  }, [
    dispatch,
    filters,
    isMounted,
    relayRefetch,
    showPeCanSamples,
    storeFiltersCallback,
    tab,
    tabChanged,
    subtab,
    subtabChanged,
    databrowserRowsChanged,
    initialLoading,
    graphqlEnvironment,
  ]);

  const showPeCanContext = filters.setId && tab === 'samples';
  const pecanToggleActive = showPeCanSamples && tab === 'samples';

  return (
    <PageSidebarTemplate
      header={<DatabrowserHeader pecan={pecanToggleActive && showPeCanContext} />}
      subHeader={
        <DatabrowserSubheader
          show={showPeCanContext}
          pecanToggleActive={pecanToggleActive}
          onTogglePecan={togglePeCanSamples}
        />
      }
      sideNav={
        <SideNav
          databrowserFragmentData={databrowserFragmentData}
          sidebarFiltersFragmentData={sidebarFiltersFragmentData}
          shouldRefetchSummary={tagsChanged && !databrowserRowsChanged}
          filters={filters}
          setFilters={setFilters}
          tab={summaryTab}
          shareableURL={shareableURL(filters, tab, subtab)}
        />
      }
      extra={<DownloadManifestButton />}
      documentationPath="/requesting-data/making-a-data-request/"
    >
      <div className="data-browser-page my-4">
        <div className={classnames('data-browser-container', {disabled: initialLoading})}>
          <Tab.Container
            id="data-browser-tabs"
            defaultActiveKey="diseases"
            transition={false}
            activeKey={tab}
            onSelect={key => redirectToTab(key)}
          >
            <Nav variant="tabs" fill className="data-browser-tab-list">
              <Nav.Item className="data-browser-tab">
                <Nav.Link eventKey="diseases" disabled={initialLoading}>
                  Diagnoses
                  <div className="tab-sub-text">Samples grouped by primary diagnosis</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="data-browser-tab">
                <Nav.Link eventKey="publications" disabled={initialLoading}>
                  Publications
                  <div className="tab-sub-text">Samples grouped by publication</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="data-browser-tab">
                <Nav.Link eventKey="cohorts" disabled={initialLoading}>
                  Studies
                  <div className="tab-sub-text">Datasets curated by St. Jude</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="data-browser-tab">
                <Nav.Link eventKey="samples" disabled={initialLoading}>
                  Samples
                  <div className="tab-sub-text">All samples curated by St. Jude</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <hr className={classnames(['tab-underline', tab])} />
            <Tab.Content>
              <Row className="top-row">
                {tab === 'diseases' && (
                  <Col className="d-flex g-0">
                    <div className="subtab-button-group">
                      <ToggleButtonGroup
                        type="radio"
                        className="btn-group-databrowser-subtab"
                        name="diseases-subtab"
                        defaultValue="paired-tumor-normal"
                        value={subtab}
                        onChange={redirectToSubtab}
                      >
                        <ToggleButton
                          variant="outline-primary"
                          type="radio"
                          id="tumor-radio-btn"
                          value="tumor"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Request tumor samples from both paired tumor-normal and tumor only
                                datasets
                              </Tooltip>
                            }
                          >
                            <div className="mx-auto">Tumor</div>
                          </OverlayTrigger>
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          variant="outline-primary"
                          id="paired-tumor-normal-radio-btn"
                          value="paired-tumor-normal"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>Request samples from paired tumor-normal datasets</Tooltip>
                            }
                          >
                            <div className="mx-auto">Paired Tumor-Normal</div>
                          </OverlayTrigger>
                        </ToggleButton>
                        <ToggleButton
                          variant="outline-primary"
                          type="radio"
                          id="germline-radio-btn"
                          value="germline"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Request germline samples from both paired tumor-normal and germline
                                only datasets
                              </Tooltip>
                            }
                          >
                            <div className="mx-auto">Germline</div>
                          </OverlayTrigger>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>
                )}
                <Col
                  className="g-0 my-auto top-row-right-col"
                  sm={tab === 'diseases' ? 6 : undefined}
                >
                  <div className="databrowser-filter-group d-flex">
                    <FilterBox
                      key={tabOrSubtab(tab, subtab)}
                      className="w-100"
                      placeholder="Search"
                      name="databrowserFilters[filterText]"
                      onChange={setFilterText}
                      value={filters.filterText}
                      disabled={initialLoading}
                      floating
                    >
                      <InputGroup.Text as={FormLabel} htmlFor="filter-exact-search">
                        Search Exact Phrase
                      </InputGroup.Text>
                      <InputGroup.Checkbox
                        aria-label="Checkbox for searching exact phrase"
                        name="databrowserFilters[exactMatch]"
                        id="filter-exact-search"
                        onChange={setExactMatch}
                        checked={filters.exactMatch}
                        disabled={initialLoading}
                      />
                    </FilterBox>
                  </div>
                </Col>
              </Row>
              <Grid
                tab={tabOrSubtab(tab, subtab)}
                rowData={rowData}
                columnDefs={columnDefs}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                initialLoading={initialLoading}
                isLongLoading={isLongLoading || isDataLoading === false}
                isShortLoading={isShortLoading}
                ref={gridRef}
              />
            </Tab.Content>
          </Tab.Container>

          <ul className="bottom-row w-100">
            <DataAlertContainer
              selectedTags={filters.selectedTags}
              show={rowData.length > 0}
              dataAlerts={DATA_ALERTS}
              rowData={rowData}
            >
              {enabled => (
                <li className="bottom-list float-end">
                  <SubmitButton
                    onClick={onRequestData}
                    enabled={rowData.length > 0 && filters.selectedTags.length > 0 && enabled}
                  />
                </li>
              )}
            </DataAlertContainer>
          </ul>
        </div>
      </div>
    </PageSidebarTemplate>
  );
}

Databrowser.propTypes = {
  filters: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  subtab: PropTypes.string,
  selectTagsAfterLoad: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  databrowserQueryData: PropTypes.any,
  sidebarFiltersFragmentData: PropTypes.any,
};

export default Databrowser;
