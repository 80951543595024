import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {Card, Row, Col, Button} from 'react-bootstrap';

import Link from '../../atoms/Link';
import ToolImage from '../ToolImage';

const toolFragment = graphql`
  fragment ToolCard_Fragment on ToolInterface {
    name
    subtitle
    slug
    ... on Tool {
      deleted
    }
    ...ToolImage_Fragment
  }
`;

function ToolCard({children, queryData}) {
  const tool = useFragment(toolFragment, queryData);

  return (
    <Card className="tool-card mb-4">
      <Row className="h-100 g-0">
        <Col xs={4} className="tool-icon-container">
          <ToolImage as={Card.Img} queryData={tool} background="bullet" />
        </Col>
        <Col xs={8}>
          <Card.Body>
            <Card.Title>{tool.name}</Card.Title>
            <Card.Text>
              <small>{tool.subtitle}</small>
            </Card.Text>
            <div className="tool-card-actions">
              <Button
                variant="primary"
                as={Link}
                to={`/workflows/${tool.slug}`}
                disabled={tool.deleted}
              >
                View
              </Button>
              {children}
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

ToolCard.propTypes = {
  queryData: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ToolCard;
