import React from 'react';
import PropTypes from 'prop-types';
import {ErrorComponent} from '@stjudecloud/ui-react';

// ErrorBoundary is meant as a top-level failsafe for the user. It's not a
// large component, but in the event that an error was otherwise not caught
// anywhere else, it will be caught here.
// TODO: In the future, we can grab the error to log, upload, etc.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  // getDerivedStateFromError accepts at least an `error` argument. It is not
  // used here so it is omitted. Invocation of this function implicity means an
  // error occurred.
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  // componentDidCatch(error, info) {
  //  // Do something with the error here. Log/upload/display, etc.
  // }

  render() {
    const {hasError} = this.state;
    const {children} = this.props;

    if (hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
