import React from 'react';
import PropTypes from 'prop-types';
import {useFragment} from 'react-relay';
import {graphql} from 'relay-runtime';

import SubsectionTitle from './SubsectionTitle';

const DisplayRow = ({title, detail}) => (
  <div className="my-3 d-flex justify-content-between">
    <div className="w-100">
      <strong>{title}</strong>
    </div>
    <div className="w-100">{detail}</div>
  </div>
);

const projectInfoFragment = graphql`
  fragment ProjectInfo_AddendumPage_Fragment on AccessRequest {
    projectName
    folderName
    viewOnly
  }
`;

const ProjectInfo = ({queryData}) => {
  const {projectName, folderName, viewOnly} = useFragment(projectInfoFragment, queryData);
  const projectType = viewOnly ? 'View Only Project' : 'Cloud Analysis Project';

  return (
    <div className="addendum-subsection">
      <SubsectionTitle title="Project Info" />
      <DisplayRow title="Project Name" detail={projectName} />
      <DisplayRow title="Request Name" detail={folderName} />
      <DisplayRow title="Project Type" detail={projectType} />
    </div>
  );
};

export default ProjectInfo;

ProjectInfo.propTypes = {
  queryData: PropTypes.object.isRequired,
};

DisplayRow.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
};
