import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip, Overlay} from 'react-bootstrap';

function CopyShareableURLButton({shareableURL}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef();

  const url = `${window.origin}${shareableURL}`;
  const onClick = event => {
    setShowTooltip(true);
    navigator.clipboard.writeText(url);
    event.preventDefault();
  };

  return (
    <>
      <Overlay
        placement="top"
        onEntered={() => setTimeout(() => setShowTooltip(false), 1000)}
        show={showTooltip}
        target={target.current}
      >
        <Tooltip id="databrowser-copy-shareable-url">Link copied to clipboard!</Tooltip>
      </Overlay>
      <div className="w-100 d-flex" id="share-selection-btn">
        <Button
          onClick={onClick}
          ref={target}
          href={url}
          variant="outline-primary"
          size="sm"
          className="mx-auto mt-4"
        >
          Share Selection <i className="fa fa-solid fa-link" />
        </Button>
      </div>
    </>
  );
}

CopyShareableURLButton.propTypes = {
  shareableURL: PropTypes.string,
};

export default CopyShareableURLButton;
