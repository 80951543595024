import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';

import {getIsUserLoggedIn} from '../../reducers/user/selectors';

export default function useLoginRedirect() {
  const location = useLocation();
  const isLoggedIn = useSelector(getIsUserLoggedIn);

  useEffect(() => {
    const {pathname, search, hash} = location;
    const cookies = new Cookies();

    if (pathname !== '/login' && !isLoggedIn) {
      cookies.set(
        'lastLocation',
        {pathname, search, hash},
        {path: '/', httpOnly: false, sameSite: 'lax'}
      );
    }
  }, [location, isLoggedIn]);

  return null;
}
