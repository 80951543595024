import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {keys, without} from 'ramda';
import {Breadcrumb} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';

import Link from '../../atoms/Link';
import BreadcrumbsContext from '../../atoms/BreadcrumbsContext';
import {filterAttributeProps} from '../../../utils';

const PageContent = props => {
  const {breadcrumbs} = useContext(BreadcrumbsContext);
  const {pathname} = useLocation();
  const {as, className, children} = props;

  const topClassNames = classnames(['content', className]);
  const elementProperties = without('as', keys(props));
  const passedProps = filterAttributeProps(elementProperties)(props);

  const element = as || 'div';

  let breadcrumbsDisplay = null;
  if (breadcrumbs.length > 0) {
    breadcrumbsDisplay = (
      <Breadcrumb>
        {breadcrumbs.map(({link, text}) => (
          <Breadcrumb.Item
            key={text}
            active={link === pathname}
            linkProps={{to: link}}
            linkAs={Link}
          >
            {text}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }

  return React.createElement(
    element,
    {
      className: topClassNames,
      ...passedProps,
    },
    <>
      {breadcrumbsDisplay}
      {children}
    </>
  );
};

PageContent.propTypes = {
  children: PropTypes.any,
  as: PropTypes.elementType,
  className: PropTypes.string,
};

export default PageContent;
