const localeData = {
  dataButton: 'Data',
  toolButton: 'Tools',
  exploreButton: 'Explore',
  userButton: 'User',
  adminButton: 'Admin',
  byDiseaseButton: 'Data By Disease',
  methodDatasetButton: 'Method Development Datasets',
  publicationButton: 'Data By Publication',
  login: 'Sign In',
  loginEmployee: 'Log In as St. Jude employee',
  register: 'Create Account',
};

export default localeData;
