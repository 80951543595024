/**
 * @generated SignedSource<<1abf457af6d0b535e3c70d947a9a3d36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "summaryFilters"
    },
    {
      "kind": "RootArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./DatabrowserSummaryRefetchQuery.graphql')
    }
  },
  "name": "SummaryTable_Summary_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Databrowser",
      "kind": "LinkedField",
      "name": "databrowser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "summaryFilters"
            },
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "type"
            }
          ],
          "concreteType": "DatabrowserSelectionSummary",
          "kind": "LinkedField",
          "name": "selectionSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalFileSize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalFileCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSampleCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extraData",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

node.hash = "188c383a394d78053bc011bc0ad318b8";

module.exports = node;
