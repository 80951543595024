/**
 * @generated SignedSource<<b04fe7e50643a2020c7cd8c41333d9e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Envelope",
  "kind": "LinkedField",
  "name": "envelope",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Applicant",
      "kind": "LinkedField",
      "name": "applicants",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signingStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessRequestPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccessRequest",
        "kind": "LinkedField",
        "name": "accessRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequestForm",
            "kind": "LinkedField",
            "name": "form",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessRequestPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccessRequest",
        "kind": "LinkedField",
        "name": "accessRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessRequestForm",
            "kind": "LinkedField",
            "name": "form",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69660a08d716208f8d9833606deb91f6",
    "id": null,
    "metadata": {},
    "name": "AccessRequestPageQuery",
    "operationKind": "query",
    "text": "query AccessRequestPageQuery(\n  $id: ID!\n) {\n  accessRequest(id: $id) {\n    name\n    form {\n      envelope {\n        applicants {\n          name\n          email\n          signingStatus\n          type\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "2943f31cc1944bd51af544b88519aa17";

module.exports = node;
