import React from 'react';
import {useSelector} from 'react-redux';
import {pathOr} from 'ramda';

import FlashAlert from '../../atoms/FlashAlert';

function FlashAlerts() {
  const flashAlerts = useSelector(state => pathOr([], ['flashAlerts'], state));

  return flashAlerts.map(flashAlert => (
    <FlashAlert
      variant={flashAlert.variant}
      key={flashAlert.key}
      alertKey={flashAlert.key}
      dismissable={flashAlert.dismissable}
      onClose={flashAlert.onClose}
    >
      {flashAlert.content}
    </FlashAlert>
  ));
}

export default FlashAlerts;
