import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';

import {
  userAuth0OmniauthAuthorizePath,
  userDNAnexusOmniauthAuthorizePath,
  usersAuthDNAnexusRegisterPath,
} from '../../../api/routes';

import Link from '../../atoms/Link';
import PageTemplate from '../../templates/PageTemplate';
import PageContent from '../../templates/PageContent';
import LoginCard from '../../molecules/LoginCard';
import {getIsUserLoggedIn} from '../../../reducers/user/selectors';

import DxLogo from '../../../../images/dx-logo.png';
import SJRedLogo from '../../../../images/st-jude-red-logo.png';

export function LoginPage() {
  const isLoggedIn = useSelector(getIsUserLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/', {replace: true});
    }
  });

  const metaData = {
    htmlTitle: 'Log In',
    metaDescription: 'Log in to St. Jude Cloud.',
    metaKeywords: ['login', 'log in'],
  };

  return (
    <PageTemplate {...metaData}>
      <PageContent as={Container} className="login-page">
        <h1>Log In to St. Jude Cloud</h1>
        <p>
          <br />
          St. Jude Cloud data is vended through the DNAnexus interface.
          <br />
          DNAnexus Login (Left) – use your DNAnexus account info to login if you only need to access
          data through DNAnexus.
          <br />
          St. Jude Cloud Login (Right, preferred method) – use your email, Google, Microsoft, or
          Github accounts along with your DNAnexus account.
          <br />
          <br />
          Note: If you are using the data download feature from the survivorship portal, you must
          use one of the 2FA options in the St. Jude Cloud account login.
        </p>

        <Row className="row_login-cards">
          <LoginCard
            image={DxLogo}
            title="Log In or Register with a DNAnexus Account"
            loginUrl={userDNAnexusOmniauthAuthorizePath()}
            registerUrl={usersAuthDNAnexusRegisterPath()}
          />
          <LoginCard
            image={SJRedLogo}
            title="Log In with a St. Jude Account"
            subtext="If you have not registered for a DNAnexus account, one will be created for you."
            loginUrl={userAuth0OmniauthAuthorizePath()}
          />
        </Row>
        <div className="login-help-text">
          Not able to log in?{' '}
          <Link href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/" newTab>
            Contact us.
          </Link>
        </div>
      </PageContent>
    </PageTemplate>
  );
}

export default LoginPage;
