import {useEffect} from 'react';
import {useLocation} from 'react-router';

export default function useIntercomUpdates() {
  const {pathname} = useLocation();

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {lastRequestAt: parseInt(new Date().getTime() / 1000, 10)});
    }
  }, [pathname]);

  return null;
}
