import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {keys} from 'ramda';
import classnames from 'classnames';

import {filterAttributeProps} from '../../../utils';

/* eslint-disable react/no-unused-prop-types */
const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export function Link(props) {
  const {className, disabled, to, children} = props;
  const topClassNames = classnames('link', className, {disabled});
  const passedProps = filterAttributeProps(keys(propTypes))(props);
  const component = to ? RouterLink : 'a';

  if (passedProps.newTab) {
    passedProps.target = '_blank';
  }
  delete passedProps.newTab;

  // Security safegaurd for new tabs. See https://mathiasbynens.github.io/rel-noopener/
  if (passedProps.target === '_blank' && !passedProps.rel) {
    passedProps.rel = 'noopener noreferrer';
  }

  return React.createElement(
    component,
    {
      className: topClassNames,
      ...passedProps,
    },
    children
  );
}

Link.propTypes = propTypes;

export default Link;
