import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {useSelector} from 'react-redux';

import {getIsUserLoggedIn} from '../../../../reducers/user/selectors';

import UserLoggedInButtons from './UserLoggedInButtons';
import UserNotLoggedInButtons from './UserNotLoggedInButtons';
import ToolError from './ToolError';

const toolFragment = graphql`
  fragment ToolButtons_Fragment on ToolInterface {
    name
    subtitle
    slug
    __typename
    ... on Tool {
      toolProject {
        ... on Error {
          type
          message
        }
      }
    }
    ...UserLoggedInButtons_Fragment
    ...UserNotLoggedInButtons_Fragment
  }
`;

function ToolButtons(props) {
  const {queryData} = props;
  const tool = useFragment(toolFragment, queryData);
  const isLoggedIn = useSelector(getIsUserLoggedIn);

  if (tool.toolProject?.type && tool.toolProject?.message) {
    return <ToolError error={tool.toolProject} />;
  }

  const Buttons = isLoggedIn ? UserLoggedInButtons : UserNotLoggedInButtons;

  return (
    <div className="tool-buttons">
      {/* eslint-disable-next-line no-underscore-dangle */}
      {!tool.__typename !== 'ToolDraft' && <Buttons {...props} queryData={tool} />}
    </div>
  );
}

ToolButtons.propTypes = {
  queryData: PropTypes.object.isRequired,
};

export default ToolButtons;
