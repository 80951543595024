import {useEffect, useCallback, useRef} from 'react';

/**
 * useIsMounted returns a callback that can be used to check if the calling
 * component is mounted. This is useful for deciding whether or not to allow
 * asynchronous tasks to complete execution or not.
 */
export default function useIsMounted() {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useCallback(() => mountedRef.current, []);
}
