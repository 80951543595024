/**
 * @generated SignedSource<<417b5a778ebea989788dedd0fc04e3d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dxid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserLoggedInButtons_startTool_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartToolMutationPayload",
        "kind": "LinkedField",
        "name": "startTool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToolProject",
            "kind": "LinkedField",
            "name": "toolProject",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserLoggedInButtons_startTool_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartToolMutationPayload",
        "kind": "LinkedField",
        "name": "startTool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToolProject",
            "kind": "LinkedField",
            "name": "toolProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4d2b608312d954b15b0876d3d1c81da",
    "id": null,
    "metadata": {},
    "name": "UserLoggedInButtons_startTool_Mutation",
    "operationKind": "mutation",
    "text": "mutation UserLoggedInButtons_startTool_Mutation(\n  $input: StartToolMutationInput!\n) {\n  startTool(input: $input) {\n    toolProject {\n      dxid\n      id\n    }\n    error {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b5e5a283d64fbb4a4a2bb191e272668f";

module.exports = node;
