import React from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import classnames from 'classnames';
import {Button} from 'react-bootstrap';

export const FileInfo = ({fileInfo, onHide}) => (
  <div className="text-center w-100">
    {fileInfo ? (
      <div className="d-flex">
        <span className="my-auto mx-3 fs-3">
          <i className="fas fa-file-pdf" />
        </span>
        <span className="text-break my-auto text-start">{fileInfo.name}</span>
        <Button
          className="my-auto ms-auto fs-5 text-danger"
          variant="link"
          onClick={e => {
            e.stopPropagation();
            onHide();
          }}
        >
          <i className="fas fa-trash-alt" />
        </Button>
      </div>
    ) : (
      <>
        <span className="upload-icon">
          <i className="fas fa-file-upload" />
        </span>
        <div>
          <strong>
            <span className="link-like">Click to upload</span> or drag and drop the signed request{' '}
          </strong>
        </div>
        <span className="fw-light">Accepted file formats: PDF, JPG, PNG</span>
      </>
    )}
  </div>
);

FileInfo.propTypes = {
  fileInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
};

export default function DropzoneWrapper({fileInfo, onDrop, onHide, accept, multiple, children}) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const multiUpload = multiple === undefined ? true : multiple;
  const formUploadClasses = ['dropzone-form-upload'];
  if (isDragActive) {
    formUploadClasses.push('dropzone-form-upload--active');
  }

  return (
    <div className={classnames(formUploadClasses)} {...getRootProps()}>
      <input
        data-test="dropzone-input"
        {...getInputProps()}
        accept={accept}
        multiple={multiUpload}
      />
      {(children && children) || <FileInfo fileInfo={fileInfo} onHide={onHide} />}
    </div>
  );
}

DropzoneWrapper.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  accept: PropTypes.object,
  multiple: PropTypes.bool,
  fileInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  children: PropTypes.element,
};
