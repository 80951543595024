import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {clearAllFlashAlerts} from '../../actions';

export default function useClearFlashes() {
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAllFlashAlerts());
  }, [pathname, dispatch]);

  return null;
}
