import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../atoms/Link';
import StickyTooltip from '../../atoms/StickyTooltip';

import {titleizeBadge} from './util';
import BADGE_DATA from './badgeData';

function Badge({type, active}) {
  const badgeText = BADGE_DATA[type] ? BADGE_DATA[type].badgeText : titleizeBadge(type);
  const description = BADGE_DATA[type] ? BADGE_DATA[type].description : 'Unknown';
  const helpUrl = BADGE_DATA[type] ? BADGE_DATA[type].helpUrl : '';
  const hasHelp = helpUrl && helpUrl !== '';
  const linkGlyph = hasHelp ? <span className="fa fa-external-link-alt" /> : null;
  const badgeclass = active ? 'type-badge-active' : 'type-badge';

  return (
    <StickyTooltip
      placement="top"
      component={
        <Link
          target="_blank"
          href={hasHelp ? helpUrl : null}
          className="data-browser-grid__tooltip"
        >
          {description} {linkGlyph}
        </Link>
      }
    >
      <span className={badgeclass}>{badgeText}</span>
    </StickyTooltip>
  );
}

Badge.propTypes = {
  type: PropTypes.oneOf(Object.keys(BADGE_DATA)),
  active: PropTypes.bool,
};

export default Badge;
