import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import CloudSpinner from '../../atoms/CloudSpinner';

const specialLoadingTextForTab = newTab => {
  let text = 'Loading ';
  switch (newTab) {
    case 'tumor':
      text += 'tumor files';
      break;
    case 'germline':
      text += 'germline files';
      break;
    case 'paired-tumor-normal':
      text += 'paired tumor-normal files';
      break;
    case 'cohorts':
      text += 'studies';
      break;
    default:
      text += newTab;
  }

  return `${text} may take a few moments... Please wait...`;
};

function LoadingOverlay({tab, isLongLoading}) {
  const [text, setText] = useState(
    isLongLoading ? specialLoadingTextForTab(tab) : 'Setting things up...'
  );
  const timers = useRef([]);

  useEffect(() => {
    setText(specialLoadingTextForTab(tab));
    timers.current.forEach(timer => clearTimeout(timer));
  }, [tab]);

  useEffect(() => {
    if (isLongLoading) return;

    timers.current.push(
      setTimeout(() => {
        setText('Loading Data...');
      }, 2500)
    );

    timers.current.push(
      setTimeout(() => {
        setText(specialLoadingTextForTab(tab));
      }, 5500)
    );

    // eslint-disable-next-line consistent-return, react-hooks/exhaustive-deps
    return () => timers.current.forEach(timer => clearTimeout(timer));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CloudSpinner />
      {text}
    </div>
  );
}

LoadingOverlay.propTypes = {
  tab: PropTypes.string,
  isLongLoading: PropTypes.bool,
};

export default LoadingOverlay;
