import {pick, without, keys} from 'ramda';

export function filterAttributeProps(propKeys) {
  return pick(without(['children', 'className'], propKeys));
}

export function createReducer(initialState, handlers = {}) {
  // eslint-disable-next-line default-param-last
  return function callHandler(state = initialState, action) {
    return handlers[action.type] ? handlers[action.type](state, action) : state;
  };
}

export const debounce = function (f, delay) {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => f(...args), delay);
  };
};

export const capitalize = function (string) {
  return (string || '').replace(/\b\w/g, l => l.toUpperCase());
};

export const generateId = () => Math.random().toString(36).substr(2);

export const createAction = (type, payload = null) => {
  const action = {
    type,
  };

  if (payload) {
    action.payload = payload;
  }

  return action;
};

export function accessRequestStatusToColor(status) {
  switch (status) {
    case 'new':
      return 'warning';
    case 'success':
      return 'success';
    case 'crashed':
      return 'danger';
    case 'waiting':
      return 'success';
    case 'processing':
    case 'pending renewal':
    default:
      return 'warning';
  }
}

export function accessRequestApprovalStatusToColor(status) {
  switch (status) {
    case 'waiting_on_signatures':
      return 'primary';
    case 'approved':
    case 'pre_approved':
      return 'success';
    case 'rejected':
    case 'expired':
    case 'declined_to_sign':
      return 'error';
    case 'expiring':
      return 'danger';
    case 'pending':
    default:
      return 'warning';
  }
}

export function objectWithoutKeys(obj, keysToRemove) {
  if (obj === null) return null;

  let ensuredArrOfKeysToRemove = keysToRemove;
  if (typeof keysToRemove === 'string') {
    ensuredArrOfKeysToRemove = [keysToRemove];
  }

  return pick(without(ensuredArrOfKeysToRemove, keys(obj)), obj);
}
