import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import parseFormFieldName from './formUtils';

function RequestForm({
  user,
  setUserField,
  projectName,
  setProjectName,
  folderName,
  setFolderName,
  wantsToVendToExistingProject,
}) {
  const onUserChange = event => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const [field] = parseFormFieldName(fieldName);
    setUserField(field, fieldValue);
  };

  return (
    <div>
      <div className="wizard-header center">Name your request.</div>
      <div className="wizard-sub-header">
        This is the name of the secure project and folder that your data will be vended to.
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <Form.Group controlId="request_project_name">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            type="text"
            name="request[projectName]"
            value={projectName}
            required
            disabled={wantsToVendToExistingProject}
            onChange={e => setProjectName(e.target.value)}
            data-testid="text-edaa-project-name"
          />
          <Form.Control.Feedback type="invalid">Project name is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="request_folder_name">
          <Form.Label>Folder Name</Form.Label>
          <Form.Control
            type="text"
            name="request[folderName]"
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
            data-testid="text-edaa-folder-name"
          />
        </Form.Group>
        <Form.Group controlId="user_name">
          <Form.Label>Your Full Name</Form.Label>
          <Form.Control
            type="text"
            name="user[name]"
            value={user.fullName}
            required
            onChange={onUserChange}
            data-testid="text-edaa-user-name"
          />
          <Form.Control.Feedback type="invalid">Full name is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="user_institution">
          <Form.Label>Institution</Form.Label>
          <Form.Control
            type="text"
            name="user[institution]"
            value={user.institution}
            required
            onChange={onUserChange}
          />
          <Form.Control.Feedback type="invalid">Institution is required.</Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

RequestForm.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    institution: PropTypes.string,
  }),
  setUserField: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  setProjectName: PropTypes.func.isRequired,
  folderName: PropTypes.string,
  setFolderName: PropTypes.func.isRequired,
  wantsToVendToExistingProject: PropTypes.bool,
};

export default RequestForm;
