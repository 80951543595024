import API from '../../../api';
import {usersAuthLogoutPath} from '../../../api/routes';
import C from '../../../constants';

export function beginLogout() {
  return {
    type: C.LOGOUT_STARTED,
  };
}

export function logoutSucceeded(response) {
  return {
    type: C.LOGOUT_SUCCEEDED,
    value: response,
  };
}

export function logoutFailed() {
  return {
    type: C.LOGOUT_FAILED,
  };
}

export function logout(path) {
  return dispatch => {
    dispatch(beginLogout());

    return API.fetchJSON(usersAuthLogoutPath(), {}, {method: 'POST'})
      .then(response => {
        dispatch(logoutSucceeded(response));
        window.location = path;
      })
      .catch(() => dispatch(logoutFailed()));
  };
}
