import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {ErrorComponent} from '@stjudecloud/ui-react';
import {graphql, useMutation, useLazyLoadQuery} from 'react-relay';
import {isEmpty} from 'ramda';
import {useDispatch} from 'react-redux';

import {pushFlashAlert} from '../../../actions';
import {objectWithoutKeys} from '../../../utils';

import DatabrowserFiltersContext from '../../atoms/DatabrowserFiltersContext';
import Link from '../../atoms/Link';
import withUserAuthentication from '../../auth/WithUserAuthentication';
import PageTemplate from '../../templates/PageTemplate';
import PageSidebarTemplate from '../../templates/PageSidebarTemplate';
import PageFullContentTemplate from '../../templates/PageFullContentTemplate';
import {useIsMounted, useHasChanged} from '../../hooks';

import RequestForm from './steps/RequestForm';
import AccessForm from './steps/AccessForm';
import ViewOnlyForm from './steps/ViewOnlyForm';
import NewOrExistingProjectForm from './steps/NewOrExistingProjectForm';
import ProjectForm from './steps/ProjectForm';
import UserForm from './steps/UserForm';
import ApplicationTypeForm from './steps/ApplicationTypeForm';
import PIForm from './steps/PIForm';
import AAForm from './steps/AAForm';
import IAForm from './steps/IAForm';
import ITDForm from './steps/ITDForm';
import ContemplateUseForm from './steps/ContemplatedUseForm';
import SummaryPage from './steps/SummaryPage/SummaryPage';
import ManualApplicationForm from './steps/ManualApplicationForm';
import WizardError from './steps/WizardError';
import SuccessPage from './steps/SuccessPage';
import SideNav from './SideNav';
import WizardForm from './steps/WizardForm';
import WizardDocumentationButton from './WizardDocumentationButton';
import NavigationButtons from './NavigationButtons';
import EDAA_COUNTRIES from './edaaCountries';

const FULL_CONTENT_PAGES = ['/requests/request_details', '/requests/success', '/requests/error'];

const accessRequestSubmissionWizardQuery = graphql`
  query AccessRequestSubmissionWizard_index_Query($isNewDraft: Boolean!) {
    user {
      id
      fullName
      jobTitle
      email
      institution
      country
      emailReminders
      wantsEsign
      isStjude
      billingSetup
      accessRequests(parentRequestsOnly: true) {
        projectName
        projectDxid
        viewOnly
      }
      ... @skip(if: $isNewDraft) {
        accessRequestDraft {
          id
          iid
          requestType
          projectName
          folderName
          lastUncompletedStep
          isPi
          wantsToDownload
          contemplatedUse
          requiredAccessUnits
          projectDxid
          viewOnly
          principalInvestigator {
            name
            email
          }
          additionalApplicants {
            name
            email
          }
          institutionalAuthority {
            name
            email
          }
          itDirector {
            name
            email
          }
          primaryApplicant {
            name
            email
          }
        }
      }
    }
    accessUnits {
      name
      description
      unrestricted
    }
  }
`;

const upsertDraftMutation = graphql`
  mutation AccessRequestSubmissionWizard_UpsertDraft_Mutation(
    $draftInput: UpsertAccessRequestDraftMutationInput!
    $userInput: UpdateUserMutationInput!
    $requestRequiredAccessUnits: Boolean!
  ) {
    upsertAccessRequestDraft(input: $draftInput) {
      accessRequestDraft {
        id
        iid
        ... @include(if: $requestRequiredAccessUnits) {
          requiredAccessUnits
        }
      }
      error {
        type
        message
      }
    }
    updateUser(input: $userInput) {
      error {
        type
        message
      }
    }
  }
`;

const submitDraftMutation = graphql`
  mutation AccessRequestSubmissionWizard_SubmitDraft_Mutation(
    $input: SubmitAccessRequestDraftMutationInput!
    $userInput: UpdateUserMutationInput!
  ) {
    submitAccessRequestDraft(input: $input) {
      accessRequest {
        projectDxid
      }
      error {
        type
        message
      }
    }
    updateUser(input: $userInput) {
      error {
        type
        message
      }
    }
  }
`;

export function AccessRequestSubmissionWizard() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isMounted = useIsMounted();
  const form = useRef();
  const scrollTarget = useRef();
  const dispatch = useDispatch();

  const databrowser = useContext(DatabrowserFiltersContext);
  const [isNewDraft] = useState(databrowser.hasFilters());

  const {accessUnits, user: fetchedUser} = useLazyLoadQuery(
    accessRequestSubmissionWizardQuery,
    {
      isNewDraft,
    },
    {fetchPolicy: 'network-only'}
  );

  const [commitUpsertDraft, draftIsUpserting] = useMutation(upsertDraftMutation);
  const [commitDraftSubmission, draftIsSubmitting] = useMutation(submitDraftMutation);
  const [submitError, setSubmitError] = useState(null);
  const resetSubmitError = useCallback(() => setSubmitError(null), [setSubmitError]);

  const [requiredAccessUnits, setRequiredAccessUnits] = useState([]);
  const [requestRequiredAccessUnits, setRequestRequiredAccessUnits] = useState(isNewDraft);
  const [formValidated, setFormValidated] = useState(false);
  const [{fn: additionalApplicantCallback}, setAdditionalApplicantCallback] = useState({fn: null});
  const needsToRequestAccess = !isEmpty(requiredAccessUnits);

  const [upsertSuccessful, setUpsertSuccessful] = useState(null);

  // The component suspends while the lazyLoadQuery is resolving, so
  // initializing state with the `fetchedUser` is ok.
  const [user, setUser] = useState(objectWithoutKeys(fetchedUser, ['id', 'accessRequestDraft']));

  const [draft, setDraft] = useState({
    id: undefined,
    iid: undefined,
    filters: {...databrowser.filters, requestType: databrowser.requestType},
    requestType: undefined,
    projectName: '',
    // match backend default for date formatting
    folderName: `Data Requested ${new Date()
      .toLocaleString('en-za', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-')}`,
    viewOnly: !fetchedUser.billingSetup,
    lastUncompletedStep: '/requests/new_or_existing',
    isPi: undefined,
    principalInvestigator: {
      name: '',
      email: '',
    },
    additionalApplicants: [],
    institutionalAuthority: {
      name: '',
      email: '',
    },
    wantsToDownload: undefined,
    itDirector: {
      name: '',
      email: '',
    },
    contemplatedUse: '',
    projectDxid: null,
    primaryApplicant: {
      name: '',
      email: '',
    },
  });

  const [wantsToVendToExistingProject, setWantsToVendToExistingProjectState] = useState(
    !isEmpty(fetchedUser.accessRequests.length) && !!draft.projectDxid
  );

  const hasAccessRequests = !isEmpty(fetchedUser.accessRequests);
  const canChooseProjectType =
    !hasAccessRequests || (hasAccessRequests && !wantsToVendToExistingProject);

  const draftChanged = useHasChanged(draft);
  const pathnameChanged = useHasChanged(pathname);

  const upsertDraft = () => {
    const variables = {
      requestRequiredAccessUnits,
      draftInput: {
        accessRequestDraftId: draft.id,
        accessRequestDraftInput: objectWithoutKeys(draft, ['id', 'iid']),
      },
      userInput: {
        userInput: objectWithoutKeys(user, [
          'accessRequestDraft',
          'isStjude',
          'accessRequests',
          'billingSetup',
        ]),
      },
    };

    function updater(store, response) {
      if (response.upsertAccessRequestDraft.error) return;

      const draftResult = store
        .getRootField('upsertAccessRequestDraft')
        .getLinkedRecord('accessRequestDraft');

      const relayUserObj = store.get(fetchedUser.id);
      relayUserObj.setValue(true, 'hasActiveDraft');
      relayUserObj.setLinkedRecord(draftResult, 'accessRequestDraft');
    }

    function onCompleted(response) {
      if (!isMounted) return;

      let errored = false;
      const {accessRequestDraft, error: draftError} = response.upsertAccessRequestDraft;
      if (draftError) {
        errored = true;
        if (isNewDraft) {
          navigate('/requests/error');
        } else {
          dispatch(
            pushFlashAlert({
              variant: 'danger',
              content: (
                <>
                  Failed to save your access request! Please try again or contact support at{' '}
                  <Link href="mailto:support@stjude.cloud">support@stjude.cloud</Link> if the
                  problem persists.
                </>
              ),
            })
          );
        }
      } else {
        if (requestRequiredAccessUnits) {
          // Order is important. Setting draft first causes useEffect to
          // trigger before requiredAccessUnits was set.
          setRequiredAccessUnits(accessRequestDraft.requiredAccessUnits);
          setRequestRequiredAccessUnits(false);
        }

        setDraft(prevState => ({
          ...prevState,
          id: accessRequestDraft.id,
          iid: accessRequestDraft.iid,
        }));
      }

      if (response.updateUser?.error) {
        errored = true;
        dispatch(
          pushFlashAlert({
            variant: 'danger',
            content: (
              <>
                Failed to update your personal information! Please try again or contact support at{' '}
                <Link href="mailto:support@stjude.cloud">support@stjude.cloud</Link> if the problem
                persists.
              </>
            ),
          })
        );
      }

      setUpsertSuccessful(!errored);
    }

    setUpsertSuccessful(false);
    commitUpsertDraft({variables, updater, onCompleted});
  };

  const submitDraft = () => {
    const variables = {
      input: {
        accessRequestDraftId: draft.id,
        projectName: draft.projectName,
        folderName: draft.folderName,
      },
      userInput: {
        userInput: objectWithoutKeys(user, [
          'accessRequestDraft',
          'isStjude',
          'accessRequests',
          'billingSetup',
        ]),
      },
    };

    function updater(store, response) {
      if (response.submitAccessRequestDraft.error) return;

      const relayUserObj = store.get(fetchedUser.id);
      relayUserObj.setValue(false, 'hasActiveDraft');
      relayUserObj.getLinkedRecord('accessRequestDraft').invalidateRecord();
    }

    function onCompleted(response) {
      if (response.submitAccessRequestDraft.error) {
        setSubmitError(response.submitAccessRequestDraft.error.message);
      } else {
        navigate('/requests/success', {replace: true});
      }

      if (response.updateUser.error) {
        dispatch(
          pushFlashAlert({
            variant: 'danger',
            content: 'Failed to update your personal information!',
          })
        );
      }
    }

    commitDraftSubmission({variables, updater, onCompleted});
  };

  const validateFormAndProceed = nextPage => {
    let dropdownValid;
    switch (pathname) {
      case '/requests/user':
        dropdownValid = user.country && user.country !== '';
        break;
      case '/requests/new_or_existing':
        dropdownValid =
          !wantsToVendToExistingProject ||
          (wantsToVendToExistingProject && draft.projectDxid && draft.projectDxid !== '');
        break;
      default:
        dropdownValid = true;
    }

    if (form.current.checkValidity() && dropdownValid) {
      setFormValidated(false);
      setDraft(prevState => ({...prevState, lastUncompletedStep: nextPage}));
      navigate(nextPage);
    } else {
      setFormValidated(true);
    }
  };

  const next = nextPage => {
    switch (pathname) {
      case '/requests':
      case '/requests/':
        break;
      case '/requests/manual':
      case '/requests/summary':
      case '/requests/request_details':
        submitDraft();
        break;
      default:
        validateFormAndProceed(nextPage);
    }
  };

  const prev = prevPage => {
    setFormValidated(false);
    setDraft(prevState => ({...prevState, lastUncompletedStep: prevPage}));
    navigate(prevPage);
  };

  const setWantsToVendToExistingProject = () => {
    setWantsToVendToExistingProjectState(true);
    setDraft(prevState => ({...prevState, viewOnly: false}));
  };

  const setDoesNotWantToVendToExistingProject = () => {
    setDraft(prevState => ({...prevState, projectDxid: null, viewOnly: !fetchedUser.billingSetup}));
    setWantsToVendToExistingProjectState(false);
    next('/requests/project_type');
  };

  const nextAfterProjectType = () => {
    if (needsToRequestAccess) {
      next('/requests/project');
    } else {
      next('/requests/request_details');
    }
  };

  const setVendToProject = project =>
    setDraft(prevState => ({
      ...prevState,
      projectDxid: project.projectDxid,
      projectName: project.projectName,
      viewOnly: project.viewOnly,
    }));

  const setViewOnly = viewOnly => {
    setDraft(prevState => ({...prevState, viewOnly}));
  };

  const onSelectViewOnly = () => {
    setViewOnly(true);
    nextAfterProjectType();
  };

  const onSelectCloudAnalysis = () => {
    setViewOnly(false);
    nextAfterProjectType();
  };

  const setProjectName = projectName => setDraft(prevState => ({...prevState, projectName}));

  const setFolderName = folderName => setDraft(prevState => ({...prevState, folderName}));

  const setUserField = (field, value) => {
    let type;
    if (field !== 'country') {
      type = draft.requestType;
    } else if (!EDAA_COUNTRIES.includes(value)) {
      type = 'manual';
    } else {
      type = null;
    }

    const newUserValues = {[field]: value};
    if (field === 'email') {
      if (value.toLowerCase().endsWith('@stjude.org')) {
        newUserValues.isStjude = true;
      } else {
        newUserValues.isStjude = false;
      }
    }

    setDraft(prevState => ({...prevState, requestType: type}));
    setUser(prevState => ({...prevState, ...newUserValues}));
  };

  const setDraftType = wantsEsign => {
    const requestType =
      wantsEsign && EDAA_COUNTRIES.includes(user.country) ? 'electronic' : 'manual';

    setDraft(prevState => ({...prevState, requestType}));
    setUser(prevState => ({...prevState, wantsEsign}));
    next('/requests/summary');
  };

  const setPIField = (field, value) =>
    setDraft(prevState => ({
      ...prevState,
      principalInvestigator: {...prevState.principalInvestigator, [field]: value},
    }));

  const setIsPi = isPi => {
    const newState = {isPi};

    // Reset principalInvestigator to empty values if the user changes if they are the principalInvestigator or not
    // if principalInvestigator, then will look to Primary Applicant info for name and email
    // if not, setPIField will update the name and email info
    newState.principalInvestigator = {name: '', email: ''};

    setDraft(prevState => ({...prevState, ...newState}));
    if (isPi) {
      navigate('/requests/signing_official');
    }
  };

  const setApplicantField = (index, field, value) => {
    setDraft(prevState => {
      const applicants = [...prevState.additionalApplicants];
      applicants[index][field] = value;
      return {...prevState, additionalApplicants: applicants};
    });
  };

  const addNewApplicant = callback => {
    setDraft(prevState => ({
      ...prevState,
      additionalApplicants: [...prevState.additionalApplicants, {name: '', email: ''}],
    }));

    // We need the callback to trigger *after* the additionalApplicant is added
    // to state and the page is rerendered. To do so, we temporarily save the
    // callback and use a useEffect to run it after the additionalApplicant has
    // been added.
    if (callback) setAdditionalApplicantCallback({fn: callback});
  };

  const removeApplicant = index => {
    setDraft(prevState => {
      const applicants = [...prevState.additionalApplicants];
      applicants.splice(index, 1);
      return {...prevState, additionalApplicants: applicants};
    });
  };

  const setNoApplicants = () => {
    setDraft(prevState => ({...prevState, additionalApplicants: []}));
    navigate('/requests/contemplated_use');
  };

  const setIAField = (field, value) =>
    setDraft(prevState => ({
      ...prevState,
      institutionalAuthority: {...prevState.institutionalAuthority, [field]: value},
    }));

  const setITDField = (field, value) =>
    setDraft(prevState => ({...prevState, itDirector: {...prevState.itDirector, [field]: value}}));

  const setWantsToDownload = wantsToDownload => {
    const {isStjude} = user;
    const newState = {wantsToDownload};

    // Reset itDirector to empty values if the user is not intending to download
    if (!wantsToDownload) {
      newState.itDirector = {name: '', email: ''};
    }

    setDraft(prevState => ({...prevState, ...newState}));
    if (!wantsToDownload || (wantsToDownload && isStjude)) {
      navigate('/requests/additional_applicants');
    }
  };

  const setContemplatedUse = contemplatedUse =>
    setDraft(prevState => ({...prevState, contemplatedUse}));

  const {
    iid,
    projectName,
    folderName,
    requestType,
    isPi,
    principalInvestigator,
    additionalApplicants,
    institutionalAuthority,
    wantsToDownload,
    itDirector,
    contemplatedUse,
    lastUncompletedStep,
    projectDxid,
    viewOnly,
  } = draft;

  useEffect(() => {
    if (!isNewDraft) {
      if (fetchedUser?.accessRequestDraft) {
        setDraft(objectWithoutKeys(fetchedUser.accessRequestDraft, 'requiredAccessUnits'));
        setRequiredAccessUnits(fetchedUser.accessRequestDraft.requiredAccessUnits);
        setWantsToVendToExistingProjectState(
          !isEmpty(fetchedUser.accessRequests.length) &&
            !!fetchedUser.accessRequestDraft.projectDxid
        );
      } else {
        navigate('/requests/error');
      }
    }
  }, [fetchedUser, databrowser.filters, setDraft, setRequiredAccessUnits, navigate, isNewDraft]);

  useEffect(() => {
    if (!draft.id) return;

    let path = '/requests/new_or_existing';
    // i.e., does the user have access already?
    if (needsToRequestAccess) {
      if (!pathname.match(/^\/requests\/?$/)) {
        return;
      }
      path = lastUncompletedStep;
    }

    // Replace /requests and /requests/ pages from browser history
    if (pathname.match(/\/requests\/*/)) {
      navigate(path, {replace: true});
    } else {
      navigate(path);
    }
  }, [draft.id, needsToRequestAccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isNewDraft) {
      upsertDraft();
      databrowser.clearFilters();
    } else {
      // no-op: We'll sync this component's state to the user's most recent
      // draft once it has loaded.
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!draft.id || !additionalApplicantCallback) return;

    additionalApplicantCallback();
    setAdditionalApplicantCallback({});
  }, [draft.id, additionalApplicantCallback, setAdditionalApplicantCallback]);

  useEffect(() => {
    if (!draft.id) return;
    if (!pathnameChanged) return;
    setFormValidated(false);
    if (!draftChanged) return;

    switch (pathname) {
      case '/requests':
      case '/requests/':
        return;
    }

    upsertDraft();
  }, [draftChanged, pathnameChanged, draft.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      setDraft(prevState => ({
        ...prevState,
        primaryApplicant: {
          name: user.fullName,
          email: user.email,
        },
      }));
    }
  }, [user]);

  const metaData = {
    htmlTitle: 'Submit request',
    metaDescription: 'Submit your access request to St. Jude Cloud genomics data',
    metaKeywords: ['submit request', 'data request', 'access'],
  };

  const fullContentPage = FULL_CONTENT_PAGES.includes(pathname);

  let PageTemplateType;
  if (pathname === '/requests/request_details' && hasAccessRequests) {
    PageTemplateType = PageSidebarTemplate;
  } else {
    PageTemplateType = fullContentPage ? PageFullContentTemplate : PageSidebarTemplate;
  }

  return (
    <PageTemplate>
      <PageTemplateType
        className="wizard-page"
        sideNav={
          <SideNav
            userCanUseElectronic={EDAA_COUNTRIES.includes(user.country)}
            requestType={requestType}
            saveDraft={upsertDraft}
            draftIsUpserting={draftIsUpserting}
            upsertSuccessful={upsertSuccessful}
            hasAccessRequests={hasAccessRequests}
            needsToRequestAccess={needsToRequestAccess}
            canChooseProjectType={canChooseProjectType}
          />
        }
        {...metaData}
      >
        <WizardDocumentationButton fullContentPage={fullContentPage} />
        <div className="access-request-submission-wizard">
          <WizardForm ref={form} validated={formValidated}>
            <Routes>
              <Route
                path="new_or_existing"
                element={
                  <NewOrExistingProjectForm
                    accessRequests={fetchedUser.accessRequests}
                    projectDxid={projectDxid}
                    validated={formValidated}
                    wantsToVendToExistingProject={wantsToVendToExistingProject}
                    setWantsToVendToExistingProject={setWantsToVendToExistingProject}
                    setDoesNotWantToVendToExistingProject={setDoesNotWantToVendToExistingProject}
                    hasAccessRequests={hasAccessRequests}
                    setVendToProject={setVendToProject}
                  />
                }
              />

              <Route
                path="project_type"
                element={
                  <ViewOnlyForm
                    viewOnly={viewOnly}
                    onSelectViewOnly={onSelectViewOnly}
                    onSelectCloudAnalysis={onSelectCloudAnalysis}
                    canChooseProjectType={canChooseProjectType}
                    needsToRequestAccess={needsToRequestAccess}
                    billingSetup={user.billingSetup}
                  />
                }
              />
              <Route
                path="request_details"
                element={
                  <RequestForm
                    user={user}
                    setUserField={setUserField}
                    projectName={projectName}
                    setProjectName={setProjectName}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    wantsToVendToExistingProject={wantsToVendToExistingProject}
                  />
                }
              />
              <Route
                path="project"
                element={
                  <ProjectForm
                    setProjectName={setProjectName}
                    projectName={projectName}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    projectDxid={projectDxid}
                    wantsToVendToExistingProject={wantsToVendToExistingProject}
                  />
                }
              />
              <Route
                path="access"
                element={
                  <AccessForm requiredAccessUnits={requiredAccessUnits} accessUnits={accessUnits} />
                }
              />
              <Route
                path="user"
                element={
                  <UserForm setUserField={setUserField} user={user} validated={formValidated} />
                }
              />
              <Route
                path="application_type"
                element={
                  <ApplicationTypeForm
                    setDraftType={setDraftType}
                    updateDraft={upsertDraft}
                    user={user}
                  />
                }
              />
              <Route
                path="manual"
                element={
                  <ManualApplicationForm
                    id={iid}
                    requiredAccessUnits={requiredAccessUnits}
                    accessUnits={accessUnits}
                    wantsEsign={user.wantsEsign}
                  />
                }
              />
              <Route
                path="pi"
                element={
                  <PIForm
                    principalInvestigator={principalInvestigator}
                    setPIField={setPIField}
                    isPi={isPi}
                    setIsPi={setIsPi}
                  />
                }
              />
              <Route
                path="signing_official"
                element={
                  <IAForm institutionalAuthority={institutionalAuthority} setIAField={setIAField} />
                }
              />
              <Route
                path="data_security"
                element={
                  <ITDForm
                    itDirector={itDirector}
                    setITDField={setITDField}
                    wantsToDownload={wantsToDownload}
                    setWantsToDownload={setWantsToDownload}
                    isStjude={user.isStjude}
                  />
                }
              />
              <Route
                path="additional_applicants"
                element={
                  <AAForm
                    additionalApplicants={additionalApplicants}
                    setApplicantField={setApplicantField}
                    setNoApplicants={setNoApplicants}
                    addNewApplicant={addNewApplicant}
                    removeApplicant={removeApplicant}
                    isPi={isPi}
                    scrollTarget={scrollTarget}
                  />
                }
              />
              <Route
                path="contemplated_use"
                element={
                  <ContemplateUseForm
                    contemplatedUse={contemplatedUse}
                    setContemplatedUse={setContemplatedUse}
                  />
                }
              />
              <Route
                path="summary"
                element={
                  <SummaryPage
                    submitDraft={submitDraft}
                    draftIsUpserting={draftIsUpserting}
                    upsertSuccessful={upsertSuccessful}
                    draftIsSubmitting={draftIsSubmitting}
                    submitError={submitError}
                    resetSubmitError={resetSubmitError}
                    saveDraft={upsertDraft}
                    user={user}
                    wantsToVendToExistingProject={wantsToVendToExistingProject}
                    {...draft}
                  />
                }
              />
              <Route path="success" element={<SuccessPage requestType={requestType} />} />
              <Route path="error" element={<WizardError />} />
              <Route path="" element={null} />
              <Route path="*" element={<ErrorComponent variant="notFound" />} />
            </Routes>

            <NavigationButtons
              pathname={pathname}
              user={user}
              isPi={isPi}
              hasAccessRequests={hasAccessRequests}
              needsToRequestAccess={needsToRequestAccess}
              hasAdditionalApplicants={additionalApplicants && additionalApplicants.length !== 0}
              wantsToVendToExistingProject={wantsToVendToExistingProject}
              canChooseProjectType={canChooseProjectType}
              wantsToDownload={wantsToDownload}
              isStjude={user.isStjude}
              onPrev={prev}
              onNext={next}
              submitError={pathname !== '/requests/summary' && submitError}
              resetSubmitError={resetSubmitError}
              ref={scrollTarget}
            />
          </WizardForm>
        </div>
      </PageTemplateType>
    </PageTemplate>
  );
}

export default withUserAuthentication(AccessRequestSubmissionWizard);
