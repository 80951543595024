import C from './constants';

export function receiveUser(user) {
  return {
    type: C.RECEIVE_USER,
    user,
  };
}

export function pushFlashAlert(flashAlert) {
  // Add a key to the flashAlert in case caller components need to manually
  // remove a flashAlert or need to reference a specific alert
  return {
    type: C.PUSH_FLASH_ALERT,
    flashAlert: {...flashAlert, key: Date.now()},
  };
}

export function clearFlashAlert(key) {
  return {
    type: C.CLEAR_FLASH_ALERT,
    key,
  };
}

export function clearAllFlashAlerts() {
  return {
    type: C.CLEAR_ALL_FLASH_ALERTS,
  };
}
