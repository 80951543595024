import React from 'react';
import Link from '../Link';

function TermsOfService() {
  return (
    <div className="terms">
      <p>
        Review and accept the St. Jude Cloud Terms of Use and Privacy Policy linked below to
        continue using St. Jude Cloud - Genomics Platform:
      </p>
      <ul>
        <li>
          <Link href="https://www.stjude.cloud/terms-of-use/">Terms of Use</Link>
        </li>
        <li>
          <Link href="https://www.stjude.cloud/privacy-policy/">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  );
}

export default TermsOfService;
