import {documentationPath} from '../../../api/routes';

const BADGE_DATA = {
  WGS: {badgeText: 'WGS', description: 'Whole Genome Sequencing', helpUrl: ''},
  WES: {badgeText: 'WES', description: 'Whole Exome Sequencing', helpUrl: ''},
  FASTQ: {
    badgeText: 'FASTQ',
    description: 'Raw read data in FastQ format',
    helpUrl: '',
  },
  CRAM: {badgeText: 'CRAM', description: 'HG38 aligned CRAM files', helpUrl: ''},
  'RNA-Seq': {badge: 'rnaseq', badgeText: 'RNA-Seq', description: 'RNA Sequencing', helpUrl: ''},
  BAM: {
    badgeText: 'BAM',
    description: 'HG38 aligned BAM files',
    helpUrl: documentationPath('/about-our-data/file-formats-and-sequencing/#bam-files'),
  },
  gVCF: {
    badgeText: 'gVCF',
    description: 'Genomic VCF files',
    helpUrl: documentationPath('/about-our-data/file-formats-and-sequencing/#gvcf-files'),
  },
  Somatic_VCF: {
    badgeText: 'Somatic VCF',
    description: 'Somatic variants',
    helpUrl: documentationPath('/about-our-data/file-formats-and-sequencing/#somatic-vcf-files'),
  },
  CNV: {
    badgeText: 'CNV',
    description: 'Copy number variants',
    helpUrl: documentationPath('/about-our-data/file-formats-and-sequencing/#cnv-files'),
  },
  FEATURE_COUNTS: {
    badgeText: 'Feature Counts',
    description: 'HTSeq feature counts',
    helpUrl: documentationPath('/about-our-data/file-formats-and-sequencing/#feature-counts-files'),
  },
  Multiple: {
    badgeText: 'Multiple',
    description: 'Multiple sequencing types',
    helpUrl: '',
  },
};

export default BADGE_DATA;
