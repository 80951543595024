import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import DocumentationButton from '../../atoms/DocumentationButton';

function PageSidebarTemplate({
  header,
  className,
  sideNav,
  documentationPath,
  children,
  subHeader,
  extra,
}) {
  useEffect(() => {
    // FIXME: Databrowser does not look great under bootstrap responsiveness,
    // so prevent the page from shrinking past a point.
    document.body.style.minWidth = '1000px';

    // Remove the minWidth styling when the component is unmounted.
    return () => {
      document.body.style.minWidth = '';
      delete document.body.style.minWidth;
    };
  }, []);

  let headerWrapper = null;

  if (header) {
    headerWrapper = <h1 className="right-bar-header">{header}</h1>;
  }

  let subHeaderWrapper = null;
  if (subHeader) {
    subHeaderWrapper = <h2 className="right-bar-subheader">{subHeader}</h2>;
  }

  let classNames = 'sidebar__wrapper';
  if (className !== undefined) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames}>
      <div className="sidebar__left-side">{sideNav}</div>
      <div className="sidebar__right-side">
        <div className="p-4">
          <div className="float-end">
            {extra}
            {documentationPath && <DocumentationButton path={documentationPath} />}
          </div>
          {headerWrapper}
          {subHeaderWrapper}
          {children}
        </div>
      </div>
    </div>
  );
}

PageSidebarTemplate.propTypes = {
  sideNav: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  subHeader: PropTypes.node,
  documentationPath: PropTypes.string,
  header: PropTypes.node,
  className: PropTypes.string,
  extra: PropTypes.node,
};

export default PageSidebarTemplate;
