const localeData = {
  dataButton: 'DATA',
  getData: 'Request Data',
  toolButton: 'TOOLS',
  exploreButton: 'Explore',
  userButton: 'User',
  adminButton: 'ADMIN',
  adminManageTools: 'Manage Tools',
  adminUpdateUnit: 'Update Access Unit',
  adminCreateUnit: 'Create Access Unit',
  adminManageUnit: 'Manage Access Units',
  byDiseaseButton: 'Data By Disease',
  methodDatasetButton: 'Method Development Datasets',
  publicationButton: 'Data By Publication',
  login: 'Log In',
  logout: 'Log Out',
  logoutMessage: 'Logging out...',
  help: 'Help',
  searchInputText: 'Filter',
  'tos.checkbox': 'I have read and accept the St. Jude Cloud Terms of Use and Privacy Policy',
  'tos.acceptButton': 'Accept',
  'tos.cancelButton': 'Cancel and log out',
  'dataNav.byDisease': 'Diagnosis',
  'dataNav.datasets': 'Datasets',
  'dataNav.publications': 'Publication',
  'dataNav.pecan': 'Using PeCan',
  requestDataButton: 'Request Data',
  'cohortNav.selectDisease': 'Select a Diagnosis',
  'cohortNav.selectPublication': 'Select a Publication',
  'cohortNav.selectCohort': 'Select a Dataset',
  'cohortNav.selectPeCan': 'Select from PeCan',
  'dataNav.selectFiles': 'Select Files',
  'dataNav.requestData': 'Request Data',
  'diseaseSummary.title': 'Disease Request Details',
  'diseaseSummary.summary':
    '{sampleText} {sampleCount, plural, one {file} other {total files}} ' +
    'from {patientText} {patientCount, plural, one {subject} other {subjects}} ' +
    'in {diagnosisText} {diagnosisCount, plural, one {diagnosis} other {different diagnoses}}',
  'diseaseSummary.sampleSummary':
    '{sampleText} {sampleCount, plural, one {file} other {total files}} from',
  'diseaseSummary.subjectSummary':
    '{patientText} {patientCount, plural, one {subject} other {subjects}} in',
  'diseaseSummary.diagnosisSummary':
    '{diagnosisText} {diagnosisCount, plural, one {diagnosis} other {different diagnoses}}',
  'datasetSummary.datasetSummary':
    '{datasetText} {datasetCount, plural, one {dataset} other {different datasets}}',
  'publicationSummary.publicationSummary':
    '{publicationText} {publicationCount, plural, one {publication} other {different publications}}',
  'diseaseSummary.summaryShort':
    '{sampleText} {sampleCount, plural, one {file} other {total files}} ' +
    'from {patientText} {patientCount, plural, one {subject} other {subjects}} ',
  'diseaseSummary.diseaseType': 'Diseases',
  'diseaseSummary.seqType': 'Sequencing Type',
  'diseaseSummary.fileType': 'File Type',
  'publicationSummary.publications': 'Publications',
  'miniSummary.title': 'Unique Subjects Selected',
  'miniSummary.viewRequests': 'View Previous Requests',
  'miniSummary.patientFootnote': 'Indicates number of patients',
  'miniSummary.selectedCount_diseases':
    'in {count} {count, plural, one {disease} other {diseases}}',
  'miniSummary.selectedCount_publications':
    'in {count} {count, plural, one {publication} other {publications}}',
  'miniSummary.selectedCount_datasets':
    'in {count} {count, plural, one {dataset} other {datasets}}',
  'requestSummary.immediateAccessInfo':
    'You have immediate access to all of the files you have selected',
  'requestSummary.controlledAccessInfo':
    'The data you are requesting is a part of one or more of our Controlled Datasets. ' +
    'If you already have access to a Dataset, it will automatically be vended to your Cloud project. ' +
    'Please be sure to request access to the Dataset(s) below in the Data Request Form.',
  'requestSummary.formsLink': 'See blank forms and more information on controlled access data.',
  'requestSummary.controlledAccessDialog':
    'For the sake of protecting our human research subjects, we control access to ' +
    'certain data files that contain information about individual research subjects. To get permission to access these files, ' +
    'please download, complete, and sign the following data access agreement(s). You may fill and sign them electronically. ' +
    'The completed agreement(s) must be uploaded with your data request.',
};

export default localeData;
