/**
 * @generated SignedSource<<d611d71a0e82fbf0636b107a49a488d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DataAccessUnits_AddendumPage_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessRequestsAccessUnit",
      "kind": "LinkedField",
      "name": "accessRequestsAccessUnits",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessUnit",
          "kind": "LinkedField",
          "name": "accessUnit",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dxid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decision",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessRequest",
  "abstractKey": null
};

node.hash = "f8ea224ed81c77d280474ea94ba20b7b";

module.exports = node;
