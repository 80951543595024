import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import SummaryField from './SummaryField';

function PISummary({isPi, user, principalInvestigator}) {
  let name;
  let email;

  if (isPi) {
    name = user.fullName;
    email = user.email;
  } else {
    name = principalInvestigator.name;
    email = principalInvestigator.email;
  }

  return (
    <div>
      <Form.Check>
        <Form.Check.Label className="checkbox-container">
          <Form.Check.Input type="checkbox" checked={isPi} disabled />
          I am the Principal Investigator.
          <span className="checkmark" />
        </Form.Check.Label>
      </Form.Check>
      <SummaryField name="Full Name" value={name} />
      <SummaryField name="Email" value={email} />
    </div>
  );
}

PISummary.defaultProps = {
  isPi: false,
};

PISummary.propTypes = {
  isPi: PropTypes.bool,
  principalInvestigator: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default PISummary;
