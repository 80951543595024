/**
 * @generated SignedSource<<a6201966a0d2558eccc0adc511339647>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalApplicantsInfo_AddendumPage_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessRequestDraft",
      "kind": "LinkedField",
      "name": "accessRequestDraft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPi",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "additionalApplicants",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessRequest",
  "abstractKey": null
};
})();

node.hash = "13f7748dfd96886aaa7a36767f52573c";

module.exports = node;
