/**
 * @generated SignedSource<<e125de4ae87c90534c7b171b044d5ccc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadData_AddendumPage_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wantsToDownload",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalApplicant",
      "kind": "LinkedField",
      "name": "itDirector",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessRequestDraft",
  "abstractKey": null
};

node.hash = "a31d9b270f05ed0f4d227c2a81d357eb";

module.exports = node;
