import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {Button, Modal, Form} from 'react-bootstrap';

import {sendTOSAcceptance} from './actions';
import TermsOfService from '../TermsOfService';
import {usersAuthLogoutPath} from '../../../api/routes';

function TOSModal({show}) {
  const [acceptTOSChecked, setAcceptTOSChecked] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const acceptTOS = () => {
    setRequestSent(true);
    dispatch(sendTOSAcceptance());
  };

  const rejectTOS = () => {
    window.location = usersAuthLogoutPath();
  };

  return (
    <Modal dialogClassName="w-75 mw-100" show={show} onHide={rejectTOS} scrollable>
      <Modal.Body>
        <div className="tosDisplay">
          <TermsOfService />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Form className="w-100">
          <Form.Check
            type="checkbox"
            id="acceptTOS"
            label={intl.formatMessage({id: 'tos.checkbox'})}
            className="pb-3"
            onChange={() => setAcceptTOSChecked(!acceptTOSChecked)}
          />
          <div className="pull-right">
            <Button variant="danger" onClick={rejectTOS} className="mx-1">
              {intl.formatMessage({id: 'tos.cancelButton'})}
            </Button>
            <Button
              variant="primary"
              onClick={acceptTOS}
              disabled={!(acceptTOSChecked || requestSent)}
              className="mx-1"
            >
              {intl.formatMessage({id: 'tos.acceptButton'})}
            </Button>
          </div>
        </Form>
      </Modal.Footer>
    </Modal>
  );
}

TOSModal.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default TOSModal;
