const FILE_SIZE_LIMIT = 16 * 1024 * 1024; // 16 megabytes

export const FILE_UPLOAD_VALIDATION = {
  FILE_SIZE_LIMIT,
  FILE_TOO_LARGE_TYPE: 'File too large',
  FILE_TOO_LARGE_MESSAGE: `File size may not exceed ${FILE_SIZE_LIMIT} bytes`,
  NO_FILE_MESSAGE: 'DAA form can not be blank.',
  INCORRECT_FILE_TYPE_TYPE: 'File has incorrect type',
  INCORRECT_FILE_TYPE_MESSAGE: 'File must be a PDF.',
};

export const TOOL_EDITOR_ICON_MAX_FILE_SIZE = 16 * 1024 ** 2; // 16 MB
export const TOOL_EDITOR_ICON_ACCEPT_MIME_TYPES = {'image/*': ['.png']};

export const TOOL_TYPE_LINK = 'LINK';
export const TOOL_TYPE_WORKFLOW = 'WORKFLOW';

export default {
  PUBLICATIONS_TABLE_CELL_URL: 'https://www.ncbi.nlm.nih.gov/pubmed/',

  REQUEST_USER: 'REQUEST_USER',
  RECEIVE_USER: 'RECEIVE_USER',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
  LOGOUT_SUCCEEDED: 'LOGOUT_SUCCEEDED',
  LOGOUT_FAILED: 'LOGOUT_FAILED',

  ACCEPT_TOS_STARTED: 'ACCEPT_TOS_STARTED',
  ACCEPT_TOS_CONFIRMED: 'ACCEPT_TOS_CONFIRMED',
  ACCEPT_TOS_FAILED: 'ACCEPT_TOS_FAILED',

  PUSH_FLASH_ALERT: 'PUSH_FLASH_ALERT',
  CLEAR_FLASH_ALERT: 'POP_FLASH_ALERT',
  CLEAR_ALL_FLASH_ALERTS: 'CLEAR_ALL_FLASH_ALERTS',
};
