/**
 * @generated SignedSource<<9e72a38e9fe442ed889cc54010c02ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isNewDraft"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "institution",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailReminders",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wantsEsign",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStjude",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingSetup",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "parentRequestsOnly",
    "value": true
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectDxid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewOnly",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = [
  (v15/*: any*/),
  (v4/*: any*/)
],
v17 = {
  "condition": "isNewDraft",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessRequestDraft",
      "kind": "LinkedField",
      "name": "accessRequestDraft",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestType",
          "storageKey": null
        },
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "folderName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUncompletedStep",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPi",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wantsToDownload",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contemplatedUse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredAccessUnits",
          "storageKey": null
        },
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "principalInvestigator",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "additionalApplicants",
          "plural": true,
          "selections": (v16/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "institutionalAuthority",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "itDirector",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalApplicant",
          "kind": "LinkedField",
          "name": "primaryApplicant",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "AccessUnit",
  "kind": "LinkedField",
  "name": "accessUnits",
  "plural": true,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unrestricted",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessRequestSubmissionWizard_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "AccessRequest",
            "kind": "LinkedField",
            "name": "accessRequests",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": "accessRequests(parentRequestsOnly:true)"
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      (v18/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessRequestSubmissionWizard_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "AccessRequest",
            "kind": "LinkedField",
            "name": "accessRequests",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": "accessRequests(parentRequestsOnly:true)"
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      (v18/*: any*/)
    ]
  },
  "params": {
    "cacheID": "81d98d58811571ee30b67d5991943b24",
    "id": null,
    "metadata": {},
    "name": "AccessRequestSubmissionWizard_index_Query",
    "operationKind": "query",
    "text": "query AccessRequestSubmissionWizard_index_Query(\n  $isNewDraft: Boolean!\n) {\n  user {\n    id\n    fullName\n    jobTitle\n    email\n    institution\n    country\n    emailReminders\n    wantsEsign\n    isStjude\n    billingSetup\n    accessRequests(parentRequestsOnly: true) {\n      projectName\n      projectDxid\n      viewOnly\n      id\n    }\n    accessRequestDraft @skip(if: $isNewDraft) {\n      id\n      iid\n      requestType\n      projectName\n      folderName\n      lastUncompletedStep\n      isPi\n      wantsToDownload\n      contemplatedUse\n      requiredAccessUnits\n      projectDxid\n      viewOnly\n      principalInvestigator {\n        name\n        email\n      }\n      additionalApplicants {\n        name\n        email\n      }\n      institutionalAuthority {\n        name\n        email\n      }\n      itDirector {\n        name\n        email\n      }\n      primaryApplicant {\n        name\n        email\n      }\n    }\n  }\n  accessUnits {\n    name\n    description\n    unrestricted\n  }\n}\n"
  }
};
})();

node.hash = "bf8c68a378137b71af13ce8e9d031fdd";

module.exports = node;
