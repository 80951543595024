import {combineReducers} from '@reduxjs/toolkit';
import apps from './apps';
import user from './user';
import config from './config';
import flashAlerts from './flashAlerts';

const rootReducer = combineReducers({
  user,
  apps,
  config,
  flashAlerts,
});

export default rootReducer;
