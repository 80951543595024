import {isEmpty, isNil} from 'ramda';
import {createReducer} from '../../utils';
import C from '../../constants';

const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  institution: null,
  jobTitle: null,
  country: null,
  wantsEsign: null,
  emailReminders: null,
  hasAcceptedTos: false,
  isAdmin: false,
  isHidden: false,
  isStjude: false,
  approvedAccessUnits: [],
  hasActiveDraft: false,
  isLoggedIn: false,
  isLoggingOut: false,
  isLoading: true,
  billingSetup: false,
};

export default createReducer(initialState, {
  [C.REQUEST_USER]: state => ({
    ...state,
    isLoading: true,
  }),

  [C.RECEIVE_USER]: (state, action) => {
    const isLoggedIn = !isNil(action.user) && !isEmpty(action.user);
    const user = isLoggedIn ? action.user : {};
    return {
      ...state,
      ...user,
      isLoading: false,
      isLoggedIn,
    };
  },

  [C.ACCEPT_TOS_CONFIRMED]: (state, action) => ({
    ...state,
    ...action.value,
  }),

  [C.LOGOUT_STARTED]: state => ({
    ...state,
    isLoggingOut: true,
  }),

  [C.LOGOUT_SUCCEEDED]: () => initialState,
});
