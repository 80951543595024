import React from 'react';
import PropTypes from 'prop-types';

function AccessUnitCheckbox({checked, description}) {
  /* eslint-disable react/no-danger */
  return (
    <label className="checkbox-container" htmlFor="checkbox">
      <input readOnly name="checkbox" type="checkbox" checked={checked} />
      <span dangerouslySetInnerHTML={{__html: description}} />
      <span className="checkmark" />
    </label>
  );
  /* eslint-enable react/no-danger */
}

AccessUnitCheckbox.propTypes = {
  checked: PropTypes.bool,
  description: PropTypes.string,
};

export default AccessUnitCheckbox;
