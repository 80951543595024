/**
 * Removes the form field prefix and returns only the field names in an array
 * @function parseFormFieldName
 * @param {string} name - Full name of form field including prefix
 * @returns {array}
 */
export default function parseFormFieldName(name) {
  return name
    .split(/\[|\]/)
    .filter(e => e !== '')
    .slice(1);
}
