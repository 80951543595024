import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {ErrorComponent} from '@stjudecloud/ui-react';

import {tabURLFromRequestType} from '../DataBrowserPage/util';
import DataBrowserPage from '../DataBrowserPage';

import PageTemplate from '../../templates/PageTemplate';

import databrowserFilterStorage from '../../../utils/databrowserFilterStorage';

const NavigateToSamples = () => {
  const {setId} = useParams();
  return <Navigate to={`../samples/${setId}`} />;
};

function DataPage() {
  const {requestType} = databrowserFilterStorage;
  const redirectPath = tabURLFromRequestType(requestType);

  const metaData = {
    htmlTitle: 'Data Browser',
    metaDescription: 'Browse and request genomic data from the St. Jude Cloud.',
    metaKeywords: ['databrowser', 'data browser'],
  };

  return (
    <PageTemplate {...metaData}>
      <Routes>
        <Route path="" element={<Navigate to={redirectPath} replace />} />

        <Route path="diseases" element={<Navigate to="paired-tumor-normal" />} />
        <Route path="diseases/:subtab" element={<DataBrowserPage />} />

        <Route path="publications" element={<DataBrowserPage />} />

        <Route path="cohorts" element={<DataBrowserPage />} />
        <Route path="cohorts/:domain" element={<DataBrowserPage />} />

        <Route path="samples" element={<DataBrowserPage />} />
        <Route path="samples/:setId" element={<DataBrowserPage />} />
        <Route path="pecan/:setId" element={<NavigateToSamples />} />

        <Route path="*" element={<ErrorComponent variant="notFound" />} />
      </Routes>
    </PageTemplate>
  );
}

export default DataPage;
