import {graphql} from 'react-relay';

export default graphql`
  query userQuery_User_Query {
    user {
      iid
      firstName
      lastName
      email
      dxid
      hasAcceptedTos
      hasActiveDraft
      isAdmin
      isHidden
      isStjude
      billingSetup
    }
  }
`;
