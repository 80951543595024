import {isEmpty as ramdaIsEmpty} from 'ramda';

const filterStorage = {
  init() {
    // defaulting to localStorage can technically result in race conditions if
    // a user is interacting with the databrowser in 2 seperate tabs/windows.
    this.storage = localStorage || sessionStorage;
    this.filtersKey = 'databrowserFilters-07202022';
    this.requestTypeKey = 'requestType';
  },

  get filters() {
    return JSON.parse(this.storage.getItem(this.filtersKey) || '{}');
  },

  set filters(filters) {
    this.storage.setItem(this.filtersKey, JSON.stringify(filters));
  },

  get requestType() {
    return this.storage.getItem(this.requestTypeKey);
  },

  set requestType(requestType) {
    this.storage.setItem(this.requestTypeKey, requestType);
  },

  get isEmpty() {
    return ramdaIsEmpty(this.requestType) || ramdaIsEmpty(this.filters.selectedTags);
  },

  get hasFilters() {
    return this.filtersKey in this.storage && !this.isEmpty;
  },

  reset() {
    this.storage.removeItem(this.filtersKey);
    this.storage.removeItem(this.requestTypeKey);
  },
};

filterStorage.init();

export default filterStorage;
