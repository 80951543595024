import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Footer} from '@stjudecloud/ui-react';
import {isNil} from 'ramda';

import {getIsUserLoggedIn, getCurrentUser} from '../../../reducers/user/selectors';

import Header from '../../complexes/Header';
import TOSModal from '../../atoms/TOSModal';
import ErrorBoundary from '../../atoms/ErrorBoundary';
import FlashAlerts from '../../molecules/FlashAlerts';

import Helmet from './Helmet';

export function PageTemplate(props) {
  const {children, metaDescription, metaKeywords, htmlTitle} = props;
  const currentUser = useSelector(getCurrentUser);
  const hasAcceptedTos = currentUser?.hasAcceptedTos;
  const isLoggedIn = useSelector(getIsUserLoggedIn);

  return (
    <div className="page">
      <Helmet {...{htmlTitle, metaDescription, metaKeywords}} />
      <TOSModal show={!isNil(isLoggedIn) && isLoggedIn && !hasAcceptedTos} />
      <Header />
      <div className="main-content">
        <FlashAlerts />
        <div className="page-children">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
      <Footer />
    </div>
  );
}

PageTemplate.propTypes = {
  children: PropTypes.any.isRequired,
  htmlTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
};

export default PageTemplate;
