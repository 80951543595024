import {useRef, useEffect} from 'react';

/**
 * usePrevious returns the given value from the previous render.
 */
const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
