import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import SummaryField from './SummaryField';

function AdditionalApplicantsSummary({additionalApplicants}) {
  const hasAdditionalApplicants =
    additionalApplicants === undefined || !!additionalApplicants.length;
  return (
    <div>
      <div className="mb-3">
        <Form.Check>
          <Form.Check.Label className="checkbox-container">
            <Form.Check.Input type="checkbox" checked={hasAdditionalApplicants} disabled />
            Others will be working with the data.
            <span className="checkmark" />
          </Form.Check.Label>
        </Form.Check>
      </div>
      {hasAdditionalApplicants &&
        additionalApplicants.map((applicant, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <div key={index} className="mb-3">
            <Form.Label className="summary-form-label" data-testid={`edaa-summary-aa-${index + 1}`}>
              {`Additional Applicant ${index + 1}`}
            </Form.Label>
            <SummaryField name="Full Name" value={applicant.name} />
            <SummaryField name="Email" value={applicant.email} />
          </div>
        ))}
    </div>
  );
}

AdditionalApplicantsSummary.propTypes = {
  additionalApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default AdditionalApplicantsSummary;
