import React, {lazy, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Routes as BrowserRoutes, Route} from 'react-router-dom';
import {usePreloadedQuery} from 'react-relay';
import {useDispatch} from 'react-redux';
import {useRollbar} from '@rollbar/react';

import {ErrorPage, LogoutPage, LoginPage} from './pages';
import userQuery from './userQuery';
import {useScrollToTop, useClearFlashes, useLoginRedirect, useIntercomUpdates} from './hooks';

import {receiveUser} from '../actions';

const HomePage = lazy(() => import('./pages/HomePage'));
const DataPage = lazy(() => import('./pages/DataPage'));
const AdminPage = lazy(() => import('./admin/AdminPage'));
const WorkflowPreviewPage = lazy(() => import('./admin/WorkflowPreview'));
const WorkflowPage = lazy(() => import('./pages/WorkflowPage'));
const WorkflowsPage = lazy(() => import('./pages/WorkflowsPage'));
const AccessRequestPage = lazy(() => import('./pages/AccessRequestPage'));
const AddendumPage = lazy(() => import('./pages/AddendumPage'));
const AccessRequestSubmissionWizard = lazy(() => import('./pages/AccessRequestSubmissionWizard'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));

function Routes({preloadedUserQuery}) {
  const dispatch = useDispatch();
  const {user} = usePreloadedQuery(userQuery, preloadedUserQuery);
  const rollbar = useRollbar();

  useEffect(() => {
    if (user) {
      rollbar.configure({
        captureIp: 'anonymize',
        payload: {
          person: {
            id: user.iid,
            username: user.dxid,
          },
        },
      });
    }
    dispatch(receiveUser(user));
  }, [user, dispatch, rollbar]);

  useIntercomUpdates();
  useScrollToTop();
  useClearFlashes();
  useLoginRedirect();

  return (
    <BrowserRoutes>
      <Route path="dashboard" element={<DashboardPage />} />
      {/* Data */}
      <Route path="request/:id" element={<AccessRequestPage />} />
      <Route path="extension/:id" element={<AddendumPage />} />
      <Route path="data/*" element={<DataPage />} />
      <Route path="requests/*" element={<AccessRequestSubmissionWizard />} />
      {/* Tools */}
      <Route path="workflows" element={<WorkflowsPage />} />
      <Route path="workflows/:slug" element={<WorkflowPage />} />
      {/* Admin pages */}
      <Route path="admin/preview-tool/:draftId" element={<WorkflowPreviewPage />} />
      <Route path="admin/*" element={<AdminPage />} />
      {/* Other */}
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      {/* Homepage */}
      <Route path="/" element={<HomePage />} />
      {/* Match all unmatched routes to a 404 page */}
      <Route path="*" element={<ErrorPage variant="notFound" />} />
    </BrowserRoutes>
  );
}

Routes.propTypes = {
  preloadedUserQuery: PropTypes.object,
};

export default Routes;
