import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

function ProjectForm({
  projectName,
  setProjectName,
  folderName,
  setFolderName,
  wantsToVendToExistingProject,
}) {
  return (
    <div>
      <div className="wizard-header">Name your project and folder.</div>
      <div className="wizard-sub-header">
        This is the name of the secure project and folder that your data will be vended to.
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <Form.Group controlId="request_project_name">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            type="text"
            name="request[projectName]"
            required
            disabled={wantsToVendToExistingProject}
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
            data-testid="txt-edaa-project-name"
          />
          <Form.Control.Feedback type="invalid">Project name is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="request_folder_name">
          <Form.Label>Folder Name</Form.Label>
          <Form.Control
            type="text"
            name="request[folderName]"
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
            data-testid="txt-edaa-folder-name"
          />
        </Form.Group>
      </div>
    </div>
  );
}

ProjectForm.defaultProps = {
  projectName: '',
};

ProjectForm.propTypes = {
  projectName: PropTypes.string,
  setProjectName: PropTypes.func.isRequired,
  folderName: PropTypes.string,
  setFolderName: PropTypes.func.isRequired,
  wantsToVendToExistingProject: PropTypes.bool,
};

export default ProjectForm;
