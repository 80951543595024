import React from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import {Dropdown} from 'react-bootstrap';

import {dnanexusUserBillingPath, usersAuthLogoutPath} from '../../../api/routes';
import Link from '../../atoms/Link';

export function UserLoggedInDropdown({user}) {
  return (
    <>
      <li>
        <Dropdown.Item className="hover-none">
          <Gravatar email={user.email} size={32} default="mm" /> {user.firstName}
        </Dropdown.Item>
      </li>
      <Dropdown.Divider />
      <li>
        <Dropdown.Item as={Link} to="/dashboard">
          Dashboard
        </Dropdown.Item>
      </li>
      <li>
        <Dropdown.Item as={Link} href={dnanexusUserBillingPath(user.dxid)} newTab>
          DNAnexus Profile
        </Dropdown.Item>
      </li>
      <li>
        <Dropdown.Item as={Link} href={usersAuthLogoutPath()}>
          Sign out
        </Dropdown.Item>
      </li>
    </>
  );
}

UserLoggedInDropdown.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    email: PropTypes.string,
    dxid: PropTypes.string,
  }).isRequired,
};

export default UserLoggedInDropdown;
