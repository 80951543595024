import {Environment, Network, RecordSource, Store, QueryResponseCache} from 'relay-runtime';
import {createConsumer} from '@rails/actioncable';
import createRelaySubscriptionHandler from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';

import csrf from './utils/csrf';
import {apiV1GraphQLPath} from './api/routes';

// 25 cache entries, 300 seconds until cache is invalid.
export const cache = new QueryResponseCache({size: 25, ttl: 300000});
export const fetchQuery = async (operation, variables, cacheConfig) => {
  const queryId = operation.name;
  const cachedData = cache.get(queryId, variables);
  const forceLoad = cacheConfig && cacheConfig.force;

  if (!forceLoad && cachedData) {
    // Return promise to keep returns consistent
    // eslint-disable-next-line no-promise-executor-return
    return new Promise(resolve => resolve(cachedData));
  }

  const query = operation.text.replace(/\s+/g, ' ');
  document.body?.setAttribute('data-fetching', true);

  const result = await fetch(apiV1GraphQLPath(), {
    credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...csrf.headers,
    },
    body: JSON.stringify({
      query,
      variables,
      operationName: queryId,
    }),
  });

  document.body?.setAttribute('data-fetching', 'done');

  const json = await result.json();
  cache.set(queryId, variables, json);
  return json;
};

const subscriptionHandler = createRelaySubscriptionHandler({
  cable: createConsumer(),
  channelName: 'GraphQLChannel',
});

const environment = new Environment({
  network: Network.create(fetchQuery, subscriptionHandler),
  store: new Store(new RecordSource()),
});

export default environment;
