import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from 'react-relay';
import {graphql} from 'relay-runtime';

import SubsectionTitle from './SubsectionTitle';
import UserDetail from './UserDetail';
import USER_TYPES from './userTypes';

const institutionalAuthorityFragment = graphql`
  fragment InstitutionalAuthority_AddendumPage_Fragment on AccessRequestDraft {
    institutionalAuthority {
      name
      email
    }
  }
`;

const InstitutionalAuthority = ({queryData, upsertDraft, upserting}) => {
  const {institutionalAuthority} = useFragment(institutionalAuthorityFragment, queryData);
  const [ia, setIa] = useState(institutionalAuthority);

  const upsert = user => {
    const onError = message => {
      setIa({...ia, error: message});
    };
    upsertDraft({institutionalAuthority: user}, onError);
  };

  const onCancel = () => {
    setIa({...ia, editMode: false});
  };

  const onSave = user => {
    upsert(user);
  };

  const onClick = () => {
    setIa({...ia, editMode: true});
  };

  useEffect(() => {
    setIa(institutionalAuthority);
  }, [institutionalAuthority]);

  return (
    <div className="addendum-subsection">
      <SubsectionTitle
        title="Institutional Authority"
        icon={<i className="fas fa-info-circle" />}
        info="If there is a different person signing for this role, please edit this section. Including an Institutional Authority signature is required."
      />
      <UserDetail
        name={ia.name}
        email={ia.email}
        icon={<i className="fas fa-user-tie" />}
        onCancel={onCancel}
        onSave={onSave}
        onClick={onClick}
        editMode={ia.editMode}
        type={USER_TYPES.INSTITUTIONAL_AUTHORITY}
        upserting={upserting}
        error={ia.error}
        canEdit
      />
    </div>
  );
};

export default InstitutionalAuthority;

InstitutionalAuthority.propTypes = {
  queryData: PropTypes.object.isRequired,
  upsertDraft: PropTypes.func.isRequired,
  upserting: PropTypes.bool.isRequired,
};
