/* eslint-disable react/no-array-index-key */
import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {Button} from 'react-bootstrap';

import SubsectionTitle from './SubsectionTitle';
import UserDetail from './UserDetail';
import USER_TYPES from './userTypes';

const additionalApplicantsInfoFragment = graphql`
  fragment AdditionalApplicantsInfo_AddendumPage_Fragment on AccessRequest {
    user {
      fullName
      email
    }
    accessRequestDraft {
      isPi
      additionalApplicants {
        name
        email
      }
    }
  }
`;

const AddApplicantsButton = ({onClick}) => (
  <Button variant="link" className="text-decoration-none p-0 mx-2" onClick={onClick}>
    <span>
      <i className="fas fa-plus" />
    </span>{' '}
    <span>Add Applicant</span>
  </Button>
);

const AddAdditionalApplicants = ({onClick}) => (
  <div className="add-additional-applicants d-flex flex-column">
    <div className="m-auto d-flex flex-column">
      <div className="text-center">
        <i className="fas fa-user fs-4" />
      </div>
      <div className="text-center">
        <div className="fw-bold">There are no applicants in this project yet</div>
        <div className="fw-light">You could add them, if you need</div>
      </div>
      <AddApplicantsButton onClick={onClick} />
    </div>
  </div>
);

const AdditionalApplicants = ({queryData, upsertDraft, upserting, showConfirmDelete}) => {
  const {
    accessRequestDraft: {additionalApplicants, isPi},
    user,
  } = useFragment(additionalApplicantsInfoFragment, queryData);

  const [applicants, setApplicants] = useState(additionalApplicants);

  const canAddApplicant = useMemo(() => {
    if (applicants.find(a => a.newApplicant)) return false;
    return true;
  }, [applicants]);

  const upsert = (index, newApplicants) => {
    // all newApplicants should be ready to be upserted by now
    const newData = {
      additionalApplicants: newApplicants.map(a => ({name: a.name, email: a.email})),
    };
    const currentApplicants = [...applicants];

    const onError = message => {
      currentApplicants[index] = {...currentApplicants[index], error: message};
      setApplicants(currentApplicants);
    };

    upsertDraft(newData, onError);
  };

  const onSave = (index, applicant) => {
    const newApplicants = [...applicants];
    newApplicants[index] = applicant;
    upsert(index, newApplicants);
  };

  const onDelete = (index, newApplicant) => {
    const newApplicants = [...applicants];
    newApplicants.splice(index, 1);

    // new applicant is not saved in database yet so no need to upsert
    if (newApplicant) setApplicants(newApplicants);
    else upsert(index, newApplicants);
  };

  const showConfirmDeleteModal = (index, newApplicant) => {
    showConfirmDelete(applicants[index].name, `An ${USER_TYPES.ADDITIONAL_APPLICANT}`, () =>
      onDelete(index, newApplicant)
    );
  };

  const onClick = index => {
    // Only allow one edit at a time
    // Also, remove the newApplicant from the state if user switches to edit another applicant
    setApplicants(
      applicants
        .filter(a => !a.newApplicant)
        .map((a, i) => {
          if (i === index) return {...a, editMode: true};
          return {...a, editMode: false};
        })
    );
  };

  const onCancel = (index, newApplicant) => {
    if (newApplicant) {
      onDelete(index, true);
    } else {
      const newApplicants = [...applicants];
      newApplicants[index] = {...additionalApplicants[index], editMode: false};
      setApplicants(newApplicants);
    }
  };

  const addApplicant = () => {
    const currentApplicants = applicants.map(a => ({...a, editMode: false}));
    const newApplicants = [
      ...currentApplicants,
      {name: '', email: '', editMode: true, newApplicant: true},
    ];
    setApplicants(newApplicants);
  };

  useEffect(() => {
    setApplicants(additionalApplicants);
  }, [additionalApplicants]);

  return (
    <div className="addendum-subsection">
      <SubsectionTitle
        title="Additional Applicants"
        icon={<i className="fas fa-info-circle" />}
        info="Additional Applicants are anyone else who will have access to the data whether on DNAnexus or locally. If any Applicants are no longer involved please remove them, and if there are any new Applicants who will have access please add them here."
      />
      {isPi && (!applicants || applicants.length === 0) ? (
        <AddAdditionalApplicants onClick={addApplicant} />
      ) : (
        <>
          {!isPi && (
            <UserDetail
              name={user.fullName}
              email={user.email}
              icon={<i className="fas fa-user" />}
              popoverText="You can't edit your information"
              self
            />
          )}
          {applicants.map((aa, index) => (
            <UserDetail
              key={`${aa.name}_${index}`}
              name={aa.name}
              email={aa.email}
              icon={<i className="fas fa-user" />}
              onCancel={() => onCancel(index, aa.newApplicant)}
              onSave={applicant => onSave(index, applicant)}
              onDelete={() => showConfirmDeleteModal(index, aa.newApplicant)}
              onClick={() => onClick(index)}
              type={`${USER_TYPES.ADDITIONAL_APPLICANT} ${index + (!isPi ? 2 : 1)}`}
              editMode={aa.editMode}
              error={aa.error}
              upserting={upserting}
              canEdit
              canDelete
            />
          ))}
          {canAddApplicant && <AddApplicantsButton onClick={addApplicant} />}
        </>
      )}
    </div>
  );
};

export default AdditionalApplicants;

AdditionalApplicants.propTypes = {
  queryData: PropTypes.object.isRequired,
  upsertDraft: PropTypes.func.isRequired,
  upserting: PropTypes.bool.isRequired,
  showConfirmDelete: PropTypes.func.isRequired,
};

AddApplicantsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

AddAdditionalApplicants.propTypes = {
  onClick: PropTypes.func.isRequired,
};
