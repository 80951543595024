import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../atoms/Link';
import C from '../../../constants';

export function PublicationTitleRenderer({data: {name, pmid}}) {
  if (!pmid) {
    return <div>{name}</div>;
  }

  return (
    <Link href={`${C.PUBLICATIONS_TABLE_CELL_URL}${pmid}`} target="_blank">
      {name}
    </Link>
  );
}

PublicationTitleRenderer.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pmid: PropTypes.string,
  }),
};

export default PublicationTitleRenderer;
