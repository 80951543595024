import * as Routes from './routes';
import API from './index';

export function ApiUploadRequestForm(id, file) {
  const formData = new FormData();
  formData.append('accessRequestForm', file);

  return API.fetchJSON(Routes.apiV1AccessRequestFormsPath(id), formData, {
    method: 'POST',
  });
}

export function ApiUploadAddendumForm(id, file) {
  const formData = new FormData();
  formData.append('accessRequestAddendumForm', file);

  return API.fetchJSON(Routes.apiV1AccessRequestAddendumFormsPath(id), formData, {
    method: 'POST',
  });
}
