import {useContext, useEffect} from 'react';

import useStableIdentity from './useStableIdentity';
import BreadcrumbsContext from '../atoms/BreadcrumbsContext';

export default function useBreadcrumbs(breadcrumbs) {
  const {setBreadcrumbs} = useContext(BreadcrumbsContext);
  const stableCrumbs = useStableIdentity(breadcrumbs);

  useEffect(() => {
    setBreadcrumbs(stableCrumbs);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs, stableCrumbs]);
}
