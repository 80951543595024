import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from 'react-bootstrap';

const SubsectionTitle = ({title, icon, info}) => {
  const popover = (
    <Popover>
      <Popover.Body>{info}</Popover.Body>
    </Popover>
  );

  return (
    <div className="addendum-subsection-title">
      <span className="h4">{title}</span>{' '}
      {info ? (
        <OverlayTrigger placement="top" overlay={popover}>
          {icon}
        </OverlayTrigger>
      ) : (
        icon
      )}
      <hr />
    </div>
  );
};

export default SubsectionTitle;

SubsectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
