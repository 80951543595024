export default {
  filters: [{type: 'input', value: 'ingtf'}],
  appList: [
    {
      appName: 'CICERO',
      subTitle: 'Small Desc, just a few words',
      version: '0.1.2.1',
      inputs: ['ingtf', 'star index', 'Reference Dataset'],
      outputs: [
        'reads_fastqgz',
        'reads2_fastgz',
        'read_group_platform_unit',
        'add_read_group',
        'read_group_samples',
        'read_group_library',
      ],
      actions: {title: 'Run this tool'},
    },
    {
      appName: 'CONSERTING',
      subTitle: 'Small Desc, just a few words',
      version: '0.1.2.1',
      inputs: ['ingtf', 'star_index', 'Reference Dataset'],
      outputs: [
        'reads_fastqgz',
        'reads2_fastgz',
        'read_group_platform_unit',
        'add_read_group',
        'read_group_samples',
        'read_group_library',
      ],
      actions: {title: 'Run this tool'},
    },
    {
      appName: 'Bambino',
      subTitle: 'Large desc, so many words. The best words. All the words.',
      version: '0.1.2.1',
      inputs: ['ingtf', 'star_index', 'Reference Dataset'],
      outputs: [
        'reads_fastqgz',
        'reads2_fastgz',
        'read_group_platform_unit',
        'add_read_group',
        'read_group_samples',
        'read_group_library',
      ],
      actions: {title: 'Run this tool'},
    },
    {
      appName: 'Strongarm',
      subTitle: 'Small Desc, just a few words',
      version: '0.1.2.1',
      inputs: ['ingtf', 'star_index', 'Reference Dataset'],
      outputs: [
        'reads_fastqgz',
        'reads2_fastgz',
        'read_group_platform_unit',
        'add_read_group',
        'read_group_samples',
        'read_group_library',
      ],
      actions: {title: 'Run this tool'},
    },
    {
      appName: 'Lance-IT',
      subTitle: 'Small Desc, just a few words',
      version: '0.1.2.1',
      inputs: ['ingtf', 'star_index', 'Reference Dataset'],
      outputs: [
        'reads_fastqgz',
        'reads2_fastgz',
        'read_group_platform_unit',
        'add_read_group',
        'read_group_samples',
        'read_group_library',
      ],
      actions: {title: 'Run this tool'},
    },
  ],
};
