import React, {useEffect, useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import {Spinner as BootstrapSpinner, InputGroup, FormControl, FloatingLabel} from 'react-bootstrap';
import {pick, without} from 'ramda';
import classnames from 'classnames';

const propTypes = {
  floating: PropTypes.bool,
  pacman: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
};

function FilterBox(props) {
  const {value, pacman, onChange, className, floating, children, placeholder} = props;
  const [filterValue, setFilterValue] = useState(value);
  const [showSpinner, setShowSpinner] = useState(false);
  const inputUpdateTimer = useRef(null);
  const spinnerTimer = useRef(null);

  const clearInputUpdateTimer = useCallback(() => {
    if (inputUpdateTimer.current) {
      clearTimeout(inputUpdateTimer.current);
      inputUpdateTimer.current = null;
    }
  }, []);

  const clearSpinnerTimer = useCallback(() => {
    if (spinnerTimer.current) {
      clearTimeout(spinnerTimer.current);
      spinnerTimer.current = null;
    }
  }, []);

  useEffect(
    () => () => {
      clearInputUpdateTimer();
      clearSpinnerTimer();
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onInputChangedTimeout = val => {
    clearSpinnerTimer();
    setShowSpinner(false);
    onChange(val);
  };

  const signalInputUpdate = val => {
    clearInputUpdateTimer();
    inputUpdateTimer.current = setTimeout(() => onInputChangedTimeout(val), 500);

    // Only show the spinner after 1 second has passed, if user is done typing
    // before then, the timer will cancel and not show spinner; if spinner is
    // already showing or queued to show, skip
    if (!spinnerTimer.current && !showSpinner) {
      spinnerTimer.current = setTimeout(() => setShowSpinner(true), 1000);
    }

    setFilterValue(val);
  };

  const spinner = () => {
    if (pacman) return <Spinner name="pacman" />;

    return (
      <BootstrapSpinner animation="border" role="status" size="sm">
        <span className="sr-only">Loading...</span>
      </BootstrapSpinner>
    );
  };

  // Generate a new object with key-value pairs that don't contain any of the
  // props (keys) declared by this component
  const passthruProps = pick(without(Object.keys(propTypes), Object.keys(props)))(props);

  // eslint-disable-next-line react/destructuring-assignment
  const label = props.showSpinner || showSpinner ? spinner() : <span className="fa fa-search" />;

  if (floating) {
    return (
      <InputGroup className={classnames(className, 'filter-box', {'match-width': pacman})}>
        <FloatingLabel
          label={
            <>
              {label} {placeholder}
            </>
          }
        >
          <FormControl
            aria-describedby="basic-addon"
            className="filter-box-search-input"
            onChange={e => signalInputUpdate(e.target.value)}
            value={filterValue}
            placeholder={placeholder}
            {...passthruProps}
          />
        </FloatingLabel>
        {children}
      </InputGroup>
    );
  }

  return (
    <InputGroup className={classnames(className, 'filter-box', {'match-width': pacman})}>
      <InputGroup.Text>{label}</InputGroup.Text>
      <FormControl
        aria-describedby="basic-addon"
        onChange={e => signalInputUpdate(e.target.value)}
        value={filterValue}
        placeholder={placeholder}
        {...passthruProps}
      />
      {children}
    </InputGroup>
  );
}

FilterBox.defaultProps = {
  pacman: false,
  className: '',
  value: '',
  floating: false,
};

FilterBox.propTypes = propTypes;

export default FilterBox;
