import React from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

import Link from '../../atoms/Link';

function AddendumUploadSuccessModal({contentLabel, show}) {
  const navigate = useNavigate();

  return (
    <Modal show={show} aria-label={contentLabel} onHide={() => navigate('/dashboard')}>
      <Modal.Header>
        <Modal.Title>Upload Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Your extension addendum was successfully uploaded.</div>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/dashboard">
          <Button variant="primary">Return to Manage Data</Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}

AddendumUploadSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string.isRequired,
};

export default AddendumUploadSuccessModal;
