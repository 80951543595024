import React from 'react';
import PropTypes from 'prop-types';
import {filesize} from 'filesize';

const FileSizeSummary = ({size}) => (
  <span>{filesize(size, {standard: 'jedec', round: 2, base: 2})}</span>
);

FileSizeSummary.propTypes = {
  size: PropTypes.number.isRequired,
};

export default FileSizeSummary;
