import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Dropdown, Nav} from 'react-bootstrap';

export function AdminLoggedInDropdown({show}) {
  // Force react-bootstrap to render the dropdown markdown so CSS can animate
  // the slide on toggle. After setting `show`, immediately unset to allow
  // dropdowns to function normally and independently.
  const [forceShow, setForceShow] = useState(true);
  useEffect(() => {
    setForceShow(null);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Dropdown className="user-dropdown" title="ADMIN" align="end">
      <Dropdown.Toggle as={Nav.Link} className="profile-dropdown-toggle">
        <i className="fa fa-user-shield fa-white" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="profile-dropdown-menu" show={forceShow}>
        <ul className="list-unstyled">
          <li>
            <Dropdown.Item to="/admin/users" as={NavLink}>
              Users
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item to="/admin/manage-tools" as={NavLink}>
              Manage Tools
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item to="/admin/access_units/update" as={NavLink}>
              Update Access Unit
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item to="/admin/access_units/create" as={NavLink}>
              Create Access Unit
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item href="/active_admin">Active Admin</Dropdown.Item>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

AdminLoggedInDropdown.propTypes = {
  show: PropTypes.bool,
};

export default AdminLoggedInDropdown;
