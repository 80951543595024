/**
 * @generated SignedSource<<f7b76255b5b990201abc382a97db74f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolList_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ToolConnection",
        "kind": "LinkedField",
        "name": "tools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tool",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ToolCard_Fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ToolList_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ToolConnection",
        "kind": "LinkedField",
        "name": "tools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tool",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subtitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      }
                    ],
                    "type": "Tool",
                    "abstractKey": null
                  }
                ],
                "type": "ToolInterface",
                "abstractKey": "__isToolInterface"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb2a887432f892a9fffef92e81bf3b77",
    "id": null,
    "metadata": {},
    "name": "ToolList_index_Query",
    "operationKind": "query",
    "text": "query ToolList_index_Query {\n  tools {\n    nodes {\n      slug\n      ...ToolCard_Fragment\n      id\n    }\n  }\n}\n\nfragment ToolCard_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  name\n  subtitle\n  slug\n  ... on Tool {\n    deleted\n  }\n  ...ToolImage_Fragment\n}\n\nfragment ToolImage_Fragment on ToolInterface {\n  __isToolInterface: __typename\n  name\n  imageUrl\n}\n"
  }
};
})();

node.hash = "5d7d23fbd96902aeff5a27b3a10bca92";

module.exports = node;
