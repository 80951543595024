import queryString from 'query-string';
import {objectWithoutKeys} from '../../../utils';

export function titleizeBadge(str) {
  return str
    .replace(/_/, ' ')
    .replace(/\w[^- ]*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1));
}

export function tabOrSubtab(tab, subtab) {
  if (tab === 'diseases') {
    return subtab;
  }

  return tab;
}

export function tabURLFromRequestType(requestType) {
  switch (requestType) {
    case 'TUMOR':
      return '/data/diseases/tumor';
    case 'GERMLINE':
      return '/data/diseases/germline';
    case 'PUBLICATIONS':
      return '/data/publications';
    case 'DATASETS':
      return '/data/cohorts';
    case 'SAMPLES':
      return '/data/samples';
    case 'PAIRED_TUMOR_NORMAL':
    default:
      return '/data/diseases/paired-tumor-normal';
  }
}

export function tabToType(tab, subtab) {
  return {
    tumor: 'TUMOR',
    germline: 'GERMLINE',
    'paired-tumor-normal': 'PAIRED_TUMOR_NORMAL',
    publications: 'PUBLICATIONS',
    cohorts: 'DATASETS',
    samples: 'SAMPLES',
  }[tabOrSubtab(tab, subtab)];
}

export function tabFromLocation(location) {
  // Parse out the selected tab. This works for both
  // `/data/xxxxx` and `/data/xxxxx/domain-param`
  const match = location.pathname.split('/data/');
  const [tab] = match[1].split('/');
  return tab;
}

export function tagForRow(row) {
  return (
    row.diseaseCode ||
    row.diagnosisCode ||
    row.accessionNumber ||
    row.publicationAccession ||
    row.name
  );
}

export function rowSelected(row, selectedTags) {
  const value = tagForRow(row);
  return selectedTags.indexOf(value) > -1;
}

function datasetAccessionNumbersForDomain(domain) {
  switch (domain) {
    case 'pediatric-cancer':
      return 'SJC-DS-1001 SJC-DS-1003 SJC-DS-1004 SJC-DS-1007';
    case 'cancer-survivorship':
      return 'SJC-DS-1002 SJC-DS-1005';
    case 'non-cancerous-diseases':
      return 'SJC-DS-1006';
    default:
      return '';
  }
}

function queryStringFilterTextForTab(tab, params, domain) {
  const parsed = queryString.parse(params);
  const pubId = parsed.publication_accession || '';
  const pubTitle = parsed.publication_title || '';
  const datasetAccessions = parsed.dataset_accession || '';
  const domainAccessions = datasetAccessionNumbersForDomain(domain);

  switch (tab) {
    case 'publications':
      if (pubId === '' && pubTitle === '') break;
      if (pubId === '') return pubTitle;
      if (pubTitle === '') return pubId;

      return `${pubId} ${pubTitle}`;
    case 'cohorts':
      if (domainAccessions === '' && datasetAccessions === '') break;
      if (domainAccessions === '') return datasetAccessions;
      if (datasetAccessions === '') return domainAccessions;

      return `${domainAccessions} ${datasetAccessions}`;
  }

  return parsed.search || '';
}

export function queryStringFilters(tab, params, domain) {
  const filters = {};

  const filterText = queryStringFilterTextForTab(tab, params, domain);
  if (filterText !== '') {
    filters.filterText = filterText;
  }

  const filterKeys = ['fileType', 'seqType', 'sampleType'];
  const parsed = queryString.parse(params, {parseBooleans: true});
  filterKeys.forEach(key => {
    const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    if (!parsed[snakeCaseKey] || parsed[snakeCaseKey] === '') return;

    const split = parsed[snakeCaseKey].split(',');
    filters[key] = split;
  });

  // Handle `selected_tags` separately in order to camelize snake_case key.
  const selectedTags = parsed.selected_tags?.split(',');
  if (selectedTags) {
    filters.selectedTags = selectedTags;
  }

  // search params like '&search=foo&exact_match' will parse `exact_match` as `null`
  if (parsed.exact_match || parsed.exact_match === null) {
    filters.exactMatch = true;
  }

  return filters;
}

export function getSelectAll(params) {
  const parsed = queryString.parse(params);
  return 'select_all' in parsed;
}

export function shareableURL(filters, tab, subtab) {
  let url = `/data/${tab}`;
  if (tab === 'diseases' && subtab) url += `/${subtab}`;
  if (tab === 'samples' && filters.setId && filters.setId !== '') url += `/${filters.setId}`;

  const queryParams = [];
  const filterKeys = ['fileType', 'seqType', 'sampleType'];
  filterKeys.forEach(key => {
    if (!filters[key] || !filters[key].length) return;

    const values = filters[key].map(value => encodeURIComponent(value));
    const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    queryParams.push(`${snakeCaseKey}=${values.join(',')}`);
  });

  if (filters.filterText && filters.filterText !== '') {
    queryParams.push(`search=${encodeURIComponent(filters.filterText)}`);
  }

  if (filters.selectedTags && filters.selectedTags.length) {
    const tags = filters.selectedTags.map(tag => encodeURIComponent(tag)).join(',');
    queryParams.push(`selected_tags=${tags}`);
  }

  if (filters.exactMatch) {
    queryParams.push('exact_match');
  }

  if (queryParams.length) {
    url += `?${queryParams.join('&')}`;
  }

  return url;
}

export function removeEmptyFilters(activeFilters) {
  const filters = {};

  const retainedFilters = ['selectedTags', 'exactMatch', 'filterText', 'setId'];
  // Empty filter lists should be omitted. Empty filter lists would signal that
  // the data should be filtered to match no filters.. which would just be an
  // empty result set.
  Object.keys(activeFilters).forEach(filterName => {
    const filter = activeFilters[filterName];

    if (filter?.length !== 0 || retainedFilters.includes(filterName)) {
      filters[filterName] = filter;
    }
  });

  return filters;
}

export function prepareGraphQLFilters(filters, tab, subtab) {
  // For showing rows on the databrowser, remove the 'selectedTags' option
  // for that filter set, otherwise only rows that were selected would be
  // shown. For the summary table, we want to filter with regards to
  // 'selectedTags', so submit those filters without modification.
  return {
    filters: objectWithoutKeys(filters, 'selectedTags'),
    summaryFilters: filters,
    type: tabToType(tab, subtab),
    includeDatabrowserRows: false,
    initialFetch: false,
  };
}
