import React from 'react';
import PropTypes from 'prop-types';
import {map, findIndex, propEq, pathOr} from 'ramda';
import classnames from 'classnames';
import {graphql, useLazyLoadQuery} from 'react-relay';

import SpinnerWrapper from '../../atoms/SpinnerWrapper';
import ToolCard from '../../molecules/ToolCard';

const toolQuery = graphql`
  query ToolList_index_Query {
    tools {
      nodes {
        slug
        ...ToolCard_Fragment
      }
    }
  }
`;

function ToolList({className, except, next}) {
  const topClassNames = classnames(['tool-list-component g-0', className]);
  const data = useLazyLoadQuery(toolQuery);
  const tools = pathOr([], ['tools', 'nodes'], data);

  let selected = [];
  if (next && next > -1 && tools && tools.length) {
    const start = findIndex(propEq(except, 'slug'))(tools);

    if (start > -1) {
      let index = (start + 1) % tools.length;

      while (index !== start) {
        const tool = tools[index];
        if (tool.slug !== except && selected.length < next) {
          selected.push(tool);
        }
        index = (index + 1) % tools.length;
      }
    } else {
      selected = tools.slice(0, next);
    }
  } else {
    selected = tools;
  }

  const renderToolCard = tool => <ToolCard key={tool.slug} queryData={tool} />;
  const toolCards = map(renderToolCard, selected);

  return (
    <SpinnerWrapper show={!tools.length} styled fadeIn="full">
      <div className={classnames(topClassNames, 'bare-list')}>{toolCards}</div>
    </SpinnerWrapper>
  );
}

ToolList.propTypes = {
  className: PropTypes.string,
  except: PropTypes.string,
  next: PropTypes.number,
};

export default ToolList;
