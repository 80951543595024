import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import classnames from 'classnames';
import {Collapse, Form} from 'react-bootstrap';
import {without} from 'ramda';

import {titleizeBadge} from './util';
import CopyShareableURLButton from './CopyShareableURLButton';

const HEADERS = {
  seqType: 'SEQUENCING TYPES',
  fileType: 'FILE TYPES',
  sampleType: 'SAMPLE TYPES',
};

const LABELS = {
  gVCF: 'gVCF', // shouldn't be capitalized
  FEATURE_COUNTS: 'Feature Counts',
  'RNA-SEQ': 'RNA-Seq',
};

const headerForType = type => HEADERS[type] || type.replace(/_/, ' ').toUpperCase();

const labelForValue = value => LABELS[value] || titleizeBadge(value);

const toggleFilter = (filters, setFilters, type, value) => {
  let filterValues = filters[type] || [];

  if (filterValues.indexOf(value) > -1) {
    filterValues = without([value], filterValues);
  } else {
    filterValues = [...filterValues, value];
  }

  setFilters({[type]: filterValues});
};

const isFilterActive = (filters, type, value) => {
  const filterValues = filters[type] || [];
  return filterValues.indexOf(value) > -1;
};

const sidebarFiltersFragment = graphql`
  fragment SidebarFilters_Filters_Fragment on Databrowser {
    filters {
      type
      filters
    }
  }
`;

/**
 * Displays an interactable pane of all possible databrowser filters.
 *
 * @param {sideNavFilters}: The list of all possible filters that can be
 *   used to narrow selections within the databrowser
 * @param {filters}: The list of filters the user has selected within this panel
 * @param {setFilters}: A callback function to update parent state of activeFilterws
 */

function SidebarFilters({filters, setFilters, sidebarFiltersFragmentData, shareableURL}) {
  const [dropdowns, setDropdowns] = useState({});
  const data = useFragment(sidebarFiltersFragment, sidebarFiltersFragmentData);
  const sideNavFilters = data?.filters;

  return (
    <div className="sidebar-filters">
      <div className="sidebar-filters-title">Filter Selections</div>
      {sideNavFilters?.map(filter => (
        <div className="sidebar-filters-subfilter" key={filter.type}>
          <div
            tabIndex={0}
            role="button"
            className="sidebar-filters-subfilter-title unselectable"
            aria-controls={`sidebar-filters-${filter.type}`}
            aria-expanded={!dropdowns[filter.type]}
            onKeyPress={() => setDropdowns({...dropdowns, [filter.type]: !dropdowns[filter.type]})}
            onClick={() => setDropdowns({...dropdowns, [filter.type]: !dropdowns[filter.type]})}
          >
            {headerForType(filter.type)}
            <i
              className={classnames('fa', 'fa-chevron-down', 'rotate', {
                down: dropdowns[filter.type],
              })}
            />
          </div>
          <Collapse in={!dropdowns[filter.type]}>
            <div id={`sidebar-filters-${filter.type}`} className="sidebar-filters-buttons">
              {filter.filters.map(value => (
                <Form.Check
                  className="filter-checkbox"
                  name={value}
                  label={labelForValue(value)}
                  key={value}
                  checked={isFilterActive(filters, filter.type, value)}
                  onChange={() => toggleFilter(filters, setFilters, filter.type, value)}
                />
              ))}
            </div>
          </Collapse>
        </div>
      ))}
      <CopyShareableURLButton shareableURL={shareableURL} />
    </div>
  );
}

SidebarFilters.propTypes = {
  sidebarFiltersFragmentData: PropTypes.any,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  shareableURL: PropTypes.string.isRequired,
};

export default SidebarFilters;
